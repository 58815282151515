<div class="mobile-how-it-works-container">
  <div class="large-variable-text title">How our solution works</div>
  <div class="how-it-works-section">
    <img class="how-it-works-image" src="assets/drawings/meter-and-transceiver-drawing.png">
    <div class="description">
      <div class="header">Meter + Transceiver</div>
      <div class="text">
        Each meter on your property is paired with our Transceiver to accurately read and communicate usage data.
      </div>
    </div>
  </div>
  <app-divider></app-divider>
  <div class="how-it-works-section">
    <img class="how-it-works-image" src="assets/drawings/repeater-drawing.png">
    <div class="description">
      <div class="header">Repeater</div>
      <div class="text">
        Repeaters consolidate Transceivers signals across large properties that may include thousands of meters.
      </div>
    </div>
  </div>
  <app-divider></app-divider>
  <div class="how-it-works-section">
    <img class="how-it-works-image" src="assets/drawings/gateway-drawing.png">
    <div class="description">
      <div class="header">Gateway</div>
      <div class="text">
        The data across all the meters on your property is collected by the Gateway and sent to the NextCentury Cloud.
      </div>
    </div>
  </div>
  <app-divider></app-divider>
  <div class="how-it-works-section">
    <img class="how-it-works-image" src="assets/drawings/cloud-drawing.png">
    <div class="description">
      <div class="header">NextCentury Cloud</div>
      <div class="text">
        Alerts and usage data are stored in the cloud and accessible through the NextCentury platform for reporting.
      </div>
    </div>
  </div>
  <app-divider></app-divider>
</div>
