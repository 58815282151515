import { mapTo } from 'rxjs/operators';
import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { gsap } from 'gsap';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[appHoverExpand]'
})
export class HoverExpandDirective implements AfterViewInit, OnDestroy {

  private _subscription?: Subscription;

  constructor(private elementRef: ElementRef) { }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    const enter$: Observable<boolean> = fromEvent(this.elementRef.nativeElement, 'mouseover').pipe(mapTo(true));
    const leave$: Observable<boolean> = fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(mapTo(false));
    const hovering$ = merge(enter$, leave$);
    this._subscription = hovering$.subscribe((hovering) => {
      if (hovering) {
        gsap.to(this.elementRef.nativeElement, { scale: 1.1, duration: .2 });
      } else {
        gsap.to(this.elementRef.nativeElement, { scale: 1, duration: .2 });
      }
    });
  }
}
