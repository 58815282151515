import { ProductTab } from './../../globals';
import { Component, Input, OnInit, AfterViewInit, OnChanges, ViewChild } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { animate, style, transition, trigger } from '@angular/animations';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss'],
  animations: [
    trigger('growIn', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.3s ease-out', style({ height: '*' })),
        animate('.1s ease-out', style({ opacity: '*' })),
      ]),
      transition(':leave', [
        style('*'),
        animate('.1s ease-out', style({ opacity: 0 })),
        animate('.3s ease-out', style({ height: 0 }))
      ])
    ])
  ],
})
export class EquipmentDetailsComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() public info?: ProductTab;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    // ScrollTrigger.create({
    //   animation: this.getImgParallax(),
    //   trigger: '#equipment-details-photo',
    //   start: 'top bottom',
    //   end: 'bottom center',
    //   scrub: .5,
    //   invalidateOnRefresh: true,
    // });
  }

  ngOnChanges(): void {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }

  public learnMoreClicked(): void {
    if (this.info?.learnMoreUrl) {
      this.linkTo(this.info.learnMoreUrl);
    }
  }

  public certificationSelected(event: any): void {
    if (event.target.value) {
      this.linkTo(event.target.value);
      event.target.value = '';
    }
  }

  private linkTo(url: string): void {
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('target', '_blank');
    el.click();
  }

  private getImgParallax(): gsap.core.Tween {
    const tween = gsap.fromTo('#equipment-details-photo', {
      x: '45px',
    },
    {
      x: '0',
    });
    return tween;
  }
}
