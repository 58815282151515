<div id="nav-container">
    <div class="nav-content">
        <img class="nc-logo" src="assets/ncss-wordmark-blue.svg" (click)="linkClicked(links.HOME)">
    </div>
</div>
<div class="leak-roi-main-container">
    <div class="input-page-container" id="calc-pdf">
        <div class="title-instructions">
            <div class="download-leak-calc">
                <h3>Leak Monitoring ROI Calculator</h3>
                <button id='download-button' (click)="downloadAsPdf('calc-pdf')">
                    <i class="icon-download"></i>
                </button>
            </div>
            <p>Instructions: Fill out and validate the input fields. See the "Leak Monitoring ROI Results" section for
                results.</p>
        </div>
        <div class="initial-info">
            <span class="initial-info-fields">
                <label>Property / Portfolio Name</label>
                <input type="text" [(ngModel)]="inputs.propertyName" (blur)="onInputChanged()" />
                <i class="icon-help-circle item-tooltip" [options]="toolTipOptions" tooltip="Name of Property"></i>
            </span>
            <span class="initial-info-fields">
                <label>Number of Units</label>
                <app-numeric-input [inputNum]="inputs.numberOfUnits" (outputNum)="inputs.numberOfUnits = $event"
                    (blur)="onInputChanged()"></app-numeric-input>
                <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                    tooltip="# of units at the property"></i>
            </span>
            <span class="initial-info-fields">
                <label>CAP Rate %</label>
                <app-numeric-input [inputNum]="inputs.capRate" (outputNum)="inputs.capRate = $event"
                    (blur)="onInputChanged()"></app-numeric-input>
                <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                    tooltip="CAP Rate for the property"></i>
            </span>
            <span class="initial-info-fields">
                <div class="radio-buttons-container">
                    <div class="single-radio-button">
                        <label>Resident pays for water</label>
                        <input type="radio" name="paying" value="resident" [checked]="payingParty === 'resident'"
                            (change)="changePayingParty('resident')" />
                    </div>
                    <div class="single-radio-button">
                        <label>Property pays for water</label>
                        <input type="radio" name="paying" value="property" [checked]="payingParty === 'property'"
                            (change)="changePayingParty('property')" />
                    </div>
                </div>
            </span>
        </div>
        <div class="costs">
            <div class="section-header left-side-row">
                <p>HARDWARE COSTS</p>
                <span class="header-row-right">
                    <p>Item Costs</p>
                    <p>Quantity</p>
                    <p>Per Unit</p>
                    <p>Total Property</p>
                </span>
            </div>
            <div>
                <div class="section-subheader left-side-row" style="margin-top: 0px">
                    <p>Unit-Level Costs</p>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Leak Sensor</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Cost of the leak sensor"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.leakSensorCost"
                            (outputNum)="inputs.leakSensorCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.leakSensorQuantityPerUnit"
                            (outputNum)="inputs.leakSensorQuantityPerUnit = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakSensorPerUnitCost) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakSensorTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Cable</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Cost of the leak sensor cable"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.cableCost" (outputNum)="inputs.cableCost = $event"
                            [isMonetaryValue]="true" (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.cableQuantityPerUnit"
                            (outputNum)="inputs.cableQuantityPerUnit = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakSensorCablePerUnitCost) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakSensorCableTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Toilet Sensor</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Cost of toilet sensor"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.toiletSensorCost"
                            (outputNum)="inputs.toiletSensorCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.toiletSensorQuantityPerUnit"
                            (outputNum)="inputs.toiletSensorQuantityPerUnit = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.toiletSensorPerUnitCost) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.toiletSensorTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Other</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Other related costs if applicable"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.otherHardwareCost"
                            (outputNum)="inputs.otherHardwareCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.otherHardwareQuantityPerUnit"
                            (outputNum)="inputs.otherHardwareQuantityPerUnit = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.otherHardwarePerUnitCost) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.otherHardwareTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Installation</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Hardware installation costs"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.installationCost"
                            (outputNum)="inputs.installationCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.installationQuantityPerUnit"
                            (outputNum)="inputs.installationQuantityPerUnit = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.installationPerUnitCost) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.installationTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="section-subheader left-side-row">
                    <p>Property-Level Costs</p>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Remote Shut-off Valve</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Cost of Remote Shut-off Valve - Not currently available"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.remoteShutOffValveCost"
                            (outputNum)="inputs.remoteShutOffValveCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.remoteShutOffValveQuantity"
                            (outputNum)="inputs.remoteShutOffValveQuantity = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>-</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.remoteShutoffValveTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Remote Shut-off Valve Installation</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Remote Shut-off Valve installation cost - Not currently available"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.remoteShutOffValveInstallationCost"
                            (outputNum)="inputs.remoteShutOffValveInstallationCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.remoteShutOffValveInstallationQuantity"
                            (outputNum)="inputs.remoteShutOffValveInstallationQuantity = $event"
                            [isMonetaryValue]="false" (blur)="onInputChanged()"></app-numeric-input>
                        <p>-</p>
                        <p>{{ formatNumber((outputs |
                            async)?.inputPage?.remoteShutOffValveInstallationTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Rebate / Tax Credit</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Input any rebate / tax credit available for the property related to this purchase as a positive number and it will be deducted from the total property costs"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.rebate" (outputNum)="inputs.rebate = $event"
                            [isMonetaryValue]="true" (blur)="onInputChanged()"></app-numeric-input>
                        <app-numeric-input [inputNum]="inputs.rebateQuantity"
                            (outputNum)="inputs.rebateQuantity = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>-</p>
                        <p>-{{ formatNumber((outputs |
                            async)?.inputPage?.rebateTotalProperty) }}</p>
                    </span>
                </div>
            </div>
            <div class="footer left-side-row">
                <p>TOTAL HARDWARE COSTS / UNIT</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalHardwareCostPerUnit) }}</p>
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalHardwareCostTotalProperty) }}</p>
                </span>
            </div>
        </div>
        <hr class="solid-left">
        <div class="costs">
            <div class="section-header left-side-row">
                <p>RECURRING MONTHLY COSTS</p>
                <span class="header-row-right">
                    <p>Total Property</p>
                </span>
            </div>
            <div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Leak Monitoring Service</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Leak monitoring subscription costs per unit"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.leakMonitoringCost"
                            (outputNum)="inputs.leakMonitoringCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-unit">&nbsp;/ unit</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakMonitoringTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Auto Shut-off Service</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Auto Shut-off Service costs per device"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.autoShutOffServiceCost"
                            (outputNum)="inputs.autoShutOffServiceCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-device">&nbsp;/ device</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.autoShutOffServiceTotalPropertyCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Other</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Any other related costs if applicable"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.otherMonthlyCost"
                            (outputNum)="inputs.otherMonthlyCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-unit">&nbsp;/ unit</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.otherMonthlyRecurringPropertyCost) }}</p>
                    </span>
                </div>
            </div>
            <div class="footer left-side-row">
                <p>TOTAL MONTHLY RECURRING COSTS</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalMonthlyRecurringCostPerUnit) }} / unit</p>
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalMonthlyRecurringCostTotalProperty) }}</p>
                </span>
            </div>
        </div>
        <hr class="solid-left" *ngIf="payingParty === 'resident'">
        <div class="costs" *ngIf="payingParty === 'resident'">
            <div class="section-header left-side-row">
                <p>RECURRING MONTHLY REVENUE / UNIT *</p>
                <span class="header-row-right">
                    <p>Total Property</p>
                </span>
            </div>
            <div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Leak Monitoring Service Revenue</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Leak monitoring subscription billed to tenants"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.leakMonitoringRevenue"
                            (outputNum)="inputs.leakMonitoringRevenue = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-unit">&nbsp;/ unit</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.leakMonitoringTotalPropertyRevenue) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Auto Shut-off Service Revenue</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Auto Shut-off Service billed to tenants"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.autoShutOffServiceRevenue"
                            (outputNum)="inputs.autoShutOffServiceRevenue = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-device">&nbsp;/ device</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.autoShutOffServiceTotalPropertyRevenue) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Other</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Any other related charges if applicable"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.otherMonthlyRevenue"
                            (outputNum)="inputs.otherMonthlyRevenue = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <span class="per-unit">&nbsp;/ unit</span>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.otherMonthlyRecurringPropertyRevenue) }}</p>
                    </span>
                </div>
            </div>
            <div class="footer left-side-row">
                <p>TOTAL MONTHLY RECURRING REVENUE</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalMonthlyRecurringRevenuePerUnit) }} / unit</p>
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalMonthlyRecurringRevenueTotalProperty) }}</p>
                </span>
            </div>
        </div>
        <hr class="solid-left">
        <div class="costs">
            <div class="section-header left-side-row">
                <p>INSURANCE PREMIUMS</p>
                <span class="header-row-right">
                    <p>Monthly</p>
                    <p>Annual</p>
                </span>
            </div>
            <div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Insurance Premium Cost</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Enter your monthly insurance premium - annual insurance premium cost is calculated by the monthly input"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.insurancePremiumCost"
                            (outputNum)="inputs.insurancePremiumCost = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.annualPremiumCost) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <p>Number of Units</p>
                    <span class="row-right">
                        <p>{{ inputs.numberOfUnits }}</p>
                        <p>{{ inputs.numberOfUnits }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <p>Insurance Cost / Unit</p>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.monthlyInsuranceCostPerUnit) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.annualInsuranceCostPerUnit) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra extra-margin-top-rows">
                    <span></span>
                    <span class="row-right">
                        <div class="line-item-with-tooltip">
                            <p><i>Discount %</i></p>
                            <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                                tooltip="Insurance premium discount offered by insurance company for installing leak sensors"></i>
                        </div>
                        <app-numeric-input [inputNum]="inputs.discountPercentage"
                            (outputNum)="inputs.discountPercentage = $event" [isMonetaryValue]="false"
                            (blur)="onInputChanged()"></app-numeric-input>
                    </span>
                </div>
                <div class="left-side-row">
                    <p>Leak Sensor Monitoring Discount</p>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.monthlyLeakSensorMonitoringDiscount) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.annualLeakSensorMonitoringDiscount) }}</p>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <p>Number of Units</p>
                    <span class="row-right">
                        <p>{{ inputs.numberOfUnits }}</p>
                        <p>{{ inputs.numberOfUnits }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <p>Discount / Unit</p>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.monthlyDiscountPerUnit) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.annualDiscountPerUnit) }}</p>
                    </span>
                </div>
            </div>
        </div>
        <div class="costs">
            <div class="section-header left-side-row">
                <p>LEAK OCCURANCE COST (AVERAGE)</p>
                <span class="header-row-right">
                    <p>Annual</p>
                </span>
            </div>
            <div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Insurance Deductible Paid</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Property damage claims run through insurance"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.insuranceDeductablePaid"
                            (outputNum)="inputs.insuranceDeductablePaid = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                    </span>
                </div>
                <div class="left-side-row zebra">
                    <div class="line-item-with-tooltip">
                        <p>Out of Pocket Repair Costs</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Property damage paid outside of insurance"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.outOfPocketRepairCosts"
                            (outputNum)="inputs.outOfPocketRepairCosts = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Other</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Any other related costs if applicable"></i>
                    </div>
                    <span class="row-right">
                        <app-numeric-input [inputNum]="inputs.otherOccuranceCosts"
                            (outputNum)="inputs.otherOccuranceCosts = $event" [isMonetaryValue]="true"
                            (blur)="onInputChanged()"></app-numeric-input>
                    </span>
                </div>
            </div>
            <div class="footer left-side-row">
                <p>TOTAL</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.totalLeakOccuranceCost) }}</p>
                </span>
            </div>
        </div>
        <div class="totals">
            <div class="footer total-estimate-top left-side-row">
                <p>ESTIMATED ANNUAL LEAK OCCURANCE SAVINGS</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedAnnualSavings) }}</p>
                </span>
            </div>
            <div class="footer left-side-row">
                <p>ESTIMATED ANNUAL LEAK OCCURANCE SAVINGS / UNIT</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedAnnualSavingsPerUnit) }}</p>
                </span>
            </div>
        </div>
        <hr class="solid-left">
        <div class="costs">
            <span class="asterisked-note">
                <p>*Water cost savings benefit the residents who are being billed for their usage.</p>
                <p>The numbers below are estimated conservation stats.</p>
            </span>
            <div
                [ngClass]="payingParty === 'resident' ? ['green-header', 'left-side-row'] : ['section-header', 'left-side-row']">
                <p>ESTIMATED WATER CONSERVATION SAVINGS</p>
            </div>
            <div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Number of Toilets</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Calculated by number of units x # of toilet sensors"></i>
                    </div>
                    <span class="row-right">
                        <p>{{ ((outputs | async)?.inputPage?.numberOfToilets) }}</p>
                    </span>
                </div>
                <div
                    [ngClass]="payingParty === 'resident' ? ['left-side-row', 'green-conservation-zebra'] : ['left-side-row', 'zebra']">
                    <p>Average % of Running Toilets**</p>
                    <span class="row-right">
                        <p>{{ inputs.avgPercentOfRunningToilets }}%</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <div class="line-item-with-tooltip">
                        <p>Average # of Running Toilets</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Number of toilets in property x % of running toilets"></i>
                    </div>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.avgNumberOfRunningToilets, true) }}</p>
                    </span>
                </div>
                <div
                    [ngClass]="payingParty === 'resident' ? ['left-side-row', 'green-conservation-zebra'] : ['left-side-row', 'zebra']">
                    <p>
                        <span>Average Cost of Running Toilet / Month**</span>
                    </p>
                    <span class="row-right">
                        <p>{{ formatNumber(inputs.avgCostOfRunningToiletPerMonth) }}</p>
                    </span>
                </div>
                <div class="left-side-row">
                    <p>Average Gallons of Water Lost Daily Due to a Running Toilet**</p>
                    <span class="row-right">
                        <p>{{ inputs.avgGallonsLostDailyFromRunningToilet }} gallons / day / toilet</p>
                    </span>
                </div>
                <div
                    [ngClass]="payingParty === 'resident' ? ['left-side-row', 'green-conservation-zebra'] : ['left-side-row', 'zebra']">
                    <span></span>
                    <span class="row-right mini-row">
                        <p>Monthly</p>
                        <p>Annual</p>
                    </span>
                </div>
                <div
                    [ngClass]="payingParty === 'resident' ? ['green-footer', 'left-side-row'] : ['footer', 'left-side-row']">
                    <div class="line-item-with-tooltip">
                        <p>Estimated Water Bill Savings</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Estimated annual water savings due to detecting and fixing running toilets"></i>
                    </div>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedMonthlyWaterBillSavings) }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedAnnualWaterBillSavings) }}</p>
                    </span>
                </div>
                <div
                    [ngClass]="payingParty === 'resident' ? ['green-footer', 'left-side-row'] : ['footer', 'left-side-row']">
                    <div class="line-item-with-tooltip">
                        <p>Estimated Gallons of Water Conserved</p>
                        <i class="icon-help-circle item-tooltip" [options]="toolTipOptions"
                            tooltip="Estimated gallons conserved due to detecting and fixing running toilets"></i>
                    </div>
                    <span class="row-right">
                        <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedGallonsOfWaterConservedMonthly, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.inputPage?.estimatedGallonsOfWaterConservedAnnualy, true)
                            }}</p>
                    </span>
                </div>
                <span class="asterisked-note">**National average per EPA.Gov/watersense study</span>
            </div>
        </div>
    </div>
    <div class="results-page-container" id="result-pdf">
        <div class="title-instructions">
            <div class="top-section">
                <h3>Leak Monitoring ROI Results</h3>
                <button id='download-button' (click)="downloadAsPdf('result-pdf')">
                    <i class="icon-download"></i>
                </button>
            </div>
            <div class="inital-info">
                <span class="initial-info-fields">
                    <label>Property / Portfolio Name</label>
                    <p>{{ inputs.propertyName || '-' }}</p>
                </span>
                <span class="initial-info-fields">
                    <label>Number of Units</label>
                    <p>{{ inputs.numberOfUnits.toLocaleString() }}</p>
                </span>
            </div>
        </div>
        <div id="smaller-row-group">
            <div class="section-header smaller-row">
                <p>SUMMARY</p>
            </div>
            <div class="smaller-row ">
                <p>5 Year Return</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.outputPage?.fiveYearReturn, false, true) }}</p>
                </span>
            </div>
            <div class="smaller-row smaller-zebra">
                <p>ROI %</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.outputPage?.roiPercent, true) }}%</p>
                </span>
            </div>
            <div class="smaller-row">
                <p>Payback Period (Yrs)</p>
                <span class="row-right">
                    <p>{{ (outputs | async)?.outputPage?.paybackPeriodYears }}</p>
                </span>
            </div>
            <div class="smaller-row smaller-zebra">
                <p>CAP Rate</p>
                <span class="row-right">
                    <p>{{ (outputs | async)?.outputPage?.capRate }}%</p>
                </span>
            </div>
            <div class="smaller-row">
                <p>Est. Increase in Property Value</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedPropertyValueIncrease, false, true) }}
                    </p>
                </span>
            </div>
        </div>
        <div class="right-costs">
            <div>
                <div class="section-header section-row">
                    <p>ROI CALCULATION</p>
                </div>
                <div class="section-header section-row">
                    <p>Year</p>
                    <span class="result-header-row-right">
                        <p>Capital Expense</p>
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                        <p>4</p>
                        <p>5</p>
                    </span>
                </div>
                <div class="section-row">
                    <p>Recurring Costs</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringCosts, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringCosts, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringCosts, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringCosts, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringCosts, false, true) }}</p>
                    </span>
                </div>
                <div class="section-row zebra">
                    <p>Recurring Revenue</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringRevenue, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringRevenue, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringRevenue, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringRevenue, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.recurringRevenue, false, true) }}</p>
                    </span>
                </div>
                <div class="section-row">
                    <p>Insurance Discount Benefit</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.insuranceDiscountBenefit, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.insuranceDiscountBenefit, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.insuranceDiscountBenefit, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.insuranceDiscountBenefit, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.insuranceDiscountBenefit, false, true) }}</p>
                    </span>
                </div>
                <div class="section-row zebra">
                    <p>Estimated Annual Occurance Savings</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedAnnualOccuranceSavings, false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedAnnualOccuranceSavings, false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedAnnualOccuranceSavings, false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedAnnualOccuranceSavings, false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedAnnualOccuranceSavings, false, true)
                            }}</p>
                    </span>
                </div>
                <div class="section-row" *ngIf="payingParty === 'property'">
                    <p>Estimated Water Conservation Savings</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedWaterConservationSavings, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedWaterConservationSavings, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedWaterConservationSavings, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedWaterConservationSavings, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.estimatedWaterConservationSavings, false,
                            true) }}
                        </p>
                    </span>
                </div>
                <div class="footer section-row">
                    <p>Increase (Decrease) in NOI</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.deltaInNOI, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.deltaInNOI, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.deltaInNOI, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.deltaInNOI, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.deltaInNOI, false, true) }}</p>
                    </span>
                </div>
                <div class="footer section-row">
                    <p>Cumulative Cash Flow</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow.capitalExpense, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow[1], false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow[2], false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow[3], false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow[4], false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.cumulativeCashFlow[5], false, true) }}</p>
                    </span>
                </div>
            </div>
        </div>
        <hr class="solid">
        <div class="right-costs">
            <div>
                <div class="section-header section-row">
                    <p>ROI CALCULATION / UNIT</p>
                </div>
                <div class="section-header section-row">
                    <p>Year</p>
                    <span class="result-header-row-right">
                        <p>Capital Expense</p>
                        <p>1</p>
                        <p>2</p>
                        <p>3</p>
                        <p>4</p>
                        <p>5</p>
                    </span>
                </div>
                <div class="section-row">
                    <p>Recurring Costs</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringCosts / inputs.numberOfUnits,
                            false, true)}}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringCosts / inputs.numberOfUnits,
                            false, true)}}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringCosts / inputs.numberOfUnits,
                            false, true)}}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringCosts / inputs.numberOfUnits,
                            false, true)}}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringCosts / inputs.numberOfUnits,
                            false, true)}}</p>
                    </span>
                </div>
                <div class="section-row zebra">
                    <p>Recurring Revenue</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringRevenue / inputs.numberOfUnits,
                            false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringRevenue / inputs.numberOfUnits,
                            false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringRevenue / inputs.numberOfUnits,
                            false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringRevenue / inputs.numberOfUnits,
                            false, true)
                            }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.recurringRevenue / inputs.numberOfUnits,
                            false, true)
                            }}</p>
                    </span>
                </div>
                <div class="section-row">
                    <p>Insurance Discount Benefit</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.insuranceDiscountBenefit /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.insuranceDiscountBenefit /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.insuranceDiscountBenefit /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.insuranceDiscountBenefit /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.insuranceDiscountBenefit /
                            inputs.numberOfUnits, false, true) }}</p>
                    </span>
                </div>
                <div class="section-row zebra">
                    <p>Estimated Annual Occurance Savings</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedAnnualOccuranceSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedAnnualOccuranceSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedAnnualOccuranceSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedAnnualOccuranceSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedAnnualOccuranceSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                    </span>
                </div>
                <div class="section-row" *ngIf="payingParty === 'property'">
                    <p>Estimated Water Conservation Savings</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedWaterConservationSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedWaterConservationSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedWaterConservationSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedWaterConservationSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.estimatedWaterConservationSavings /
                            inputs.numberOfUnits, false, true) }}</p>
                    </span>
                </div>
                <div class="footer section-row">
                    <p>Increase (Decrease) in NOI</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage!.deltaInNOI / inputs.numberOfUnits, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.deltaInNOI / inputs.numberOfUnits, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.deltaInNOI / inputs.numberOfUnits, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.deltaInNOI / inputs.numberOfUnits, false,
                            true) }}
                        </p>
                        <p>{{ formatNumber((outputs | async)?.outputPage!.deltaInNOI / inputs.numberOfUnits, false,
                            true) }}
                        </p>
                    </span>
                </div>
                <div class="footer section-row">
                    <p>Cumulative Cash Flow</p>
                    <span class="result-row-right">
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow!.capitalExpense /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow![1] /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow![2] /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow![3] /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow![4] /
                            inputs.numberOfUnits, false, true) }}</p>
                        <p>{{ formatNumber((outputs | async)?.outputPage?.cumulativeCashFlow![5] /
                            inputs.numberOfUnits, false, true) }}</p>
                    </span>
                </div>
            </div>
        </div>
        <div class="leak-roi-chart-container">
            <div id="chart"></div>
        </div>
    </div>
</div>