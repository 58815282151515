import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceCount } from '../globals';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private baseUrl = 'https://api.nextcenturymeters.com';
    constructor(private http: HttpClient) {}

    getDeviceCount(): Observable<DeviceCount> {
        return this.http.get<any>(`${this.baseUrl}/api/public-stats/active-devices`);
    }
}