<div class="open-positions-container">
  <div class="open-position-card" appHoverExpand>
    <div class="title">Open Positions</div>
    <div class="description">Providence, UT</div>
    <div class="applyNow"><button (click)="goToGusto()" class="btn btn-lg">APPLY NOW</button></div>
  </div>
</div>
<ng-container *ngIf="showingForm$ | async">
  <div class="form-background" id="applicationFormBackground">
    <div class="form-container" id="applicationFormContainer">
      <div class="header">{{position + ' Application'}}<i (click)="closeApplicationForm()" class="icon-x" appHoverExpand></i></div>
      <div class="position-description">
        <div class="left">
          <div class="list-header">Technical Skills</div>
          <ul>
            <li *ngFor="let l of skills.technical">{{l}}</li>
          </ul>
          <div class="list-header">Experience/Education</div>
          <ul>
            <li *ngFor="let l of skills.experience">{{l}}</li>
          </ul>
        </div>
        <div class="right">
          <div class="list-header">General Skills</div>
            <ul>
              <li *ngFor="let l of skills.general">{{l}}</li>
            </ul>
        </div>
      </div>
      <form [formGroup]="applicationForm">
        <div class="form-row">
          <label for="firstname">First Name:</label>
          <input name="firstname" formControlName="firstname" placeholder="Enter first name">
        </div>
        <div class="form-row">
          <label for="lastname">Last Name:</label>
          <input name="lastname" formControlName="lastname" placeholder="Enter last name">
        </div>
        <div class="form-row">
          <label for="email">Email:</label>
          <input name="email" formControlName="email" type="email" placeholder="Enter email address">
        </div>
        <div class="form-row">
          <label for="phone">Phone:</label>
          <input name="phone" formControlName="phone" type="tel" placeholder="Enter phone number">
        </div>
        <div class="form-row">
          <label for="coverLetter">Cover Letter:</label>
          <app-file-upload formControlName="coverLetter" name="coverLetter"></app-file-upload>
        </div>
        <div class="form-row">
          <label for="cv">Resume/CV:</label>
          <app-file-upload formControlName="cv" name="cv"></app-file-upload>
        </div>
        <div class="form-row" *ngIf="errorMsg$ | async">
          <label class="error">{{errorMsg$ | async}}</label>
        </div>
        <div class="submit-row">
          <button (click)="submitApplicationForm()" class="btn btn-lg"[disabled]="applicationForm.invalid">SUBMIT APPLICATION</button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
