import { trigger, transition, style, animate } from '@angular/animations';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { forEach as _forEach } from 'lodash-es';
import {
  Application,
  MultifamilyApplication,
  CommercialApplication,
  HighRiseApplication,
  MobileHomeApplication,
} from './Application';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export const Applications: Application[] = [
  new MultifamilyApplication(),
  new CommercialApplication(),
  new HighRiseApplication(),
  new MobileHomeApplication(),
];

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  animations: [
    trigger('growIn', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.3s ease-out', style({ height: '*' })),
        animate('.1s ease-out', style({ opacity: '*' })),
      ]),
      transition(':leave', [
        style('*'),
        animate('.1s ease-out', style({ opacity: 0 })),
        animate('.3s ease-out', style({ height: 0 }))
      ]),
    ]),
  ],
})
export class ApplicationsComponent implements OnInit, AfterViewInit {

  public Applications = Applications;

  private _currentApplicationIndex$ = new BehaviorSubject<number>(0);
  public currentApplication$: Observable<Application> = this._currentApplicationIndex$.pipe(
    distinctUntilChanged(),
    debounceTime(100),
    map((i) => Applications[i]));

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: '.applications-outer-container',
      start: () => 'top top',
      end: () => 'bottom bottom',
      pin: '.applications-outer-container .content-container',
      pinSpacing: false,
      anticipatePin: 1,
      invalidateOnRefresh: true,
    });
    const sections = gsap.utils.toArray('.applications-outer-container .animation-section');
    sections.forEach((section, i) => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: section as any,
          start: () => 'top center',
          end: () => 'top top',
          scrub: true,
          snap: {
            snapTo: [1],
            duration: { min: 1, max: 1.5 },
            delay: .5,
          },
          onToggle: (val) => this._currentApplicationIndex$.next(i),
        },
      });
      Applications[i]?.populateAnimateInTimeline(timeline);
      const reverseTimeline = gsap.timeline({
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: section as any,
          start: () => 'top top',
          end: () => i === 3 ? 'bottom top+=75px' : 'bottom center',
          scrub: true,
          snap: {
            snapTo: i === 3 ? [0, 1] : [0],
            duration: { min: 1, max: 1.5 },
            delay: .5,
          },
          onToggle: (val) => this._currentApplicationIndex$.next(i),
        },
      });
      Applications[i]?.populateAnimateOutTimeline(reverseTimeline);
    });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
  }

  applicationClicked(index: number): void {
    gsap.to(window, {scrollTo: `#animationSection-${index}`, duration: 1}).then(() => {
      ScrollTrigger.refresh();
    });
  }
}
