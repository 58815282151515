<div class="outer-background gray-gradient">
  <ng-container *ngIf="(scrollService.isMobile$ | async)">
    <app-mobile-toolbar (homeClicked)="linkClicked($event)" (openMobileMenu)="openMobileMenu()"></app-mobile-toolbar>

    <div class="flex-center video-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xe3x4PBGm2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <app-mobile-footer></app-mobile-footer>
  </ng-container>
  <ng-container *ngIf="!(scrollService.isMobile$ | async)">
    <app-toolbar [context]="'careers'" (linkClicked)="linkClicked($event)"></app-toolbar>
    <div class="flex-center video-container">
      <iframe width="896" height="504" src="https://www.youtube.com/embed/xe3x4PBGm2w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <app-footer id="footer" [context]="'careers'" (linkClicked)="linkClicked($event)"></app-footer>
  </ng-container>
</div>
