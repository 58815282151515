import { SnackbarService } from './../../services/snackbar/snackbar.service';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Links, SENDY_URL, ROOT_URL } from './../../globals';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public copyrightYear = new Date().getFullYear();
  public links = Links;
  public email = new FormControl('', [Validators.required, Validators.email]);

  @Input() public context: 'home' | 'careers' | 'nm4i' = 'home';
  @Output() linkClicked = new EventEmitter();

  private apiKey?: string;
  private list?: string;

  constructor(private http: HttpClient, private snack: SnackbarService) {
    this.apiKey = environment.SendyApiKey;
    this.list = environment.SendyList;
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.email.invalid) { return; }
    this.http.post(`${ROOT_URL}newsletter-subscribe`, { email: this.email.value }).subscribe((res) => {
      this.snack.create('Subscribed!');
      this.email.reset();
    }, (err) => {
      console.log(err);
      this.snack.create('Couldn\'t subscribe to newsletter at this time.');
    });
  }
}
