<div class="main-container">
  <div class="toolbar-content">
    <img src="assets/ncss-wordmark-blue.svg" (click)="linkClicked.emit(links.HOME)">
    <div class="links">
      <div class="link" (click)="linkClicked.emit(links.SUPPORT)">Support</div>
      <div class="link" (click)="linkClicked.emit(links.CONTACT)">Contact</div>
      <div class="link" (click)="linkClicked.emit(links.CAREERS)">Careers</div>
      <div class="link" (click)="linkClicked.emit(links.SIGN_IN)"><i class="icon-user"></i>Sign In</div>
    </div>
  </div>
</div>
<div class="spacer"></div>
