import { ScrollService } from './../../services/scroll.service';
import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import Player from '@vimeo/player';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements AfterViewInit {

  @ViewChild('videoContainer') videoContainer?: ElementRef;

  private player?: Player;

  constructor(public scrollService: ScrollService) { }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: '.welcome-content',
      start: 'top top',
      end: 'bottom top',
      scrub: .5,
      animation: gsap.fromTo('.laptop', {y: '-3%'}, {y: '3%'}),
      invalidateOnRefresh: true,
    });
  }

  playBtnClicked(): void {
    if (!this.player) {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        this.videoContainer?.nativeElement?.classList.add('forward');
        this.player = new Player(iframe);
        this.player.on('ended', () => {
          this.videoContainer?.nativeElement?.classList.remove('forward');
          this.player?.unload();
        });
        this.player.play();
      }
    } else {
      this.videoContainer?.nativeElement?.classList.add('forward');
      this.player.play();
    }
  }

}
