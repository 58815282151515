<div class="mobile-solutions-container">
  <app-product-slider [slides]="slides" (slideSelected$)="equipmentClicked($event)"></app-product-slider>
  <app-mobile-equipment-details *ngIf="!!selectedEquipment && !selectedEquipment.subTabs" [info]="selectedEquipment"></app-mobile-equipment-details>
  <div *ngIf="!!selectedEquipment && !!selectedEquipment.subTabs?.length" class="sub-tabs">
    <div *ngFor="let tab of selectedEquipment.subTabs" class=sub-tab (click)="selectedSubTab = tab" [ngClass]="tab.title === selectedSubTab?.title ? 'selected' : ''">
      <img [src]="tab.sliderImg">
      {{tab.title}}
    </div>
  </div>
  <app-mobile-equipment-details *ngIf="!!selectedSubTab" [info]="selectedSubTab"></app-mobile-equipment-details>
</div>
