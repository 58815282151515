import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ApiService } from 'src/app/services/api.service';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-device-count',
  templateUrl: './device-count.component.html',
  styleUrls: ['./device-count.component.scss']
})
export class DeviceCountComponent implements OnInit, AfterViewInit {

  @ViewChild('odometerCount') public odometerCount?: ElementRef;

  public count = 3200000;
  public config = {
    auto: false,
  };

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
      this.apiService.getDeviceCount().subscribe((res) => {
        this.count = res.activeDevices;
      }, (err) => {
        console.error('Unable to fetch active device count: ', err);
      });
  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: '.proven-reliability-container',
      start: 'top bottom',
      end: 'bottom top',
      animation: gsap.fromTo('.background-container', { bottom: '50%' }, { bottom: '-20%' }),
      scrub: true,
      onEnter: () => this.countUp(),
      onEnterBack: () => this.countUp(),
      onLeave: () => this.resetCount(),
      onLeaveBack: () => this.resetCount(),
    });
  }

  resetCount(): void {
    (this.odometerCount?.nativeElement as any).innerHTML = 1900000;
  }

  countUp(): void {
    (this.odometerCount?.nativeElement as any).innerHTML = this.count;
  }
}
