import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from './directives/directives.module';
import { ComponentsModule } from './components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CareersComponent } from './careers/careers.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { LeakSensorRoiComponent } from './leak-sensor-roi/leak-sensor-roi.component';
import { PowerUsageCostComponent } from './power-usage-cost/power-usage-cost.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NM4IComponent } from './nm4i/nm4i.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CareersComponent,
    ThirdPartyComponent,
    LeakSensorRoiComponent,
    PowerUsageCostComponent,
    NM4IComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
