<div class="equipment-details-container">
  <div class="equipment-info-container">
    <div class="large-variable-text equipment-title">{{info?.title}}</div>
    <div class="equipment-description" [innerHTML]="info?.descriptionHTML"></div>
    <div class="equipment-models" *ngFor="let m of info?.models">
      <div class="equipment-model-header">
        {{m.name}}
        <a *ngIf="m.hyperlink" target="_blank" [href]="m.hyperlink?.url">
          <i class="icon-external-link"></i>
          {{m.hyperlink?.label}}
        </a>
      </div>
      <div class="model-downloads">
        <div class="download" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.specSheet">
          <a target="_blank" (click)="m.disableLinks ? false : ''" [href]="m.specSheet"><i class="icon-download"></i>Specification Sheet</a>
        </div>
        <div class="download" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.hardwareWarranty">
          <a target="_blank" (click)="m.disableLinks ? false : ''" [href]="m.hardwareWarranty"><i class="icon-download"></i>Hardware Warranty</a>
        </div>
        <div class="download" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.installationGuide">
          <a target="_blank" (click)="m.disableLinks ? false : ''" [href]="m.installationGuide"><i class="icon-download"></i>Installation Guide</a>
        </div>
        <div class="download" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.certifications?.length">
          <i class="icon-download"></i>
          <select (change)="certificationSelected($event)" [disabled]="m.disableLinks">
            <option value="" selected>Certifications</option>
            <option *ngFor="let o of m.certifications" value="{{o.url}}">{{o.label}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="learn-more" *ngIf="info?.learnMoreUrl" (click)="learnMoreClicked()">LEARN MORE</div>
  </div>
  <div class="equipment-img-container">
    <img id="equipment-details-photo" [src]="info?.photo">
  </div>
</div>
