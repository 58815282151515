import { Links } from './../globals';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { formatCurrency } from '@angular/common';

function round(number: number) {
  const result = Math.round(number * 100) / 100;
  return result;
}
class Inputs {
  totalUnits: number = 300;
  totalRepeaters: number = 10;
  checkinIntervalHours: number = 6;
  electricRatePerKWH: number = 0.17;
  networkRetryRate: number = 15;

  gatewayTXPowerUsageAt15Volts: number = 430;
  gatewayTXPowerUsage: number = 6.45;
  gatewayRXPowerUsageAt15Volts: number = 200;
  gatewayRXPowerUsage: number = 3;
  gatewayTXDuration: number = 400;
  repeaterTXPowerUsageAt15Volts: number = 200;
  repeaterTXPowerUsage: number = 3;
  repeaterRXPowerUsageAt15Volts: number = 15;
  repeaterRXPowerUsage: number = 0.225;
  repeaterTXDuration: number = 400;

  public calculate(): Outputs {
    const o: Outputs = {} as Outputs;

    // network messages
    o.totalTransceiverMessagesPerDay = (this.totalUnits + this.totalRepeaters) * (24 / this.checkinIntervalHours);
    o.averageTransceiverMessages = o.totalTransceiverMessagesPerDay * (1 + (this.networkRetryRate / 100));

    // power usage durations
    o.totalGatewayTXTime = (this.gatewayTXDuration * o.averageTransceiverMessages) * 0.000000277778;
    o.totalGatewayRXTime = 24 - o.totalGatewayTXTime;
    o.repeaterTXTime = (this.repeaterTXDuration * o.averageTransceiverMessages * 2) * 0.000000277778;
    o.repeaterRXTime = (24 - o.repeaterTXTime);

    // daily kwh calculations
    o.gatewayTXPowerUsagesPerDay = (this.gatewayTXPowerUsage * o.totalGatewayTXTime) * 0.001;
    o.gatewayRXPowerUsagesPerDay = (this.gatewayRXPowerUsage * o.totalGatewayRXTime) * 0.001;
    o.gatewayTotalUsagePerDay = o.gatewayTXPowerUsagesPerDay + o.gatewayRXPowerUsagesPerDay;
    o.repeaterTXPowerUsagesPerDay = (this.repeaterTXPowerUsage * o.repeaterTXTime) * 0.001;
    o.repeaterRXPowerUsagesPerDay = (this.repeaterRXPowerUsage * o.repeaterRXTime) * 0.001;
    o.repeaterTotalUsagePerDay = o.repeaterTXPowerUsagesPerDay + o.repeaterRXPowerUsagesPerDay;

    // estimated costs
    o.gatewayCostPerDay = o.gatewayTotalUsagePerDay * this.electricRatePerKWH;
    o.repeaterCostPerDayEachRepeater = o.repeaterTotalUsagePerDay * this.electricRatePerKWH;
    o.repeaterCostPerDayAllRepeaters = o.repeaterCostPerDayEachRepeater * this.totalRepeaters;
    o.dailyEquipmentCost = o.repeaterCostPerDayAllRepeaters + o.gatewayCostPerDay;
    o.gatewayCostPerMonth = o.gatewayCostPerDay * 30;
    o.repeaterCostPerMonthEachRepeater = o.repeaterCostPerDayEachRepeater * 30;
    o.repeaterCostPerMonthAllRepeaters = o.repeaterCostPerDayAllRepeaters * 30;
    o.monthlyEquipmentCost = o.gatewayCostPerMonth + o.repeaterCostPerMonthAllRepeaters;

    return o;
  }
}

interface Outputs {
  // network messages
  totalTransceiverMessagesPerDay: number;
  averageTransceiverMessages: number;

  // power usage durations
  totalGatewayTXTime: number;
  totalGatewayRXTime: number;
  repeaterTXTime: number;
  repeaterRXTime: number;

  // daily kwh calculations
  gatewayTXPowerUsagesPerDay: number;
  gatewayRXPowerUsagesPerDay: number;
  gatewayTotalUsagePerDay: number;
  repeaterTXPowerUsagesPerDay: number;
  repeaterRXPowerUsagesPerDay: number;
  repeaterTotalUsagePerDay: number;

  // estimated costs
  gatewayCostPerDay: number;
  repeaterCostPerDayEachRepeater: number;
  repeaterCostPerDayAllRepeaters: number;
  dailyEquipmentCost: number;
  gatewayCostPerMonth: number;
  repeaterCostPerMonthEachRepeater: number;
  repeaterCostPerMonthAllRepeaters: number;
  monthlyEquipmentCost: number;
}

@Component({
  selector: 'app-power-usage-cost',
  templateUrl: './power-usage-cost.component.html',
  styleUrls: ['./power-usage-cost.component.scss']
})
export class PowerUsageCostComponent {
  inputs = new Inputs();
  outputs = new BehaviorSubject<Outputs>({} as Outputs);
  public links = Links;

  constructor(private router: Router) {
    this.outputs.next(this.inputs.calculate());
  }

  onInputChanged() {
    this.outputs.next(this.inputs.calculate());
  }

  formatNumber(number: number | undefined, hideTrailingZeroes?: boolean, isDollarAmount?: boolean) {
    if (number === undefined) {
      return '';
    }
    if (hideTrailingZeroes) {
      return round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (isDollarAmount) {
      return formatCurrency(number, 'en-US', '$', 'USD', '1.2-2');
    }
    return number.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  linkClicked(link: Links) {
    if (link === Links.HOME) {
      this.router.navigateByUrl('/');
    }
  }
}
