import { SnackbarService } from './snackbar.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar/snackbar.component';



@NgModule({
  declarations: [SnackbarComponent],
  imports: [
    CommonModule
  ],
  providers: [
    SnackbarService,
  ],
})
export class SnackbarModule { }
