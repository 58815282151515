import { Links } from './../../globals';
import { Component, EventEmitter, OnInit, Output, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit, AfterViewInit {

  public links = [
    { label: 'Home', link: Links.HOME },
    { label: 'Support', link: Links.SUPPORT },
    { label: 'Contact', link: Links.CONTACT },
    { label: 'Careers', link: Links.CAREERS },
    { label: 'Sign In', link: Links.SIGN_IN, icon: 'icon-user' },
  ];

  public copyrightYear = new Date().getFullYear();

  @Output() public dismiss = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    gsap.fromTo('.backdrop', { opacity: 0 }, { opacity: 1, duration: .5 });
    gsap.fromTo('.link', { y: 150, opacity: 0 }, { y: 0, opacity: 1, delay: .2, stagger: .1 }).play();
  }

  close(link?: Links): void {
    this.dismiss.emit(link);
    gsap.fromTo('.backdrop', { opacity: 1 }, { opacity: 0, duration: .5 }).play();
    gsap.fromTo('.link', { y: 0, opacity: 1 }, { y: 150, opacity: 0, stagger: -.1 }).play();
  }

}
