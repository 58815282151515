import { Component, OnInit, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export interface IFeaturesListItem {
  title: string;
  items: Array<{ icon: string, name: string, comingSoon?: boolean }>;
}

export const FeatureList: IFeaturesListItem[] = [
  {
    title: 'Robust Alert Notifications',
    items: [
      { name: 'Tamper', icon: 'icon-transceiver-open' },
      { name: 'Connection', icon: 'icon-radio' },
      { name: 'Power Loss', icon: 'icon-power-loss' },
      { name: 'Battery', icon: 'icon-battery-low' },
      { name: 'Temperature', icon: 'icon-thermometer' },
      { name: 'Abnormal Consumption', icon: 'icon-trending-up' },
      { name: 'Leak Detection', icon: 'icon-leak-B', comingSoon: true },
    ]
  },
  {
    title: 'Custom Reporting',
    items: [
      { name: 'Usage', icon: 'icon-pie-chart' },
      { name: 'Network', icon: 'icon-radio' },
      { name: 'Battery', icon: 'icon-battery' },
      { name: 'Temperature', icon: 'icon-thermometer' },
    ]
  },
  {
    title: 'Powered by the Cloud',
    items: [
      { name: 'Access anywhere', icon: 'icon-globe' },
      { name: 'Cloud storage', icon: 'icon-cloud' },
      { name: 'Integrate with our API', icon: 'icon-dev' },
      { name: 'Security & data protection', icon: 'icon-lock' },
    ]
  }
];

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, AfterViewInit {

  public features: IFeaturesListItem[] = FeatureList;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: '#mobile-half-img',
      start: 'top bottom',
      end: window.innerHeight <= 400 ? '+=100px' : '+=500px',
      animation: this.getPhoneParallax(),
      scrub: .5,
      invalidateOnRefresh: true,
    });
  }

  private getPhoneParallax(): gsap.core.Tween {
    const tween = gsap.fromTo('#mobile-half-img', {
      y: window.innerHeight <= 400 ? '75px' : '150px',
    },
      {
        y: 0,
      });
    return tween;
  }

}
