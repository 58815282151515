import { Component, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-mobile-how-it-works',
  templateUrl: './mobile-how-it-works.component.html',
  styleUrls: ['./mobile-how-it-works.component.scss']
})
export class MobileHowItWorksComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit(): void {
    gsap.utils.toArray('.how-it-works-image').forEach((el, i) => {
      ScrollTrigger.create({
        trigger: el as any,
        start: 'top bottom',
        end: 'bottom top',
        scrub: .5,
        animation: gsap.fromTo(el as any, {y: '10%'}, {y: '-10%'}),
        invalidateOnRefresh: true,
      });
    });
  }

}
