<div class="mobile-footer-container">
  <div class="newsletter-subscription">
    <div>
      Stay updated with NextCentury by<br>
      subscribing to our newsletter
    </div>
  </div>
  <div class="input-box">
    <input (input)="emailAddressEntered($event)" placeholder="Your email address">
    <div class="input-btn" (click)="onSubmit()">
      <i class="icon-arrow-right"></i>
    </div>
  </div>
  <img src="assets/ncss-wordmark-blue.svg">
  <div class="copyright-text">
    &copy; {{copyrightYear}} NextCentury Submetering Solutions.<br>
    All rights reserved.<br>
    <a target="_blank" href="https://privacy-policy.nextcenturymeters.com">Privacy Policy</a>
  </div>
</div>
