<div class="outer-background gray-gradient">
  <ng-container *ngIf="(scrollService.isMobile$ | async)">
    <app-mobile-toolbar (homeClicked)="linkClicked($event)" (openMobileMenu)="openMobileMenu()"></app-mobile-toolbar>

    <section class="intro-header">
      <div class="intro-text">
        <p class="introducing">INTRODUCING THE</p>
        <p class="next-meter-text">NextMeter</p>
        <p class="nm-subtext">NM4-I (Indoor)</p>
        <div class="introducing flex-center">
          <a id="view-docs"
            href="https://support.nextcenturymeters.com/hc/en-us/articles/13176227189780-NM4-I-NextMeter-Indoor-Overview-and-Documents"
            target="_blank">
            View Documentation <i class="icon-chevron-right chevron"></i>
          </a>
          <span id="watch-video" (click)="watchVideo()">Watch Video <i class="icon-play-circle play"></i></span>
        </div>
      </div>

      <div class="spacer"></div>
      <div class="pin">
        <div class="canvas-container">
          <canvas id="nextMeterCanvas"></canvas>
        </div>
      </div>
      <div class="spacer-bottom"></div>
    </section>

    <div class="lower-sections">
      <section class="blue-section">
        <div class="width-third">
          The first and only <b>solid-state ultrasonic</b> meter designed for submetering
        </div>
      </section>

      <section class="ultrasonic-tech">
        <div>
          <h3 class="ultrasonic-tech-header">ULTRASONIC TECHNOLOGY</h3>
          NextMeter ultrasonic technology reliably detects even the smallest amount of flow and extends this level of
          accuracy across all flow rates.
          Without moving parts to where down over time, this reliability and accuracy is sustained over the full life of
          the meter.
        </div>
      </section>
      <app-divider class="divider"></app-divider>

      <section class="feature-list-section">
        <div class="feature-list">
          <div class="row">
            <div class="value">
              <i class="icon-meter"></i>
              <div class="feature-tile">
                <b>Cutting Edge Features</b>
                <ul>
                  <li>Hot and cold water</li>
                  <li>Directionless installation</li>
                </ul>
              </div>
            </div>

            <div class="value">
              <img id="non-icomoon-icon" src="assets/drawings/dollar.png"> <!-- TODO: put this in icon stack -->
              <div class="feature-tile">
                <b>Lowest Installation Costs</b>
                <ul>
                  <li>Single Visit</li>
                  <li>Easy Programming</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="value">
              <img id="non-icomoon-icon" src=" assets/drawings/open-flow-tube.svg">
              <!-- TODO: put this in icon stack -->
              <div class="feature-tile">
                <b>Open Flow Tube</b>
                <ul>
                  <li>Negligible Headloss</li>
                  <li>No Spacer Tube Required</li>
                </ul>
              </div>
            </div>

            <div class="value">
              <i class="icon-alert-triangle"></i>
              <div class="feature-tile">
                <b>New Alerts Available</b>
                <ul>
                  <li>Pipe Burst*</li>
                  <li>Backflow Detection</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="value">
              <i class="icon-award"></i>
              <div class="feature-tile">
                <b>Certified</b>
                <ul>
                  <li>FCC</li>
                  <li>IC</li>
                  <li>NTEP</li>
                  <li>CTEP</li>
                  <li>Conforms to AWWA</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="asterisk">* Requires activating leak monitoring service</div>
      </section>

      <section class="blue-section">
        <h2>FEATURES</h2>
        <br>
        <div class="feature-grid">
          <div class="grid-item"><i class="icon-eye"></i>Increased Usage Visibility</div>
          <div class="grid-item"><i class="icon-waves"></i>Fully NCSS Compatible</div>
          <!-- TODO: we need the filled waves icon-->
          <div class="grid-item"><i class="icon-settings"></i>Lasting Reliability & Serviceability</div>
          <div class="grid-item"><i class="icon-battery-charging"></i>Replaceable 10+ year Battery</div>
        </div>
      </section>

      <section class="blue-text feature-images">

        <div class="row">
          <img src="assets/drawings/nm4-i/1-meter-lid-open.svg" class="bump-up">
          <div class="value">
            <b>Dependable</b>
            <ul>
              <li>Consistent</li>
              <li>Reliable</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/2-gallons-per-minute.svg">
          <div class="value">
            <b>Multiple Flow Rates</b>
            <ul>
              <li>.01 to 30 gallons per minute</li>
              <li>Solid state</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/3-reflector-tube.svg">
          <div class="value">
            <b>Open Flow Tube</b>
            <ul>
              <li>End Spacer Tubes</li>
              <li>Avoid Clogs</li>
              <li>Eliminate Headloss</li>
              <li>Future Proof</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/4-horizontal-vertical.svg">
          <div class="value">
            <b>Directionless Install</b>
            <ul>
              <li>Resilient</li>
              <li>Eco-friendly</li>
              <li>Hygiene Optimized</li>
              <li>Horizontal Install</li>
              <li>Vertical Install</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/5-battery.svg">
          <div class="value">
            <b>Long Battery Life</b>
            <ul>
              <li>10 Year Battery Life</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/6-low-power.svg">
          <div class="value">
            <b>Low Power Mode</b>
            <ul>
              <li>Additional 2 Years</li>
              <li>No Multipliers</li>
              <li>No Inital Meter Reads</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/7-connected.svg">
          <div class="value">
            <b>NextCentury Platform</b>
            <ul>
              <li>Built-in Transceiver</li>
              <li>Easy Configuration</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <img src="assets/drawings/nm4-i/8-smart-alerts.svg">
          <div class="value">
            <b>Smart Alerts</b>
            <ul>
              <li>Leak Detection</li>
              <li>Freeze Detection</li>
              <li>Backflow Detection</li>
              <li>Burst Pipe Detection</li>
              <li>Low Battery Detection</li>
              <li>No Water Detection</li>
            </ul>
          </div>
        </div>

        <h3>Contact your distributor for pricing and availability</h3>
      </section>

      <section class="blue-section">
        <h2>Ready to learn more?</h2>
        <a href="https://support.nextcenturymeters.com/hc/en-us/articles/13176227189780-NM4-I-NextMeter-Indoor-Overview-and-Documents"
          target="_blank">
          <button class="btn learn-more">
            LEARN MORE
          </button>
        </a>

      </section>

      <app-mobile-footer></app-mobile-footer>
    </div>
  </ng-container>
  <ng-container *ngIf="!(scrollService.isMobile$ | async)">
    <app-toolbar [context]="'nm4i'" (linkClicked)="linkClicked($event)"></app-toolbar>

    <section class="intro-header">
      <div class="intro-text">
        <p class="introducing">INTRODUCING THE</p>
        <p class="next-meter-text">NextMeter</p>
        <p class="nm-subtext">NM4-I (Indoor)</p>
        <div class="introducing flex-center">
          <a id="view-docs"
            href="https://support.nextcenturymeters.com/hc/en-us/articles/13176227189780-NM4-I-NextMeter-Indoor-Overview-and-Documents"
            target="_blank">
            View Documentation <i class="icon-chevron-right chevron"></i>
          </a>
          <span id="watch-video" (click)="watchVideo()">Watch Video <i class="icon-play-circle play"></i></span>


        </div>
      </div>

      <div class="spacer"></div>
      <div class="pin">
        <div class="canvas-container">
          <canvas id="nextMeterCanvas"></canvas>
        </div>
      </div>
      <div class="spacer"></div>


    </section>

    <section class="blue-section">
      <div class="width-third">
        The first and only <b>solid-state ultrasonic</b> meter designed for submetering
      </div>
    </section>

    <section class="ultrasonic-tech">
      <div>
        <h3 class="ultrasonic-tech-header">ULTRASONIC TECHNOLOGY</h3>
        NextMeter ultrasonic technology reliably detects even the smallest amount of flow and extends this level of
        accuracy across all flow rates.
        Without moving parts to where down over time, this reliability and accuracy is sustained over the full life of
        the meter.
      </div>
    </section>
    <app-divider></app-divider>

    <section class="feature-list-section">
      <div class="feature-list">
        <div class="row">
          <div class="value">
            <i class="icon-meter"></i>
            <div class="feature-tile">
              <b>Cutting Edge Features</b>
              <ul>
                <li>Hot and cold water</li>
                <li>Directionless installation</li>
              </ul>
            </div>
          </div>

          <div class="value">
            <img id="non-icomoon-icon" src="assets/drawings/dollar.png"> <!-- TODO: put this in icon stack -->
            <div class="feature-tile">
              <b>Lowest Installation Costs</b>
              <ul>
                <li>Single Visit</li>
                <li>Easy Programming</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="value">
            <img id="non-icomoon-icon" src=" assets/drawings/open-flow-tube.svg"> <!-- TODO: put this in icon stack -->
            <div class="feature-tile">
              <b>Open Flow Tube</b>
              <ul>
                <li>Negligible Headloss</li>
                <li>No Spacer Tube Required</li>
              </ul>
            </div>
          </div>

          <div class="value">
            <i class="icon-alert-triangle"></i>
            <div class="feature-tile">
              <b>New Alerts Available</b>
              <ul>
                <li>Pipe Burst*</li>
                <li>Backflow Detection</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="value">
            <i class="icon-award"></i>
            <div class="feature-tile">
              <b>Certified</b>
              <ul>
                <li>FCC</li>
                <li>IC</li>
                <li>NTEP</li>
                <li>CTEP</li>
                <li>Conforms to AWWA</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="asterisk">* Requires activating leak monitoring service</div>
    </section>

    <section class="blue-section">
      <h2>FEATURES</h2>
      <br>
      <div class="feature-grid">
        <div class="grid-item"><i class="icon-eye"></i>Increased Usage Visibility</div>
        <div class="grid-item"><i class="icon-waves"></i>Fully NCSS Compatible</div>
        <!-- TODO: we need the filled waves icon-->
        <div class="grid-item"><i class="icon-settings"></i>Lasting Reliability & Serviceability</div>
        <div class="grid-item"><i class="icon-battery-charging"></i>Replaceable 10+ year Battery</div>
      </div>
    </section>

    <section class="blue-text feature-images">

      <div class="row">
        <img src="assets/drawings/nm4-i/1-meter-lid-open.svg" height="150px">
        <div class="value">
          <b>Dependable</b>
          <ul>
            <li>Consistent</li>
            <li>Reliable</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/2-gallons-per-minute.svg" height="150px">
        <div class="value">
          <b>Multiple Flow Rates</b>
          <ul>
            <li>.01 to 30 gallons per minute</li>
            <li>Solid state</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/3-reflector-tube.svg" height="150px">
        <div class="value">
          <b>Open Flow Tube</b>
          <ul>
            <li>End Spacer Tubes</li>
            <li>Avoid Clogs</li>
            <li>Eliminate Headloss</li>
            <li>Future Proof</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/4-horizontal-vertical.svg" height="150px">
        <div class="value">
          <b>Directionless Install</b>
          <ul>
            <li>Resilient</li>
            <li>Eco-friendly</li>
            <li>Hygiene Optimized</li>
            <li>Horizontal Install</li>
            <li>Vertical Install</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/5-battery.svg" height="150px">
        <div class="value">
          <b>Long Battery Life</b>
          <ul>
            <li>10 Year Battery Life</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/6-low-power.svg" height="150px">
        <div class="value">
          <b>Low Power Mode</b>
          <ul>
            <li>Additional 2 Years</li>
            <li>No Multipliers</li>
            <li>No Inital Meter Reads</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/7-connected.svg" height="150px">
        <div class="value">
          <b>NextCentury Platform</b>
          <ul>
            <li>Built-in Transceiver</li>
            <li>Easy Configuration</li>
          </ul>
        </div>
      </div>

      <div class="row">
        <img src="assets/drawings/nm4-i/8-smart-alerts.svg" height="150px">
        <div class="value">
          <b>Smart Alerts</b>
          <ul>
            <li>Leak Detection</li>
            <li>Freeze Detection</li>
            <li>Backflow Detection</li>
            <li>Burst Pipe Detection</li>
            <li>Low Battery Detection</li>
            <li>No Water Detection</li>
          </ul>
        </div>
      </div>

      <h3>Contact your distributor for pricing and availability</h3>
    </section>

    <section class="blue-section">
      <h2>Ready to learn more?</h2>
      <a href="https://support.nextcenturymeters.com/hc/en-us/articles/13176227189780-NM4-I-NextMeter-Indoor-Overview-and-Documents"
        target="_blank">
        <button class="btn learn-more">
          LEARN MORE
        </button>
      </a>

    </section>

    <app-footer id="footer" [context]="'nm4i'" (linkClicked)="linkClicked($event)"></app-footer>
  </ng-container>
</div>