import { ROOT_URL } from './../../globals';
import { SnackbarService } from './../../services/snackbar/snackbar.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss']
})
export class MobileFooterComponent implements OnInit {

  public copyrightYear = new Date().getFullYear();
  public email = '';

  constructor(private http: HttpClient, private snack: SnackbarService) { }

  ngOnInit(): void {}

  emailAddressEntered(event: any): void {
    this.email = event.target.value;
  }

  onSubmit(): void {
    this.http.post(`${ROOT_URL}newsletter-subscribe`, { email: this.email }).subscribe((res) => {
      this.snack.create('Subscribed!');
      this.email = '';
    }, (err) => {
      console.log(err);
      this.snack.create('Couldn\'t subscribe to newsletter at this time.');
    });
  }

}
