import { Links } from './../../globals';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  public links = Links;

  @Input() public context: 'home' | 'careers' | 'nm4i' = 'home';
  @Output() public linkClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
