import { PowerUsageCostComponent } from './power-usage-cost/power-usage-cost.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { CareersComponent } from './careers/careers.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeakSensorRoiComponent } from './leak-sensor-roi/leak-sensor-roi.component';
import { NM4IComponent } from './nm4i/nm4i.component';

const routes: Routes = [
  { path: 'careers', component: CareersComponent },
  { path: 'home', redirectTo: '' },
  { path: '', component: HomeComponent },
  { path: '3rd-party-upgrade', component: ThirdPartyComponent },
  { path: 'leak-monitoring', component: HomeComponent },
  { path: 'leak-roi', component: LeakSensorRoiComponent },
  { path: 'power-usage-cost', component: PowerUsageCostComponent },
  { path: 'nm4-i-overview', component: NM4IComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
