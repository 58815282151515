import { take } from 'rxjs/operators';
import { MobileMenuComponent } from './../components/mobile-menu/mobile-menu.component';
import { ScrollService } from './../services/scroll.service';
import { Router } from '@angular/router';
import { Links, ExternalLinks, LinkSectionIds } from './../globals';
import { Component, OnInit, ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-nm4i',
  templateUrl: './nm4i.component.html',
  styleUrls: ['./nm4i.component.scss'],
})
export class NM4IComponent implements OnInit {

  public mobileMenuOpen = false;

  public benefits = [
    { icon: 'savings.svg', text: '401(k)' },
    { icon: 'tooth.svg', text: 'Dental' },
    { icon: 'health-shield.svg', text: 'Medical' },
    { icon: 'eye.svg', text: 'Vision' },
    { icon: 'calendar-date-range.svg', text: 'Paid Time Off' },
    { icon: 'group-add.svg', text: 'Referral Program' },
    { icon: 'medical-services.svg', text: 'HSA Contributions' },
    { icon: 'coffee-cup.svg', text: 'Office/Local Cafe Perks' },
    { icon: 'school-graduation.svg', text: 'Development Programs' },
    { icon: 'fitness.svg', text: 'Health & Wellness' },
    { icon: 'table-tennis.svg', text: 'Ping Pong' },
    { icon: 'esports.svg', text: 'Virtual Reality Access' },
  ];

  constructor(
    public scrollService: ScrollService,
    private router: Router,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      const canvas: HTMLCanvasElement = document.getElementById("nextMeterCanvas") as HTMLCanvasElement;
      const context = canvas?.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = 1400;
      canvas.height = 788;

      const frameCount = 121;
      const currentFrame = (index: number) =>
        `assets/drawings/nm4-i/NM4-Transition/NM4-Transition-1400x788-${(
          index
        )
          .toString()
          .padStart(3, "0")}.png`;

      const images = <any>[]; // TODO: type this
      const nextMeter = {
        frame: 0
      };

      for (let i = 0; i < frameCount; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        images.push(img);
      }

      // Mobile Animation Configuration
      if (this.scrollService.isMobile$.value) {
        console.log('this is mobile');
        gsap.to(nextMeter, {
          frame: frameCount - 1,
          snap: "frame" as any, // TODO: Type this
          ease: "none",
          scrollTrigger: {
            trigger: ".intro-header",
            start: "top top",
            end: "+=3500",
            pin: ".intro-header",
            scrub: 0.5
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        });
      } else {
        console.log('this is desktop');
        gsap.to(nextMeter, {
          frame: frameCount - 1,
          snap: "frame" as any, // TODO: Type this
          ease: "none",
          scrollTrigger: {
            trigger: ".canvas-container",
            start: "top top",
            end: "+=3500",
            pin: ".pin",
            scrub: 0.5
          },
          onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        });
      }

      images[0].onload = render;

      function render() {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(images[nextMeter.frame], 0, 0);
      }

      gsap.to('.intro-text', {
        opacity: 0,
        scrollTrigger: {
          trigger: ".intro-header",
          start: "top top",
          end: "100",
          scrub: 0.5
        },
      });
    }, 10);
  }

  public openMobileMenu(): void {
    this.mobileMenuOpen = true;
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(MobileMenuComponent).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    componentRef.instance.dismiss.pipe(take(1)).subscribe((link: Links | undefined) => {
      if (link) {
        this.linkClicked(link);
      }
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, 600);
    });
  }

  public linkClicked(link: Links): void {
    const externalLink = ExternalLinks[link];
    const scrollToLink = LinkSectionIds[link];
    if (externalLink) {
      this.externalLinkTo(externalLink);
    } else if (link === Links.HOME) {
      this.router.navigateByUrl('');
    } else if (link === Links.CAREERS) {
      this.router.navigateByUrl('careers');
    } else if (scrollToLink) {
      this.router.navigateByUrl('').then(() => {
        setTimeout(() => {
          this.scrollToElement(scrollToLink);
        }, 200);
      });
    }
  }

  public watchVideo() {
    console.log('Watch Video'); // TODO: finish this
  }

  private scrollToElement(elId: string): void {
    const el = document.getElementById(elId);
    el?.scrollIntoView({ block: 'start' });
    window.scrollBy({ top: -75 });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }

  private externalLinkTo(url: string): void {
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('target', '_blank');
    el.click();
  }

}
