import { FormControl } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProductTab } from './../../globals';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-mobile-equipment-details',
  templateUrl: './mobile-equipment-details.component.html',
  styleUrls: ['./mobile-equipment-details.component.scss'],
  animations: [
    trigger('growIn', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.3s ease-out', style({ height: '*' })),
        animate('.1s ease-out', style({ opacity: '*' })),
      ]),
      transition(':leave', [
        style('*'),
        animate('.1s ease-out', style({ opacity: 0 })),
        animate('.3s ease-out', style({ height: 0 }))
      ])
    ])
  ],
})
export class MobileEquipmentDetailsComponent implements AfterViewInit {

  public selectionCtrl = new FormControl('');

  @Input() public info?: ProductTab;

  constructor() { }

  ngAfterViewInit(): void {
    this.selectionCtrl.valueChanges.subscribe((val) => {
      if (val) {
        this.linkTo(val);
        setTimeout(() => {
          this.selectionCtrl.setValue('');
        }, 500);
      }
    });
  }

  public learnMoreClicked(): void {
    if (this.info?.learnMoreUrl) {
      this.linkTo(this.info.learnMoreUrl);
    }
  }

  public linkTo(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

}
