<div class="our-team-container">
  <div class="white-gradient">
  </div>
  <div class="blue-gradient"></div>
  <div class="our-team-content">
    <img src="assets/our-team.png">
    <div class="our-team-text">
      <div class="large-variable-text">
        Our Team
      </div>
      <div class="description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare tincidunt pharetra nulla tristique.
      </div>
    </div>
  </div>
</div>
