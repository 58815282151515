<div class="welcome-background-container">
  <div class="welcome-content">
    <div class="large-variable-text title">Welcome to the future of Submetering</div>
    <div class="laptop">
      <div #videoContainer class="welcome-video" *ngIf="!(scrollService?.isMobile$ | async)">
        <iframe
        src="https://player.vimeo.com/video/565645077?color=007AB6&title=0&byline=0&portrait=0"
        width="100%"
        height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen>
      </iframe>
      </div>
      <div #videoContainer class="welcome-video" *ngIf="(scrollService?.isMobile$ | async)">
        <iframe
        src="https://player.vimeo.com/video/567938929?color=007AB6&title=0&byline=0&portrait=0"
        width="100%"
        height="100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen>
      </iframe>
      </div>
      <img src="assets/laptop.svg">
      <img src="assets/play-btn.png" class="play-btn" (click)="playBtnClicked()">
    </div>
  </div>
  <div class="gray-gradient"></div>
  <div class="white"></div>
</div>
