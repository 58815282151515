import { ISlideInfo } from './../product-slider/product-slider.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { ProductTab, ProductTabs } from './../../globals';
import { Component, Input, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-mobile-solutions',
  templateUrl: './mobile-solutions.component.html',
  styleUrls: ['./mobile-solutions.component.scss'],
  animations: [
    trigger('growIn', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.3s ease-out', style({ height: '*' })),
        animate('.1s ease-out', style({ opacity: '*' })),
      ]),
      transition(':leave', [
        style('*'),
        animate('.1s ease-out', style({ opacity: 0 })),
        animate('.3s ease-out', style({ height: 0 }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileSolutionsComponent implements OnInit {

  @ViewChild('sliderContainer') sliderContainer?: ElementRef;
  @ViewChild('slider') slider?: ElementRef;

  public equipment: ProductTab[] = ProductTabs;
  public slides: ISlideInfo[] = ProductTabs.filter((e) => !!e.sliderImg).map((e) => {
    return {
      imgUrl: e.sliderImg,
      title: e.title,
    };
  });

  public selectedEquipment?: ProductTab;
  public selectedSubTab?: ProductTab;


  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setSelectedEquipment(this.equipment[0]);
  }

  equipmentClicked(s: ISlideInfo): void {
    const found = this.equipment.find((e) => e.sliderImg === s.imgUrl && e.title === s.title);
    if (found) {
      this.setSelectedEquipment(found);
    }
  }

  private setSelectedEquipment(equipment: ProductTab) {
    this.selectedSubTab = undefined;
    this.selectedEquipment = equipment;
    if (this.selectedEquipment.subTabs) {
      this.selectedSubTab = this.selectedEquipment.subTabs[0];
    }
    this.cd.markForCheck();
  }
}
