import { ApplicationRef, Component, ComponentFactoryResolver, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MobileMenuComponent } from '../components/mobile-menu/mobile-menu.component';
import { ExternalLinks, Links, LinkSectionIds } from '../globals';
import { ScrollService } from '../services/scroll.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss']
})
export class ThirdPartyComponent implements OnInit {
  public mobileMenuOpen = false;

  constructor(
    public scrollService: ScrollService,
    private router: Router,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
  }

  public openMobileMenu(): void {
    this.mobileMenuOpen = true;
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(MobileMenuComponent).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    componentRef.instance.dismiss.pipe(take(1)).subscribe((link: Links | undefined) => {
      if (link) {
        this.linkClicked(link);
      }
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, 600);
    });
  }

  public linkClicked(link: Links): void {
    const externalLink = ExternalLinks[link];
    const scrollToLink = LinkSectionIds[link];
    if (externalLink) {
      this.externalLinkTo(externalLink);
    } else if (link === Links.HOME) {
      this.router.navigateByUrl('');
    } else if (link === Links.CAREERS) {
      this.router.navigateByUrl('careers');
    }else if (scrollToLink) {
      this.router.navigateByUrl('').then(() => {
        setTimeout(() => {
          this.scrollToElement(scrollToLink);
        }, 200);
      });
    }
  }

  private externalLinkTo(url: string): void {
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('target', '_blank');
    el.click();
  }

  private scrollToElement(elId: string): void {
    const el = document.getElementById(elId);
    el?.scrollIntoView({ block: 'start' });
    window.scrollBy({ top: -75 });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }

}
