import { gsap } from 'gsap';

export enum ApplicationType {
  MULTIFAMILY,
  COMMERCIAL,
  HIGH_RISE,
  MOBILE_HOME,
  UTILITIES,
}

export abstract class Application {
  public abstract type: ApplicationType;
  public abstract label: string;
  public abstract description: string;
  public abstract populateAnimateInTimeline(timeline: gsap.core.Timeline): void;
  public abstract populateAnimateOutTimeline(timeline: gsap.core.Timeline): void;
}

export class MultifamilyApplication extends Application {
  public type = ApplicationType.MULTIFAMILY;
  public label = 'Multifamily';
  public description = 'NextCentury\'s best-in-class communication protocol can reach 2x farther than any other product on the market. Our Repeater and Gateway products can handle more endpoint connections, allowing you to save on hardware costs.';

  public populateAnimateInTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#multifamilyWindow', { clipPath: 'circle(0px) ' }, 0);
    timeline.set('#multifamilySVG #tree', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#multifamilySVG', { opacity: 0 }, 0);
    timeline.set('#multifamilySVG #treeTrunk', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    const clipReveal = gsap.to('.multifamilyCenterRect', { attr: { y: '0%' }, duration: 1 });
    const treeTrunkReveal = gsap.to('#multifamilySVG #treeTrunk', { scaleY: 1, duration: .2, transformOrigin: 'bottom' });
    const opacityReveal = gsap.to('#multifamilySVG', { opacity: 1, duration: 1 });
    const windowsIn = gsap.to('#multifamilyWindow',
      { clipPath: 'circle(100%)', stagger: { from: [0, 0], grid: 'auto', amount: .8, axis: 'y' } });
    const treesIn = gsap.to('#multifamilySVG #tree', { clipPath: 'circle(100%)', stagger: { amount: .4 } });
    timeline.add(opacityReveal, .1);
    timeline.add(treeTrunkReveal, 1);
    timeline.add(clipReveal, .1);
    timeline.add(windowsIn, .4);
    timeline.add(treesIn, 1.3);
  }

  public populateAnimateOutTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#multifamilyWindow', { clipPath: 'circle(100%) ' }, 0);
    timeline.set('#multifamilySVG #tree', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#multifamilySVG', { opacity: 1 }, 0);
    timeline.set('#multifamilySVG #treeTrunk', { scaleY: 1, transformOrigin: 'bottom' }, 0);

    const clipOut = gsap.to('.multifamilyCenterRect', { attr: { y: '100%' }, duration: .8 });
    const opacityHide = gsap.to('#multifamilySVG', { opacity: 0, duration: .8 });
    const treeTrunkHide = gsap.to('#multifamilySVG #treeTrunk', { scaleY: 0, duration: .4, transformOrigin: 'bottom' });
    const windowsOut = gsap.to('#multifamilyWindow',
      { clipPath: 'circle(0px)', stagger: { from: [0, 0], grid: 'auto', amount: .6, axis: 'y' } });
    const treesOut = gsap.to('#multifamilySVG #tree', { clipPath: 'circle(0px)', stagger: { amount: .1 } });
    timeline.add(treeTrunkHide, .4);
    timeline.add(windowsOut, .2);
    timeline.add(treesOut, .1);
    timeline.add(opacityHide, .7);
    timeline.add(clipOut, .7);
  }
}

export class CommercialApplication extends Application {
  public type = ApplicationType.COMMERCIAL;
  public label = 'Commercial';
  public description = 'With customizable read intervals as frequent as every 15 minutes combined with an intuitive software you can better understand and plan your utility usage to avoid higher price brackets.';

  public populateAnimateInTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#commercialSVG', { opacity: 0 }, 0);
    timeline.set('#commercialWindow', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#commercialSVG #lampHead', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#commercialSVG #lampPost', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#pillar', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#canopy', { scaleY: 0, transformOrigin: 'top' }, 0);
    timeline.set('#commercialSVG #fenceRail', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#commercialSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#commercialSVG #stair', { opacity: 0 }, 0);
    const stairs = gsap.to('#commercialSVG #stair', { opacity: 1, stagger: { amount: .3 } });
    const fencePosts =
      gsap.to('#commercialSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const fenceRail = gsap.to('#commercialSVG #fenceRail', { scaleY: 1, transformOrigin: 'bottom', duration: .2 });
    const canopies = gsap.to('#canopy', { scaleY: 1, transformOrigin: 'top', duration: .3 });
    const pillars = gsap.to('#pillar', { scaleY: 1, duration: .3, transformOrigin: 'bottom' });
    const opacityReveal = gsap.to('#commercialSVG', { opacity: 1, duration: 1 });
    const clipReveal = gsap.to('.commercialCenterRect', { attr: { y: '0%' }, duration: 1 });
    const windowsIn = gsap.to('#commercialWindow',
      { clipPath: 'circle(100%)', stagger: { from: [0, 0], grid: 'auto', amount: .8, axis: 'y' } });
    const lampPostIn = gsap.to('#commercialSVG #lampPost', { scaleY: 1, duration: .2, transformOrigin: 'bottom' });
    const lampHeadIn = gsap.to('#commercialSVG #lampHead', { clipPath: 'circle(100%)', stagger: { amount: .3 } });
    timeline.add(opacityReveal, .1);
    timeline.add(stairs, .3);
    timeline.add(clipReveal, .1);
    timeline.add(pillars, .5);
    timeline.add(windowsIn, .4);
    timeline.add(fencePosts, .7);
    timeline.add(fenceRail, 1.1);
    timeline.add(lampPostIn, 1);
    timeline.add(canopies, 1.1);
    timeline.add(lampHeadIn, 1.2);
  }

  public populateAnimateOutTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#commercialSVG', { opacity: 1 }, 0);
    timeline.set('#commercialWindow', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#commercialSVG #lampHead', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#commercialSVG #lampPost', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#pillar', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#canopy', { scaleY: 1, transformOrigin: 'top' }, 0);
    timeline.set('#commercialSVG #fenceRail', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#commercialSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#commercialSVG #stair', { opacity: 1 }, 0);
    const stairs = gsap.to('#commercialSVG #stair', { opacity: 0, stagger: { amount: .3 } });
    const fencePosts =
      gsap.to('#commercialSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const fenceRail = gsap.to('#commercialSVG #fenceRail', { scaleY: 0, transformOrigin: 'bottom', duration: .2 });
    const canopies = gsap.to('#canopy', { scaleY: 0, transformOrigin: 'top', duration: .3 });
    const pillars = gsap.to('#pillar', { scaleY: 0, duration: .3, transformOrigin: 'bottom' });
    const opacityHide = gsap.to('#commercialSVG', { opacity: 0, duration: 1 });
    const clipHide = gsap.to('.commercialCenterRect', { attr: { y: '100%' }, duration: 1 });
    const windowsOut = gsap.to('#commercialWindow',
      { clipPath: 'circle(0px)', stagger: { from: [0, 0], grid: 'auto', amount: .6, axis: 'y' } });
    const lampPostOut = gsap.to('#commercialSVG #lampPost', { scaleY: 0, duration: .4, transformOrigin: 'bottom' });
    const lampHeadOut = gsap.to('#commercialSVG #lampHead', { clipPath: 'circle(0px)', stagger: { amount: .1 } });
    timeline.add(lampHeadOut, 0);
    timeline.add(canopies, .1);
    timeline.add(lampPostOut, .5);
    timeline.add(stairs, .7);
    timeline.add(pillars, .5);
    timeline.add(windowsOut, .1);
    timeline.add(fencePosts, .5);
    timeline.add(fenceRail, .4);
    timeline.add(opacityHide, .7);
    timeline.add(clipHide, .7);
  }
}

export class HighRiseApplication extends Application {
  public type = ApplicationType.HIGH_RISE;
  public label = 'High Rise';
  public description = 'With the NextCentury best-in-class radios you’ll be able to transmit read data between more floors than any other system on the market with minimal need for additional costly hardware.';

  public populateAnimateInTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#highriseSVG', { opacity: 0 }, 0);
    timeline.set('#highriseWindow', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#highriseSVG #fenceRail', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #tree', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#highriseSVG #treeTrunk', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #stair', { opacity: 0 }, 0);
    timeline.set('#highriseWindowSill', { scaleX: 0, transformOrigin: 'top' }, 0);
    const stairs = gsap.to('#highriseSVG #stair', { opacity: 1, stagger: { amount: .3 } });
    const fencePosts =
      gsap.to('#highriseSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const fenceRail = gsap.to('#highriseSVG #fenceRail', { scaleY: 1, transformOrigin: 'bottom', duration: .2 });
    const opacityReveal = gsap.to('#highriseSVG', { opacity: 1, duration: 1 });
    const clipReveal = gsap.to('.highRiseCenterRect', { attr: { y: '0%' }, duration: 1 });
    const windowsIn = gsap.to('#highriseWindow',
      { clipPath: 'circle(100%)', stagger: { from: 'end', grid: 'auto', amount: 1.2, axis: 'y' } });
    const treeTrunkReveal = gsap.to('#highriseSVG #treeTrunk', { scaleY: 1, duration: .2, transformOrigin: 'bottom' });
    const treesIn = gsap.to('#highriseSVG #tree', { clipPath: 'circle(100%)', stagger: { amount: .6 } });
    const windowSills = gsap.to('#highriseWindowSill', { scaleX: 1, transformOrigin: 'top', duration: .2 });
    timeline.add(opacityReveal, .1);
    timeline.add(stairs, .3);
    timeline.add(clipReveal, .1);
    timeline.add(windowsIn, .3);
    timeline.add(fencePosts, .7);
    timeline.add(treeTrunkReveal, 1);
    timeline.add(fenceRail, 1.1);
    timeline.add(treesIn, 1.1);
    timeline.add(windowSills, 1.2);
  }

  public populateAnimateOutTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#highriseSVG', { opacity: 1 }, 0);
    timeline.set('#highriseWindow', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#highriseSVG #fenceRail', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #tree', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#highriseSVG #treeTrunk', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#highriseSVG #stair', { opacity: 1 }, 0);
    timeline.set('#highriseWindowSill', { scaleX: 1, transformOrigin: 'top' }, 0);
    const stairs = gsap.to('#highriseSVG #stair', { opacity: 0, stagger: { amount: .3 } });
    const fencePosts =
      gsap.to('#highriseSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const fenceRail = gsap.to('#highriseSVG #fenceRail', { scaleY: 0, transformOrigin: 'bottom', duration: .2 });
    const opacityHide = gsap.to('#highriseSVG', { opacity: 0, duration: 1 });
    const clipHide = gsap.to('.highRiseCenterRect', { attr: { y: '100%' }, duration: 1 });
    const windowsOut = gsap.to('#highriseWindow',
      { clipPath: 'circle(0px)', stagger: { from: 'end', grid: 'auto', amount: 1.2, axis: 'y' } });
    const treeTrunkHide = gsap.to('#highriseSVG #treeTrunk', { scaleY: 0, duration: .3, transformOrigin: 'bottom' });
    const treesHide = gsap.to('#highriseSVG #tree', { clipPath: 'circle(0px)', stagger: { amount: .4 } });
    const windowSills = gsap.to('#highriseWindowSill', { scaleX: 0, transformOrigin: 'top', duration: .2 });
    timeline.add(windowSills, 0);
    timeline.add(windowsOut, 0);
    timeline.add(treesHide, 0);
    timeline.add(treeTrunkHide, .5);
    timeline.add(stairs, .3);
    timeline.add(fenceRail, .3);
    timeline.add(fencePosts, .4);
    timeline.add(clipHide, .7);
    timeline.add(opacityHide, .7);
  }
}

export class MobileHomeApplication extends Application {
  public type = ApplicationType.MOBILE_HOME;
  public label = 'Mobile Home';
  public description = 'Outdoor installations at mobile home properties are simple with our upcoming line of weatherproof outdoor Transceivers, which still boast the same long lasting and easily replaceable battery as our indoor Transceivers.';

  public populateAnimateInTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#mobilehomeSVG', { opacity: 0 }, 0);
    timeline.set('#mobilehomeSVG #shadow', { opacity: 0 }, 0);
    timeline.set('#mobileHomeWindow', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#mobilehomeSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#mobilehomeSVG #tree', { clipPath: 'circle(0px)' }, 0);
    timeline.set('#mobilehomeSVG #treeTrunk', { scaleY: 0, transformOrigin: 'bottom' }, 0);
    timeline.set('#mobilehomeSVG #stair', { opacity: 0 }, 0);
    timeline.set('#mobileHomeWindowSill', { scaleX: 0, transformOrigin: 'top' }, 0);
    timeline.set('#mobilehomeSVG #bush', { clipPath: 'circle(0px)' }, 0);
    const shadows = gsap.to('#mobilehomeSVG #shadow', { opacity: 1, duration: .3 });
    const stairs = gsap.to('#mobilehomeSVG #stair', { opacity: 1, stagger: { amount: .3, from: 'end' } });
    const fencePosts =
      gsap.to('#mobilehomeSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const opacityReveal = gsap.to('#mobilehomeSVG', { opacity: 1, duration: 1 });
    const clipReveal = gsap.to('.mobileHomeCenterRect', { attr: { y: '0%' }, duration: 1 });
    const windowsIn = gsap.to('#mobileHomeWindow',
      { clipPath: 'circle(100%)', stagger: { from: 'end', grid: 'auto', amount: .7, axis: 'y' } });
    const treeTrunkReveal = gsap.to('#mobilehomeSVG #treeTrunk', { scaleY: 1, duration: .2, transformOrigin: 'bottom' });
    const treesIn = gsap.to('#mobilehomeSVG #tree', { clipPath: 'circle(100%)', stagger: { amount: .2 } });
    const bushIn = gsap.to('#mobilehomeSVG #bush', { clipPath: 'circle(100%)', stagger: { amount: .6 } });
    const windowSills = gsap.to('#mobileHomeWindowSill', { scaleX: 1, transformOrigin: 'top', duration: .2 });
    timeline.add(opacityReveal, .1);
    timeline.add(clipReveal, .1);
    timeline.add(stairs, .3);
    timeline.add(windowsIn, .5);
    timeline.add(fencePosts, .7);
    timeline.add(treeTrunkReveal, 1);
    timeline.add(bushIn, .9);
    timeline.add(treesIn, 1.1);
    timeline.add(windowSills, 1.2);
    timeline.add(shadows, 1.3);
  }

  public populateAnimateOutTimeline(timeline: gsap.core.Timeline): void {
    timeline.set('#mobilehomeSVG', { opacity: 1 }, 0);
    timeline.set('#mobilehomeSVG #shadow', { opacity: 1 }, 0);
    timeline.set('#mobileHomeWindow', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#mobilehomeSVG #fencePost', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#mobilehomeSVG #tree', { clipPath: 'circle(100%)' }, 0);
    timeline.set('#mobilehomeSVG #treeTrunk', { scaleY: 1, transformOrigin: 'bottom' }, 0);
    timeline.set('#mobilehomeSVG #stair', { opacity: 1 }, 0);
    timeline.set('#mobileHomeWindowSill', { scaleX: 1, transformOrigin: 'top' }, 0);
    timeline.set('#mobilehomeSVG #bush', { clipPath: 'circle(100%)' }, 0);
    const shadows = gsap.to('#mobilehomeSVG #shadow', { opacity: 0, duration: .3 });
    const stairs = gsap.to('#mobilehomeSVG #stair', { opacity: 0, stagger: { amount: .3, from: 'end' } });
    const fencePosts =
      gsap.to('#mobilehomeSVG #fencePost', { scaleY: 0, transformOrigin: 'bottom', stagger: { amount: .4, grid: 'auto', from: [0, 0], axis: 'x' } });
    const opacityHide = gsap.to('#mobilehomeSVG', { opacity: 0, duration: 1 });
    const clipHide = gsap.to('.mobileHomeCenterRect', { attr: { y: '100%' }, duration: 1 });
    const windowsHide = gsap.to('#mobileHomeWindow',
      { clipPath: 'circle(0px)', stagger: { from: 'end', grid: 'auto', amount: .4, axis: 'y' } });
    const treeTrunkHide = gsap.to('#mobilehomeSVG #treeTrunk', { scaleY: 0, duration: .2, transformOrigin: 'bottom' });
    const treesOut = gsap.to('#mobilehomeSVG #tree', { clipPath: 'circle(0px)', stagger: { amount: .2 } });
    const bushOut = gsap.to('#mobilehomeSVG #bush', { clipPath: 'circle(0px)', stagger: { amount: .6 } });
    const windowSills = gsap.to('#mobileHomeWindowSill', { scaleX: 0, transformOrigin: 'top', duration: .2 });
    timeline.add(shadows, 0);
    timeline.add(windowSills, 0);
    timeline.add(bushOut, 0);
    timeline.add(windowsHide, 0);
    timeline.add(treesOut, .1);
    timeline.add(treeTrunkHide, .4);
    timeline.add(fencePosts, .2);
    timeline.add(stairs, .4);
    timeline.add(opacityHide, .4);
    timeline.add(clipHide, .4);
  }
}
