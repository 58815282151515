import { HoverExpandDirective } from './hover-expand.directive';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    HoverExpandDirective,
  ],
  exports: [
    HoverExpandDirective,
  ]
})
export class DirectivesModule { }
