<!-- mobile screen size -->
<ng-container *ngIf="(scrollService.isMobile$ | async)">
  <app-mobile-toolbar (openMobileMenu)="openMobileMenu()"></app-mobile-toolbar>
  <app-welcome></app-welcome>
  <app-divider></app-divider>
  <app-mobile-how-it-works></app-mobile-how-it-works>
  <app-mobile-applications id="applications"></app-mobile-applications>
  <app-mobile-solutions id="howItWorks"></app-mobile-solutions>
  <app-device-count></app-device-count>
  <app-mobile-features></app-mobile-features>
  <app-mobile-mission></app-mobile-mission>
  <app-mobile-footer></app-mobile-footer>
</ng-container>

<!-- desktop screen size -->
<ng-container *ngIf="!(scrollService.isMobile$ | async)">
  <app-toolbar (linkClicked)="linkClicked($event)"></app-toolbar>
  <app-welcome></app-welcome>
  <app-divider></app-divider>
  <app-how-it-works id="howItWorks"></app-how-it-works>
  <app-applications id="applications"></app-applications>
  <app-solutions id="ourSolution"></app-solutions>
  <app-device-count></app-device-count>
  <app-features></app-features>
  <app-mission></app-mission>
  <app-footer (linkClicked)="linkClicked($event)"></app-footer>
</ng-container>
