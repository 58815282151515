import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-numeric-input',
  templateUrl: './numeric-input.component.html',
  styleUrls: ['./numeric-input.component.scss']
})
export class NumericInputComponent implements OnChanges {
  @Input() inputNum: number = 0;
  @Input() isMonetaryValue: boolean = true;
  @Output() outputNum: EventEmitter<number> = new EventEmitter();
  @Output() blur: EventEmitter<Event> = new EventEmitter();

  public numString: string = '0';
  public isMoney: boolean = false;
  public hasChanged: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputNum) {
      if (changes.isMonetaryValue && changes.isMonetaryValue.currentValue) {
        this.isMoney = true;
        this.numString = formatCurrency(changes.inputNum.currentValue, 'en-US', '$', 'USD', '1.2-2');
      } else {
        this.numString = changes.inputNum.currentValue.toLocaleString();
      }
    }
  }

  addTrailingZeroes(e: any) {
    if (e && e.target.value && this.isMoney && this.hasChanged) {
      let num = parseFloat((e.target.value).replace(/[$,]/g, ''));
      this.numString = formatCurrency(num, 'en-US', '$', 'USD', '1.2-2');
    }
  }

  onInputChanged(e: string) {
    if (e) {
      this.hasChanged = true;
      let num = parseFloat(e.replace(/[$,]/g, ''));
      this.outputNum.next(num);
    } else {
      this.numString = '0';
      this.outputNum.next(0);
    }
  }

}
