<div class="mission-container">
  <div class="mission-title large-variable-text">
    Our Mission
  </div>
  <div class="values-container">
    <div class="values-title">"To promote conservation of utilities for the betterment of our communities."</div>
    <div class="row">
      <div class="value"><i class="icon-waves"></i>Beautifully Simple</div>
      <div class="value"><i class="icon-award"></i>Affordable Quality</div>
      <div class="value"><i class="icon-settings"></i>Effortless Integration</div>
    </div>
    <div class="row">
      <div class="value"><i class="icon-electric"></i>Continuous Innovation</div>
      <div class="value"><i><img src="assets/handshake.svg"></i>Unparalleled Service</div>
      <div class="value"><i><img src="assets/stars.svg"></i>Reaching for the Stars</div>
    </div>
  </div>
  <div class="team-photo-container">
    <img src="assets/our-team.png">
  </div>
  <div id="questions" class="mission-title large-variable-text">Questions?</div>
  <form [formGroup]="form">
    <div class="input-row">
      <label for="name">Name</label>
      <input class="custom-input" formControlName="name" placeholder="enter your name">
    </div>
    <div class="input-row">
      <label for="email">Email</label>
      <input class="custom-input" formControlName="email" placeholder="enter your email address">
    </div>
    <div class="input-row">
      <label for="phone">Phone</label>
      <input class="custom-input" formControlName="phone" placeholder="enter your phone number">
    </div>
    <div class="input-row">
      <label for="message">Message</label>
      <textarea class="custom-input" formControlName="message" placeholder="ask us a question..."></textarea>
    </div>
    <div class="submit-btn">
      <button class="btn btn-primary" (click)="submitForm()" [disabled]="form.invalid">Send Message</button>
    </div>
  </form>
  <p class="contact-us pad-top">
    or call us at <a href="tel:18445388203"><i class="icon-phone"></i>1-844-538-8203</a>
  </p>
</div>
