import { take } from 'rxjs/operators';
import { ScrollService } from './../services/scroll.service';
import { MobileMenuComponent } from './../components/mobile-menu/mobile-menu.component';
import { Links, LinkSectionIds, ExternalLinks } from './../globals';
import { Subscription } from 'rxjs';
import { Component, ComponentFactoryResolver, Injector, ApplicationRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy, AfterViewInit {

  public mobileMenuOpen = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    public scrollService: ScrollService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private router: Router,
    private location: Location,
  ) { }

  ngOnDestroy(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.location.path() === '/leak-monitoring') {
        this.scrollToElement('ourSolution');
      }
    }, 500);
  }

  public linkClicked(link: Links): void {
    const elId = LinkSectionIds[link];
    const externalLink = ExternalLinks[link];
    if (elId) {
      this.scrollToElement(elId);
    } else if (externalLink) {
      this.externalLinkTo(externalLink);
    } else if (link === Links.CAREERS) {
      this.router.navigateByUrl('careers');
    }
  }

  public openMobileMenu(): void {
    this.mobileMenuOpen = true;
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(MobileMenuComponent).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    componentRef.instance.dismiss.pipe(take(1)).subscribe((link: Links | undefined) => {
      if (link) {
        this.linkClicked(link);
      }
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, 600);
    });
  }

  private scrollToElement(elId: string): void {
    const el = document.getElementById(elId);
    el?.scrollIntoView({ block: 'start' });
    window.scrollBy({ top: -75 });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }

  private externalLinkTo(url: string): void {
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('target', '_blank');
    el.click();
  }
}
