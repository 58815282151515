import { take } from 'rxjs/operators';
import { MobileMenuComponent } from './../components/mobile-menu/mobile-menu.component';
import { ScrollService } from './../services/scroll.service';
import { Router } from '@angular/router';
import { Links, ExternalLinks, LinkSectionIds } from './../globals';
import { Component, OnInit, ApplicationRef, ComponentFactoryResolver, Injector } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
})
export class CareersComponent implements OnInit {

  public mobileMenuOpen = false;
  public benefits = [
    {icon: 'savings.svg', text: '401(k)'},
    {icon: 'tooth.svg', text: 'Dental'},
    {icon: 'health-shield.svg', text: 'Medical'},
    {icon: 'eye.svg', text: 'Vision'},
    {icon: 'calendar-date-range.svg', text: 'Paid Time Off'},
    {icon: 'group-add.svg', text: 'Referral Program'},
    {icon: 'medical-services.svg', text: 'HSA Contributions'},
    {icon: 'coffee-cup.svg', text: 'Office/Local Cafe Perks'},
    {icon: 'school-graduation.svg', text: 'Development Programs'},
    {icon: 'fitness.svg', text: 'Health & Wellness'},
    {icon: 'table-tennis.svg', text: 'Ping Pong'},
    {icon: 'esports.svg', text: 'Virtual Reality Access'},
  ];

  constructor(
    public scrollService: ScrollService,
    private router: Router,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) { }

  ngOnInit(): void { }

  public openMobileMenu(): void {
    this.mobileMenuOpen = true;
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(MobileMenuComponent).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    document.body.appendChild(componentRef.location.nativeElement);
    componentRef.instance.dismiss.pipe(take(1)).subscribe((link: Links | undefined) => {
      if (link) {
        this.linkClicked(link);
      }
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, 600);
    });
  }

  public linkClicked(link: Links): void {
    const externalLink = ExternalLinks[link];
    const scrollToLink = LinkSectionIds[link];
    if (externalLink) {
      this.externalLinkTo(externalLink);
    } else if (link === Links.HOME) {
      this.router.navigateByUrl('');
    } else if (scrollToLink) {
      this.router.navigateByUrl('').then(() => {
        setTimeout(() => {
          this.scrollToElement(scrollToLink);
        }, 200);
      });
    }
  }

  private scrollToElement(elId: string): void {
    const el = document.getElementById(elId);
    el?.scrollIntoView({ block: 'start' });
    window.scrollBy({ top: -75 });
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }

  private externalLinkTo(url: string): void {
    const el = document.createElement('a');
    el.setAttribute('href', url);
    el.setAttribute('target', '_blank');
    el.click();
  }
}
