<div class="mobile-features-container">
  <div class="large-variable-text">Key Features</div>
  <div class="alerts-img-container">
    <img src="assets/alerts-page.jpg">
  </div>
  <div class="features-list-container">
    <app-feature-details [featureList]="features[0]"></app-feature-details>
  </div>
  <div class="mobile-half-img-container">
    <img src="assets/mobile-half.jpg">
  </div>
  <app-divider></app-divider>
  <div class="features-list-container">
    <app-feature-details [featureList]="features[1]"></app-feature-details>
  </div>
  <div class="features-list-container">
    <app-feature-details [featureList]="features[2]"></app-feature-details>
  </div>
</div>
