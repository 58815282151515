import { SnackbarService } from './../../services/snackbar/snackbar.service';
import { ROOT_URL } from './../../globals';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { requiredFileType } from '../file-upload/file-upload.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take, filter } from 'rxjs/operators';
import { forEach as _forEach } from 'lodash-es';
import { gsap } from 'gsap';


const SoftwareSkills = {
  technical: ['HTML/CSS3', 'JavaScript/Typescript', 'Node.js', 'Angular (Angular 2+)', 'Mongo DB'],
  experience: ['Bachelor\'s Computer Science Degree', '6+ Year\'s Experience'],
  general: [
    'Ability to work on projects alone and as a part of a team',
    'Effectively manage time and tasks',
    'Excellent verbal and written communication skills',
    'Excellent problem-solving skills',
    'Self-Motivated',
    'Ability to work and provide solutions under pressure',
    'Attention to detail',
  ],
};
const HardwareSkills = {
  technical: [
    'Schematic and PCB Design (Altium Software)',
    'Experience in embedded firmware design (MCU)',
    'C/C++ (Embedded)',
    'Assembly',
    'Experience with embedded operating systems',
    'Experience with ARM architecture',
  ],
  experience: ['Bachelor\'s Engineering Degree', '3+ Year\'s Experience'],
  general: [
    'Ability to work on projects alone and as a part of a team',
    'Effectively manage time and tasks',
    'Excellent verbal and written communication skills',
    'Excellent problem-solving skills',
    'Self-Motivated',
    'Ability to work and provide solutions under pressure',
    'Attention to detail',
  ],
};

const SupportSkills = {
  technical: [
    'Good working knowledge of networking, hardware, and software',
    'Ability to explain problems and solutions clearly to non-technical users',
    'Ability to analyze and troubleshoot complicated software and electronics issues',
    'Proficient with office software (Microsoft Office, Google Suite)',
  ],
  experience: [
    'Previous technical experience',
    'High school diploma or equivalent',
    'Degree preferred (not required)',
  ],
  general: [
    'Ability to work on projects alone and as a part of a team',
    'Effectively manage time and tasks',
    'Excellent verbal and written communication skills',
    'Attention to detail',
    'Ability to follow through on tasks to completion',
    'Excellent customer service',
  ],
};

const HelpDeskSkills = {
  technical: [
    'Networking & system administration',
    'Windows and Mac OS setup and troubleshooting',
    'Strong working knowledge of software systems and management',
    'Excellent hands-on skills (running network cables, desk assembly, etc.)'
  ],
  experience: [
    'High School diploma or equivalent',
    'Previous help desk, system IT, and/or other technical experience required'
  ],
  general: [
    'Must be self-motivated and demonstrate excellent troubleshooting, communication, and organizational skills',
    'Ability to work on projects alone or as part of a team',
    'Good attention to detail',
    'Eagerness to take initiative and proactively improve systems and processes',
  ]
};

const TestingSpecialistSkills = {
  technical: [
    'Excellent troubleshooting and analysis skills',
    'Hands-on skills with assembly and wiring',
    'Good writing and documentation skills',
    'Good technical knowledge of software and database systems',
    'Good technical knowledge of networking and communication',
    'Good technical knowledge of hardware and PCB electronics',
  ],
  experience: [
    'High School diploma or equivalent',
    'Previous testing and/or other technical experience required'
  ],
  general: [
    'Must be self-motivated and demonstrate excellent troubleshooting, communication, and organizational skills',
    'Ability to work on projects alone or as part of a team',
    'Excellent attention to detail',
    'Eagerness to take initiative and proactively improve systems and processes',
  ]
};

const StaffAccountingSkills = {
  technical: [
    'Process vendor payments',
    'Research customer discrepancies and past-due amounts',
    'Assists Accounting Manager in reconciling general ledger accounts each month',
    'Assists with the monthly close',
    'Reconciles bank accounts',
    'Maintains knowledge of acceptable accounting practices and procedures',
    'Proficient with Microsoft Office Suite or similar software, and accounting software (NetSuite a plus)',
  ],
  experience: [
    'High School diploma or equivalent',
    "0-3 year's accounting experience"
  ],
  general: [
    'Excellent verbal and written communication skills',
    'Self-starter and accountable',
  ]
};


@Component({
  selector: 'app-open-positions',
  templateUrl: './open-positions.component.html',
  styleUrls: ['./open-positions.component.scss']
})
export class OpenPositionsComponent implements OnInit {

  public showingForm$ = new BehaviorSubject(false);
  public position = '';
  public applicationForm = new FormGroup({
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email, Validators.required]),
    phone: new FormControl('', [Validators.required]),
    coverLetter: new FormControl(null, [Validators.required, requiredFileType('pdf')]),
    cv: new FormControl(null, [Validators.required, requiredFileType('pdf')]),
  });
  public errorMsg$ = new BehaviorSubject<string>('');

  public skills = SoftwareSkills;


  private _closeFormSubscription?: Subscription;

  constructor(private http: HttpClient, private snack: SnackbarService) { }

  ngOnInit(): void {
    this.applicationForm.statusChanges.subscribe((val) => {
      this.updateErrorMsg();
    });
  }

  public applyNowClicked(position: string): void {
    this.position = position;
    this.updateSkills(position);
    this.showingForm$.next(true);
    setTimeout(() => {
      this.animateInForm().then(() => {
        const container = document.getElementById('applicationFormContainer');
        const containerRect = container?.getBoundingClientRect();
        const minX = containerRect?.left;
        const maxX = containerRect?.right;
        const minY = containerRect?.top;
        const maxY = containerRect?.bottom;
        if (minX && maxX && minY && maxY) {
          this._closeFormSubscription = fromEvent(document, 'click').pipe(
            filter((ev: any) => {
              return ev.x < minX || ev.x > maxX || ev.y < minY || ev.y > maxY;
            }),
            take(1),
          ).subscribe((ev) => {
            this.animateOutForm().then(() => {
              this.applicationForm.reset();
              this.showingForm$.next(false);
              this.position = '';
            });
          });
        }
      });
    }, 100);
  }

  public goToGusto(): void {
    document.location.href = 'https://jobs.gusto.com/boards/nextcentury-submetering-solutions-09d626e4-f037-453b-b873-9552974e5dca';
  }
  
  public closeApplicationForm(): void {
    this._closeFormSubscription?.unsubscribe();
    this.animateOutForm().then(() => {
      this.applicationForm.reset();
      this.showingForm$.next(false);
      this.position = '';
    });
  }

  public async submitApplicationForm(): Promise<void> {
    const data = new FormData();
    data.append('firstname', this.applicationForm.value.firstname);
    data.append('lastname', this.applicationForm.value.lastname);
    data.append('email', this.applicationForm.value.email);
    data.append('phone', this.applicationForm.value.phone);
    data.append('coverLetter', this.applicationForm.value.coverLetter);
    data.append('cv', this.applicationForm.value.cv);
    data.append('position', this.position);
    const res: any = await this.http.post(`${ROOT_URL}submit-application`, data).toPromise();
    if (res && res.success) {
      this.snack.create('Application submitted, thank you!');
      this.closeApplicationForm();
    } else {
      await this.snack.create('Unable to submit application at this time');
    }
  }

  private animateInForm(): Promise<void> {
    return new Promise((resolve) => {
      const tl = gsap.timeline();
      tl.add(gsap.fromTo('#applicationFormBackground', { opacity: 0 }, { opacity: 1, duration: .3 }), 0);
      tl.add(gsap.fromTo('#applicationFormContainer', { y: -200, opacity: 0 }, { y: 0, opacity: 1, duration: .4 }), .3);
      tl.play(0).then(() => {
        resolve();
      });
    });
  }

  private animateOutForm(): Promise<void> {
    return new Promise((resolve) => {
      const tl = gsap.timeline();
      tl.add(gsap.fromTo('#applicationFormContainer', { y: 0, opacity: 1 }, { y: -200, opacity: 0, duration: .4 }), 0);
      tl.add(gsap.fromTo('#applicationFormBackground', { opacity: 1 }, { opacity: 0, duration: .2 }), .4);
      tl.play(0).then(() => {
        resolve();
      });
    });
  }

  private updateSkills(position: string): void {
    if (position === 'Product Support Technician') {
      this.skills = SupportSkills;
    } else if (position === 'Software Engineer') {
      this.skills = SoftwareSkills;
    } else if (position === 'Hardware Engineer') {
      this.skills = HardwareSkills;
    } else if (position === 'General Help Desk') {
      this.skills = HelpDeskSkills;
    } else if (position === 'Product Testing Specialist') {
      this.skills = TestingSpecialistSkills;
    } else if (position === 'Staff Accountant') {
      this.skills = StaffAccountingSkills;
    }
  }

  private updateErrorMsg(): void {
    let msg = '';
    _forEach(this.applicationForm.controls, (c, key) => {
      if (c.hasError('email')) {
        msg = 'Please enter a valid email address.';
      }
      if (c.hasError('requiredFileType')) {
        msg = 'Uploaded file must be a PDF.';
      }
    });
    this.errorMsg$.next(msg);
  }


}
