import { Component, HostListener, Input, OnInit, ElementRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {

  onChange?: (val: any) => void;
  @Input() public placeholder = 'Choose a file';
  public file: File | null = null;

  constructor(private host: ElementRef<HTMLInputElement>) { }

  @HostListener('change', ['$event.target.files'])
  private emitFiles(event: FileList): void {
    const file = event && event.item(0);
    if (this.onChange) {
      this.onChange(file);
    }
    this.file = file;
  }

  writeValue(value: null): void {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

}

export function requiredFileType(type: string): ValidatorFn {
  return (control: AbstractControl) => {
    const file = control.value;
    if (file) {
      const extension = file.name.split('.')[1];
      if (!extension || extension.toLowerCase() !== type.toLowerCase()) {
        return {
          requiredFileType: true,
        };
      }
    }
    return null;
  };
}
