import { Links } from './../globals';
import { Router } from '@angular/router';
import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as c3 from 'c3';
import * as d3 from 'd3';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatCurrency } from '@angular/common';

function round(number: number) {
  const result = Math.round(number * 100) / 100
  return result
}

class Inputs {
  propertyName: string = '';
  numberOfUnits: number = 0;
  capRate: number = 0;
  leakSensorCost: number = 0.00;
  leakSensorQuantityPerUnit: number = 0;
  cableCost: number = 0.00;
  cableQuantityPerUnit: number = 0;
  remoteShutOffValveCost: number = 0.00;
  remoteShutOffValveQuantity: number = 0;
  toiletSensorCost: number = 0.00;
  toiletSensorQuantityPerUnit: number = 0;
  otherHardwareCost: number = 0.00;
  otherHardwareQuantityPerUnit: number = 0;
  remoteShutOffValveInstallationCost: number = 0.00;
  remoteShutOffValveInstallationQuantity: number = 0;
  installationCost: number = 0.00;
  installationQuantityPerUnit: number = 0;
  rebate: number = 0;
  rebateQuantity: number = 0;

  leakMonitoringCost: number = 0.00;
  autoShutOffServiceCost: number = 0.00;
  otherMonthlyCost: number = 0.00;

  leakMonitoringRevenue: number = 0.00;
  autoShutOffServiceRevenue: number = 0.00
  otherMonthlyRevenue: number = 0.00;

  insurancePremiumCost: number = 0.00;
  discountPercentage: number = 0

  insuranceDeductablePaid: number = 0.00;
  outOfPocketRepairCosts: number = 0.00;
  otherOccuranceCosts: number = 0.00;

  effectivenessRate = 1;

  avgPercentOfRunningToilets: number = 10;
  avgCostOfRunningToiletPerMonth: number = 70.00;
  avgGallonsLostDailyFromRunningToilet: number = 200;

  public calculate(payingParty?: string): Outputs {
    const i: InputPageOutputs = {} as InputPageOutputs;
    i.leakSensorPerUnitCost = this.leakSensorCost * this.leakSensorQuantityPerUnit;
    i.leakSensorTotalPropertyCost = this.leakSensorCost * this.leakSensorQuantityPerUnit * this.numberOfUnits;
    i.leakSensorCablePerUnitCost = this.cableCost * this.cableQuantityPerUnit;
    i.leakSensorCableTotalPropertyCost = this.cableCost * this.cableQuantityPerUnit * this.numberOfUnits;
    i.remoteShutoffValveTotalPropertyCost = this.remoteShutOffValveCost * this.remoteShutOffValveQuantity;
    i.toiletSensorPerUnitCost = this.toiletSensorCost * this.toiletSensorQuantityPerUnit;
    i.toiletSensorTotalPropertyCost = this.toiletSensorCost * this.toiletSensorQuantityPerUnit * this.numberOfUnits;
    i.otherHardwarePerUnitCost = this.otherHardwareCost;
    i.otherHardwareTotalPropertyCost = this.otherHardwareCost * this.numberOfUnits;
    i.remoteShutOffValveInstallationTotalPropertyCost = this.remoteShutOffValveInstallationCost * this.remoteShutOffValveInstallationQuantity;
    i.installationPerUnitCost = this.installationCost * this.installationQuantityPerUnit;
    i.installationTotalPropertyCost = i.installationPerUnitCost * this.numberOfUnits;
    i.rebateTotalProperty = this.rebate * this.rebateQuantity;
    i.totalHardwareCostPerUnit = i.leakSensorPerUnitCost + i.leakSensorCablePerUnitCost + i.toiletSensorPerUnitCost + i.otherHardwarePerUnitCost + i.installationPerUnitCost;
    i.totalHardwareCostTotalProperty = (i.leakSensorTotalPropertyCost + i.leakSensorCableTotalPropertyCost + i.toiletSensorTotalPropertyCost + i.otherHardwareTotalPropertyCost + i.installationTotalPropertyCost + i.remoteShutoffValveTotalPropertyCost + i.remoteShutOffValveInstallationTotalPropertyCost) - i.rebateTotalProperty;

    i.leakMonitoringTotalPropertyCost = this.leakMonitoringCost * this.numberOfUnits;
    i.autoShutOffServiceTotalPropertyCost = this.autoShutOffServiceCost * this.remoteShutOffValveQuantity;
    i.otherMonthlyRecurringPropertyCost = this.otherMonthlyCost * this.numberOfUnits;
    i.totalMonthlyRecurringCostPerUnit = this.leakMonitoringCost + this.otherMonthlyCost;
    i.totalMonthlyRecurringCostTotalProperty = ((this.leakMonitoringCost + this.otherMonthlyCost) * this.numberOfUnits) + i.autoShutOffServiceTotalPropertyCost;

    i.leakMonitoringTotalPropertyRevenue = this.leakMonitoringRevenue * this.numberOfUnits;
    i.autoShutOffServiceTotalPropertyRevenue = this.autoShutOffServiceRevenue * this.remoteShutOffValveQuantity;
    i.otherMonthlyRecurringPropertyRevenue = this.otherMonthlyRevenue * this.numberOfUnits;
    i.totalMonthlyRecurringRevenuePerUnit = payingParty === 'property' ? 0 : this.leakMonitoringRevenue + this.autoShutOffServiceRevenue + this.otherMonthlyRevenue;
    i.totalMonthlyRecurringRevenueTotalProperty = payingParty === 'property' ? 0 : (this.leakMonitoringRevenue + this.autoShutOffServiceRevenue + this.otherMonthlyRevenue) * this.numberOfUnits;

    i.annualPremiumCost = this.insurancePremiumCost * 12;
    i.monthlyInsuranceCostPerUnit = this.numberOfUnits == 0 ? 0 : this.insurancePremiumCost / this.numberOfUnits;
    i.annualInsuranceCostPerUnit = this.numberOfUnits == 0 ? 0 : this.insurancePremiumCost * 12 / this.numberOfUnits;

    i.monthlyLeakSensorMonitoringDiscount = (this.discountPercentage / 100) * this.insurancePremiumCost;
    i.annualLeakSensorMonitoringDiscount = (this.discountPercentage / 100) * this.insurancePremiumCost * 12;
    i.monthlyDiscountPerUnit = this.numberOfUnits == 0 ? 0 : (this.discountPercentage / 100) * this.insurancePremiumCost / this.numberOfUnits;
    i.annualDiscountPerUnit = this.numberOfUnits == 0 ? 0 : ((this.discountPercentage / 100) * this.insurancePremiumCost * 12) / this.numberOfUnits;

    i.totalLeakOccuranceCost = this.insuranceDeductablePaid + this.outOfPocketRepairCosts + this.otherOccuranceCosts;
    i.estimatedAnnualSavings = round(i.totalLeakOccuranceCost * this.effectivenessRate);
    i.estimatedAnnualSavingsPerUnit = round(this.numberOfUnits == 0 ? 0 : i.estimatedAnnualSavings / this.numberOfUnits);

    i.numberOfToilets = this.numberOfUnits * this.toiletSensorQuantityPerUnit;
    i.avgNumberOfRunningToilets = i.numberOfToilets * (this.avgPercentOfRunningToilets / 100);
    i.estimatedMonthlyWaterBillSavings = i.avgNumberOfRunningToilets * (this.avgCostOfRunningToiletPerMonth);
    i.estimatedAnnualWaterBillSavings = i.estimatedMonthlyWaterBillSavings * 12;

    i.estimatedGallonsOfWaterConservedMonthly = this.avgGallonsLostDailyFromRunningToilet * i.avgNumberOfRunningToilets * 30;
    i.estimatedGallonsOfWaterConservedAnnualy = i.estimatedGallonsOfWaterConservedMonthly * 12;

    const o: OutputPageOutputs = {} as OutputPageOutputs;
    o.recurringCosts = -(i.totalMonthlyRecurringCostTotalProperty * 12);
    o.recurringRevenue = i.totalMonthlyRecurringRevenueTotalProperty * 12;
    o.insuranceDiscountBenefit = i.annualLeakSensorMonitoringDiscount;
    o.estimatedAnnualOccuranceSavings = i.estimatedAnnualSavings;
    o.estimatedWaterConservationSavings = payingParty === 'resident' ? 0 : i.estimatedAnnualWaterBillSavings;
    o.deltaInNOI = o.recurringCosts + o.recurringRevenue + o.insuranceDiscountBenefit + o.estimatedAnnualOccuranceSavings + o.estimatedWaterConservationSavings;
    o.cumulativeCashFlow = { capitalExpense: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, };
    o.cumulativeCashFlow.capitalExpense = -i.totalHardwareCostTotalProperty;
    o.cumulativeCashFlow[1] = o.deltaInNOI - i.totalHardwareCostTotalProperty;
    o.cumulativeCashFlow[2] = o.cumulativeCashFlow[1] + o.deltaInNOI;
    o.cumulativeCashFlow[3] = o.cumulativeCashFlow[2] + o.deltaInNOI;
    o.cumulativeCashFlow[4] = o.cumulativeCashFlow[3] + o.deltaInNOI;
    o.cumulativeCashFlow[5] = o.cumulativeCashFlow[4] + o.deltaInNOI;
    o.fiveYearReturn = o.cumulativeCashFlow[5];
    o.roiPercent = o.cumulativeCashFlow.capitalExpense === 0 ? 0 : (o.fiveYearReturn / -o.cumulativeCashFlow.capitalExpense) * 100;
    o.capRate = this.capRate;
    o.paybackPeriodYears = round(parseFloat((o.deltaInNOI === 0 ? 0 : (-o.cumulativeCashFlow.capitalExpense / o.deltaInNOI)).toFixed(4)));
    o.estimatedPropertyValueIncrease = Math.round(o.capRate === 0 ? 0 : (o.deltaInNOI / (o.capRate / 100)));

    return {
      inputPage: i,
      outputPage: o,
    };
  }
}

interface Outputs {
  inputPage: InputPageOutputs;
  outputPage: OutputPageOutputs;
}

interface InputPageOutputs {
  leakSensorPerUnitCost: number;
  leakSensorTotalPropertyCost: number;
  leakSensorCablePerUnitCost: number;
  leakSensorCableTotalPropertyCost: number;
  remoteShutoffValvePerUnitCost: number;
  remoteShutoffValveTotalPropertyCost: number;
  toiletSensorPerUnitCost: number;
  toiletSensorTotalPropertyCost: number;
  otherHardwarePerUnitCost: number;
  otherHardwareTotalPropertyCost: number;
  remoteShutOffValveInstallationTotalPropertyCost: number;
  installationPerUnitCost: number;
  installationTotalPropertyCost: number
  rebateTotalProperty: number;
  totalHardwareCostPerUnit: number;
  totalHardwareCostTotalProperty: number;

  leakMonitoringTotalPropertyCost: number;
  autoShutOffServiceTotalPropertyCost: number;
  otherMonthlyRecurringPropertyCost: number;
  totalMonthlyRecurringCostPerUnit: number;
  totalMonthlyRecurringCostTotalProperty: number;

  leakMonitoringTotalPropertyRevenue: number;
  autoShutOffServiceTotalPropertyRevenue: number;
  otherMonthlyRecurringPropertyRevenue: number;
  totalMonthlyRecurringRevenuePerUnit: number;
  totalMonthlyRecurringRevenueTotalProperty: number;

  annualPremiumCost: number;
  monthlyInsuranceCostPerUnit: number;
  annualInsuranceCostPerUnit: number;
  monthlyLeakSensorMonitoringDiscount: number;
  annualLeakSensorMonitoringDiscount: number;
  monthlyDiscountPerUnit: number;
  annualDiscountPerUnit: number;

  totalLeakOccuranceCost: number;

  estimatedAnnualSavings: number;
  estimatedAnnualSavingsPerUnit: number;

  numberOfToilets: number;
  avgNumberOfRunningToilets: number;
  estimatedMonthlyWaterBillSavings: number;
  estimatedAnnualWaterBillSavings: number;

  estimatedGallonsOfWaterConservedMonthly: number;
  estimatedGallonsOfWaterConservedAnnualy: number;
}

interface OutputPageOutputs {
  fiveYearReturn: number;
  roiPercent: number;
  paybackPeriodYears: number;
  capRate: number;
  estimatedPropertyValueIncrease: number;

  recurringCosts: number;
  recurringRevenue: number;
  insuranceDiscountBenefit: number;
  estimatedAnnualOccuranceSavings: number;
  estimatedWaterConservationSavings: number;
  deltaInNOI: number;
  cumulativeCashFlow: {
    capitalExpense: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
}

@Component({
  selector: 'app-leak-sensor-roi',
  templateUrl: './leak-sensor-roi.component.html',
  styleUrls: ['./leak-sensor-roi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeakSensorRoiComponent implements AfterViewInit {
  inputs = new Inputs();
  outputs = new BehaviorSubject<Outputs>({} as Outputs);
  private chart?: c3.ChartAPI;
  public links = Links;
  public payingParty = 'resident';
  public toolTipOptions = {
    'placement': 'right',
    'theme': 'light',
    'hideDelay': 100,
    'animationDuration': 100
  }

  constructor(private router: Router) {
    this.outputs.next(this.inputs.calculate('resident'));
  }

  ngAfterViewInit(): void {
    this.chart = c3.generate({
      bindto: '#chart',
      size: {
        height: 400
      },
      color: {
        pattern: ['#4ba346b2']
      },
      data: {
        columns: [
          [
            'Cumulative Cash Flow',
            0,
            this.outputs.getValue().outputPage.cumulativeCashFlow[1],
            this.outputs.getValue().outputPage.cumulativeCashFlow[2],
            this.outputs.getValue().outputPage.cumulativeCashFlow[3],
            this.outputs.getValue().outputPage.cumulativeCashFlow[4],
            this.outputs.getValue().outputPage.cumulativeCashFlow[5],
          ]
        ],
        type: 'bar',
        color: (color, d) => {
          if (typeof (d as c3.DataPoint).index === 'undefined') { return color; }
          return (d as c3.DataPoint).value < 0 ? '#C4C4C4' : '#8cce88';
        }
      },
      grid: {
        y: {
          show: true,
        }
      },
      axis: {
        x: {
          tick: {
            values: [1, 2, 3, 4, 5]
          },
          padding: {
            left: 0,
            right: 1.5
          }
        },
        y: {
          tick: {
            format: function (n) { return `$${n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` }
          }
        }
      },
      tooltip: {
        format: {
          title: (d) => { return 'Data ' + d; },
          value: (value, ratio, id) => {
            const format = id === 'Cumulative Cash Flow' ? d3.format('$,') : d3.format('$');
            return format(value as number);
          },
        }
      },
      title: {
        text: 'Cumulative Cash Flow'
      },
    });
    this.chart.load({});
  }

  onInputChanged() {
    this.outputs.next(this.inputs.calculate(this.payingParty));
    this.chart!.load({
      columns: [
        [
          'Cumulative Cash Flow',
          0,
          this.outputs.getValue().outputPage.cumulativeCashFlow[1],
          this.outputs.getValue().outputPage.cumulativeCashFlow[2],
          this.outputs.getValue().outputPage.cumulativeCashFlow[3],
          this.outputs.getValue().outputPage.cumulativeCashFlow[4],
          this.outputs.getValue().outputPage.cumulativeCashFlow[5],
        ]
      ]
    });
    this.chart?.flush();
  }

  formatNumber(number: number | undefined, isNonMonetaryValue?: boolean, hideTrailingZeroes?: boolean) {
    if (number === undefined) {
      return '';
    }
    if (isNaN(number)) {
      number = 0;
    }
    if (isNonMonetaryValue) {
      return round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (hideTrailingZeroes) {
      return formatCurrency(number, 'en-US', '$', 'USD', (number % 1 == 0) ? '1.0-0' : '1.2-2');
    }
    return formatCurrency(number, 'en-US', '$', 'USD', '1.2-2');
  }

  changePayingParty(party: string) {
    this.payingParty = party;
    this.onInputChanged();
  }

  linkClicked(link: Links) {
    if (link === Links.HOME) {
      this.router.navigateByUrl('/');
    }
  }

  public downloadAsPdf(element: any) {
    html2canvas(document.getElementById(element)!).then(canvas => {
      const margin = 50;
      const height = element === 'calc-pdf' ? canvas.height : (canvas.height - 800);
      const pdf = new jsPDF('p', 'pt', [canvas.width, height]);
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const fileName = element === 'calc-pdf' ? 'Leak-Monitoring-ROI-Calculator.pdf' : 'Leak-Monitoring-ROI-Results.pdf';
      pdf.addImage(imgData, margin, margin, (canvas.width - (margin * 2)), (canvas.height - (margin * 2)));
      pdf.save(fileName);
    });
  }
}
