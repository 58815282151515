<div class="proven-reliability-container">
  <div class="background-container">
    <img src="assets/map.svg">
  </div>
  <div class="large-variable-text">Proven Reliability</div>
  <div class="xlarge-variable-text count-container">
    <div #odometerCount class="odometer">100000</div>
    <div>+</div>
  </div>
  <div class="medium-variable-text">active NextCentury devices</div>
</div>
