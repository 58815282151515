<div class="footer-container">
  <div class="footer-content">
    <div class="left-section">
      <img src="assets/ncss-wordmark-blue.svg">
      <div class="copyright-info">
        &copy; {{copyrightYear}} NextCentury Submetering Solutions.<br>
        All rights reserved.<br>
        <a target="_blank" href="https://privacy-policy.nextcenturymeters.com">Privacy Policy</a>
      </div>
    </div>
    <div class="right-section">
      <div class="right-column">
        <div class="link" (click)="linkClicked.emit(links.HOW_IT_WORKS)">How It Works</div>
        <div class="link" (click)="linkClicked.emit(links.APPLICATIONS)">Applications</div>
        <div class="link" (click)="linkClicked.emit(links.PRODUCTS)">Products</div>
      </div>
      <div class="right-column">
        <div class="link" (click)="linkClicked.emit(links.SUPPORT)">Support</div>
        <div class="link" (click)="linkClicked.emit(links.SIGN_IN)">Sign In</div>
        <div class="link" *ngIf="context === 'careers'" (click)="linkClicked.emit(links.HOME)">Home</div>
        <div class="link" *ngIf="context === 'home'" (click)="linkClicked.emit(links.CAREERS)">Careers</div>
      </div>
      <div class="right-column">
        <div class="newsletter-prompt">
          Stay updated with NextCentury by<br>
          subscribing to our newsletter
        </div>
        <div class="input-box">
          <input [formControl]="email" placeholder="Your email address" name="email" type="email">
          <div class="input-btn" [ngClass]="email.invalid ? 'disabled' : ''" (click)="onSubmit()">
            <i class="icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
