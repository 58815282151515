import { IFeaturesListItem } from './../features/features.component';
import { Component, Input, OnInit, AfterViewInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-feature-details',
  templateUrl: './feature-details.component.html',
  styleUrls: ['./feature-details.component.scss']
})
export class FeatureDetailsComponent implements OnInit, AfterViewInit {

  @Input() public featureList?: IFeaturesListItem;
  @ViewChild('featureListContainer') public container?: ElementRef;
  @ViewChildren('featureItem') public featureItems?: QueryList<ElementRef>;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      animation: this.getTimeline(),
      trigger: this.container?.nativeElement,
      start: 'top bottom',
      end: 'bottom top',
      toggleActions: 'play reset play reset',
    });
  }

  private getTimeline(): gsap.core.Tween {
    const els = [];
    els.push(this.container?.nativeElement);
    this.featureItems?.forEach((f) => els.push(f?.nativeElement));
    const tween = gsap.fromTo(els, {
      opacity: 0,
      x: '150px',
    }, {
      opacity: 1,
      x: 0,
      stagger: { amount: 1, from: 'start' },
    });
    return tween;
  }

}
