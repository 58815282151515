import { Links } from './../../globals';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss']
})
export class MobileToolbarComponent implements OnInit {

  public Links = Links;

  @Output() public openMobileMenu = new EventEmitter();
  @Output() public homeClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
}
