import { SnackbarModule } from './../services/snackbar/snackbar.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NgModule } from '@angular/core';
import { MobileToolbarComponent } from './mobile-toolbar/mobile-toolbar.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ApplicationsComponent } from './applications/applications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SolutionsComponent } from './solutions/solutions.component';
import { EquipmentDetailsComponent } from './equipment-details/equipment-details.component';
import { CommonModule } from '@angular/common';
import { DeviceCountComponent } from './device-count/device-count.component';
import { FeaturesComponent } from './features/features.component';
import { MobileFeaturesComponent } from './mobile-features/mobile-features.component';
import { FeatureDetailsComponent } from './feature-details/feature-details.component';
import { MissionComponent } from './mission/mission.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component';
import { FooterComponent } from './footer/footer.component';
import { MobileSolutionsComponent } from './mobile-solutions/mobile-solutions.component';
import { MobileApplicationsComponent } from './mobile-applications/mobile-applications.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { DirectivesModule } from '../directives/directives.module';
import { ProductSliderComponent } from './product-slider/product-slider.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { MobileHowItWorksComponent } from './mobile-how-it-works/mobile-how-it-works.component';
import { DividerComponent } from './divider/divider.component';
import { MobileEquipmentDetailsComponent } from './mobile-equipment-details/mobile-equipment-details.component';
import { MobileMissionComponent } from './mobile-mission/mobile-mission.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { OpenPositionsComponent } from './open-positions/open-positions.component';
import { NumericInputComponent } from './numeric-input/numeric-input.component';


@NgModule({
  declarations: [
    ToolbarComponent,
    MobileToolbarComponent,
    WelcomeComponent,
    ApplicationsComponent,
    SolutionsComponent,
    EquipmentDetailsComponent,
    DeviceCountComponent,
    FeaturesComponent,
    MobileFeaturesComponent,
    FeatureDetailsComponent,
    MissionComponent,
    OurTeamComponent,
    MobileFooterComponent,
    FooterComponent,
    MobileSolutionsComponent,
    MobileApplicationsComponent,
    MobileMenuComponent,
    ProductSliderComponent,
    HowItWorksComponent,
    MobileHowItWorksComponent,
    DividerComponent,
    MobileEquipmentDetailsComponent,
    MobileMissionComponent,
    FileUploadComponent,
    ProgressBarComponent,
    OpenPositionsComponent,
    NumericInputComponent,
  ],
  imports: [
    DirectivesModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SnackbarModule,
  ],
  exports: [
    MobileApplicationsComponent,
    MobileSolutionsComponent,
    FooterComponent,
    MobileFooterComponent,
    OurTeamComponent,
    SolutionsComponent,
    MissionComponent,
    DeviceCountComponent,
    EquipmentDetailsComponent,
    MobileFeaturesComponent,
    FeaturesComponent,
    FeatureDetailsComponent,
    ApplicationsComponent,
    ProductSliderComponent,
    WelcomeComponent,
    ToolbarComponent,
    MobileToolbarComponent,
    MobileMenuComponent,
    HowItWorksComponent,
    MobileHowItWorksComponent,
    DividerComponent,
    MobileEquipmentDetailsComponent,
    MobileMissionComponent,
    FileUploadComponent,
    ProgressBarComponent,
    OpenPositionsComponent,
    NumericInputComponent
  ]
})
export class ComponentsModule {}
