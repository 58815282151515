<div class="snackbar-container" [@showHide]="hide">
  <div class="snackbar">
    <span>
      {{message}}
    </span>
    <button class="btn btn-dark ml-3" (click)="onDismiss.next()">
      {{closeLabel}}
    </button>
  </div>
</div>
