import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  animations: [
    trigger('showHide', [
      state('true', style({transform: 'translateY(150%)'})),
      state('false', style({transform: 'translateY(0)'})),
      transition('* => false', [
        animate('500ms ease-out', style({transform: 'translateY(0)'})),
      ]),
      transition('false => *', [
        animate('500ms ease-out', style({transform: 'translateY(150%)'})),
      ]),
    ]),
  ],
})
export class SnackbarComponent implements OnInit {

  public message?: string;
  public closeLabel = 'Dismiss';
  public hide = false;
  public onDismiss = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
