import { ISlideInfo } from './../product-slider/product-slider.component';
import { ProductTab, ProductTabs } from './../../globals';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionsComponent implements OnInit {

  @ViewChild('sliderContainer') sliderContainer?: ElementRef;
  @ViewChild('slider') slider?: ElementRef;

  public equipment: ProductTab[] = ProductTabs;
  public slides: ISlideInfo[] = ProductTabs.filter((e) => !!e.sliderImg).map((e) => {
    return {
      imgUrl: e.sliderImg,
      title: e.title,
    };
  });

  public selectedEquipment?: ProductTab;
  public selectedSubTab?: ProductTab;

  constructor(
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.setSelectedEquipment(this.equipment[0]);
  }

  equipmentClicked(s: ISlideInfo): void {
    const found = this.equipment.find((e) => e.sliderImg === s.imgUrl && e.title === s.title);
    if (found) {
      this.setSelectedEquipment(found);
    }
  }

  private setSelectedEquipment(equipment: ProductTab) {
    this.selectedSubTab = undefined;
    this.selectedEquipment = equipment;
    if (this.selectedEquipment.subTabs) {
      this.selectedSubTab = this.selectedEquipment.subTabs[0];
    }
    this.cd.markForCheck();
  }
}
