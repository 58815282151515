import { ROOT_URL } from './../../globals';
import { SnackbarService } from './../../services/snackbar/snackbar.service';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-mobile-mission',
  templateUrl: './mobile-mission.component.html',
  styleUrls: ['./mobile-mission.component.scss']
})
export class MobileMissionComponent implements OnInit {

  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
    phone: new FormControl('', []),
  });

  constructor(
    private http: HttpClient,
    private snack: SnackbarService,
    ) { }

  ngOnInit(): void {
    ScrollTrigger.create({
      trigger: '.team-photo-container',
      start: 'top bottom',
      end: 'top top',
      scrub: .5,
      animation: gsap.fromTo('.team-photo-container', { y: '20px' }, { y: '-20px' }),
      invalidateOnRefresh: true,
    });
  }

  public submitForm(): void {
    if (this.form.valid) {
      this.form.disable();
      this.http.post(`${ROOT_URL}contactUs`, {
        name: this.form.value.name,
        email: this.form.value.email,
        phone: this.form.value.phone,
        messageBody: this.form.value.message
      }).subscribe(() => {
        this.snack.create('Message Sent!');
        this.form.reset();
        this.form.enable();
      }, () => {
        this.form.enable();
        this.snack.create('Couldn\'t send message at this time.');
      });
    }
  }

}
