<div class="how-it-works-container">
  <div class="large-variable-text">How it works</div>
  <div class="chart-container">
    <div class="drawings-background">
      <img class="dotted-line" src="assets/drawings/dotted-line.svg">
      <div class="dotted-line-mask"></div>
      <div class="drawings">
        <img src="assets/drawings/meter-and-transceiver-drawing.png">
        <img src="assets/drawings/repeater-drawing.png">
        <img src="assets/drawings/gateway-drawing.png">
        <img src="assets/drawings/cloud-drawing.png">
      </div>
    </div>
    <div class="descriptions">
      <div class="description">
        <div class="header">Meter + Transceiver</div>
        <div class="text">
          Each meter on your property is paired with a Transceiver to accurately read and communicate usage data.
        </div>
      </div>
      <div class="description">
        <div class="header">Repeater</div>
        <div class="text">
          Repeaters amplify Transceivers signals to accommodate large properties with thousands of meters.
        </div>
      </div>
      <div class="description">
        <div class="header">Gateway</div>
        <div class="text">
          The data across all meters on a property is collected by the Gateway and sent to the NextCentury Cloud.
        </div>
      </div>
      <div class="description">
        <div class="header">NextCentury Cloud</div>
        <div class="text">
          Alerts and usage data are stored securely in the cloud and accessible through the NextCentury platform.
        </div>
      </div>
    </div>
  </div>
  <div id="laptopHalf">
    <img src="assets/laptop-half.jpg">
  </div>
</div>
