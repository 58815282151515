import { ApplicationType } from './../applications/Application';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { Applications } from './../applications/applications.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Application } from '../applications/Application';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

@Component({
  selector: 'app-mobile-applications',
  templateUrl: './mobile-applications.component.html',
  styleUrls: ['./mobile-applications.component.scss']
})
export class MobileApplicationsComponent implements OnInit, AfterViewInit {
  @ViewChild('applicationsContent') public applicationsContent?: ElementRef;
  public ApplicationType = ApplicationType;
  public Applications = Applications;

  private _currentApplicationIndex$ = new BehaviorSubject<number>(0);
  public currentApplication$: Observable<Application> = this._currentApplicationIndex$.pipe(
    distinctUntilChanged(),
    debounceTime(100),
    map((i) => Applications[i]),
  );


  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    gsap.utils.toArray('.application-section').forEach((el: any, i) => {
      const tl = gsap.timeline();
      Applications[i]?.populateAnimateInTimeline(tl);
      tl.pause();
      ScrollTrigger.create({
        trigger: el,
        start: 'top center',
        end: 'bottom center',
        snap: {
          snapTo: [.5],
        },
        onUpdate: (instance) => {
          let progress = 0;
          if (instance.progress <= .5) {
            progress = (instance.progress * 2);
          } else {
            progress = (1 - ((instance.progress - .5) * 2));
          }
          tl.progress(progress);

          if (progress > 0) {
            this._currentApplicationIndex$.next(i);
          }
        },
      });
    });
    ScrollTrigger.create({
      trigger: this.applicationsContent?.nativeElement,
      start: 'top top+=25px',
      end: '+=1800px',
      pin: true,
      pinSpacing: false,
      anticipatePin: 1,
    });
  }

  applicationClicked(index: number): void {
    gsap.to(window, {scrollTo: `#animationSection-${index}`, duration: 1}).then(() => {
      ScrollTrigger.refresh();
    });
  }
}
