<div class="applications-outer-container gray-gradient">
  <div #contentContainer class="content-container">
    <div class="left">
      <div class="large-variable-text blue">Applications</div>
      <div class="spacer"></div>
      <div class="application-description">The simplicity of installation combined with our passion for providing the highest quality products and services has led NextCentury’s solution to become the new standard across all applications of submetering.</div>
      <div *ngFor="let a of Applications; let i = index" class="application-section">
        <div class="application-header" (click)="applicationClicked(i)" [ngClass]="(currentApplication$ | async)?.type === a.type ? '' : 'blue'">{{a.label}}</div>
        <div @growIn *ngIf="(currentApplication$ | async)?.type === a.type" class="spacer"></div>
        <div @growIn *ngIf="(currentApplication$ | async)?.type === a.type" class="application-description">{{a.description}}</div>
      </div>
    </div>
    <div class="right">
      <div class="ground"></div>
      <svg class="building-drawing" id="multifamilySVG" viewBox="0 0 447 442" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Multifamily</title>
        <defs>
          <clipPath id="multifamilyClipPath">
            <rect class="multifamilyCenterRect" x="0" y="100%" width="100%" height="100%" fill="none"></rect>
          </clipPath>
        </defs>
        <g id="multifamilyClipReveal" clip-path="url(#multifamilyClipPath)">
          <g id="Figma-Imports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Multifamily_Updated" transform="translate(0.000000, 0.000000)" fill-rule="nonzero">
              <polygon id="Path" fill="#2E2E2E"
                points="443.958142 441.493508 436.834948 441.493508 438.185606 422.943726 442.608485 422.943726">
              </polygon>
              <polygon id="Path" fill="#000000"
                points="441.56375 361.223088 439.229341 361.223088 439.229341 441.493508 441.56375 441.493508">
              </polygon>
              <polygon id="Path" fill="#000000"
                points="444.073113 361.223088 436.719978 361.223088 434.019662 347.876842 446.773429 347.876842">
              </polygon>
              <polygon id="Path" fill="#FFFFFF"
                points="442.709459 358.750783 438.083632 358.750783 436.387062 350.349146 444.413027 350.349146">
              </polygon>
              <polygon id="Path" fill="#313131"
                points="444.073113 344.480797 436.719978 344.480797 434.019662 347.876842 446.773429 347.876842">
              </polygon>
              <path
                d="M444.629972,361.223088 L436.184113,361.223088 C436.004159,361.223088 435.831203,361.152108 435.704235,361.024144 C435.577267,360.897179 435.505286,360.724228 435.505286,360.544279 C435.505286,360.363329 435.577267,360.191378 435.704235,360.063414 C435.831203,359.93645 436.004159,359.86447 436.184113,359.86447 L444.629972,359.86447 C444.809927,359.86447 444.982883,359.93645 445.10985,360.063414 C445.236818,360.191378 445.3088,360.363329 445.3088,360.544279 C445.3088,360.724228 445.236818,360.897179 445.10985,361.024144 C444.982883,361.152108 444.809927,361.223088 444.629972,361.223088 Z"
                id="Path" fill="#313131"></path>
              <path
                d="M442.710459,423.629533 L438.096629,423.629533 C437.916674,423.629533 437.744718,423.558553 437.61775,423.430589 C437.489782,423.303624 437.4188,423.130673 437.4188,422.950724 C437.4188,422.770774 437.489782,422.597823 437.61775,422.470859 C437.744718,422.342895 437.916674,422.271915 438.096629,422.271915 L442.710459,422.271915 C442.890413,422.271915 443.06237,422.342895 443.190337,422.470859 C443.317305,422.597823 443.388287,422.770774 443.388287,422.950724 C443.388287,423.130673 443.317305,423.303624 443.190337,423.430589 C443.06237,423.558553 442.890413,423.629533 442.710459,423.629533 Z"
                id="Path" fill="#313131"></path>
              <path
                d="M440.403044,346.484234 C441.508763,346.484234 442.404536,345.587486 442.404536,344.480797 C442.404536,343.374109 441.508763,342.476361 440.403044,342.476361 C439.298324,342.476361 438.402551,343.374109 438.402551,344.480797 C438.402551,345.587486 439.298324,346.484234 440.403044,346.484234 Z"
                id="Path" fill="#000000"></path>
              <path
                d="M91.7534376,441.493508 L51.5177386,441.493508 C51.7698747,440.520781 52.2313577,439.614036 52.8700957,438.839254 C53.5091337,438.063472 54.308931,437.436649 55.2147013,437.00377 C57.0152448,436.270977 59.0247354,436.241985 60.8452738,436.922793 C60.5063597,436.813824 60.0524748,436.114021 59.8145351,435.822103 C59.2066892,435.138295 58.8067906,434.294533 58.6608276,433.390787 C58.5158644,432.31409 58.7747987,431.222397 59.3876433,430.326649 C59.8685214,429.670833 60.5203562,429.158977 61.2711658,428.847065 C62.0219755,428.534153 62.8437671,428.433181 63.6475633,428.554147 L63.8785048,428.554147 C64.181428,428.608132 64.4773529,428.697107 64.7602812,428.819073 C66.0189621,429.442897 67.1326797,430.324649 68.0304521,431.407345 C68.2064075,431.611287 68.3823629,431.81423 68.5383234,432.032169 C68.5383234,432.032169 65.9269854,429.53887 65.3091421,427.651401 C63.0367182,422.271915 67.8405003,414.460112 73.816985,416.667491 C76.2183762,417.829164 78.1788792,420.199498 79.4675524,422.99871 C74.7187564,414.800016 82.6157543,409.814419 85.4310405,417.191344 C83.6874826,411.112054 86.2858238,404.747844 91.5364926,408.319839 C91.6124733,408.349831 91.6874543,408.383821 91.7604358,408.421811 L91.7534376,441.493508 Z"
                id="Path" fill="#379F4D"></path>
              <path
                d="M91.7534376,441.493508 L68.050447,441.493508 C68.1994093,440.919669 68.4713403,440.385819 68.847245,439.927948 C69.2231497,439.471076 69.6950301,439.10118 70.227895,438.844253 C71.2896258,438.417373 72.4733257,438.402377 73.545054,438.803264 C73.3151123,438.180439 73.316112,437.494632 73.5470535,436.871808 C73.7789947,436.247983 74.2258814,435.729129 74.8077339,435.40722 C75.8654657,434.925355 77.0531646,435.326242 78.076905,435.924074 C77.142142,435.331241 76.4623144,434.4115 76.1703884,433.3428 C74.8137323,430.177691 77.66301,425.572986 81.1841172,426.876619 C82.6867363,427.703387 83.8624382,429.019017 84.5152727,430.60557 C81.6999865,425.769931 86.3668033,422.835756 88.0293818,427.209526 C86.4147911,422.454863 89.5290015,419.669647 91.7534376,420.743345 L91.7534376,441.493508 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M67.1756688,415.513816 C67.3806169,415.71076 67.6255548,415.862717 67.8924871,415.96069 C68.1594194,416.058662 68.4443472,416.100651 68.7282752,416.083655 C68.6602924,415.419842 68.4013581,414.790019 67.9824643,414.270165 C67.7755167,414.07522 67.5315786,413.924263 67.2636465,413.82729 C66.9967142,413.730317 66.7127862,413.689329 66.4288582,413.706324 C66.4978407,414.368138 66.7577748,414.995961 67.1756688,415.513816 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M85.4580337,423.154666 C85.7739536,423.756497 86.3118172,424.212369 86.9576535,424.424309 C87.0946187,423.662523 87.0176383,422.876744 86.7337102,422.155947 C86.4197898,421.555116 85.8839257,421.099244 85.2410887,420.886304 C85.0991247,421.64709 85.1741057,422.433869 85.4580337,423.154666 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M69.1091786,412.918546 C68.9732131,413.855282 69.0971817,414.812013 69.4680876,415.682768 C70.148915,415.100932 70.6247944,414.315153 70.8247437,413.441399 C70.9607092,412.504662 70.8367406,411.547931 70.4658347,410.677176 C69.7850073,411.259012 69.3091279,412.044791 69.1091786,412.918546 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M402.256715,441.493508 L442.486516,441.493508 C442.235579,440.520781 441.774696,439.615036 441.137858,438.839254 C440.500019,438.064472 439.700222,437.437648 438.795451,437.00377 C436.994908,436.270977 434.985417,436.241985 433.164879,436.922793 C433.497795,436.813824 433.958678,436.114021 434.196617,435.822103 C434.801464,435.136296 435.201363,434.293533 435.349325,433.390787 C435.445301,432.718976 435.381317,432.034168 435.162372,431.393349 C434.942428,430.751529 434.574522,430.170693 434.088645,429.698825 C433.601768,429.226958 433.010918,428.877057 432.363082,428.678112 C431.715246,428.479168 431.03042,428.43718 430.363589,428.554147 L430.132648,428.554147 C429.827725,428.608132 429.528801,428.697107 429.243873,428.819073 C427.988191,429.444897 426.877473,430.326649 425.9807,431.407345 C425.804745,431.611287 425.62779,431.81423 425.464831,432.032169 C425.464831,432.032169 428.084167,429.53887 428.701011,427.651401 C430.967436,422.271915 426.163654,414.460112 420.194167,416.667491 C417.792776,417.829164 415.832273,420.199498 414.536602,422.99871 C419.318389,414.800016 411.395398,409.814419 408.580112,417.191344 C410.316672,411.112054 407.725329,404.747844 402.47466,408.319839 C402.399679,408.319839 402.331696,408.38782 402.256715,408.421811 L402.256715,441.493508 Z"
                id="Path" fill="#379F4D"></path>
              <path
                d="M402.256715,441.493508 L425.960705,441.493508 C425.811743,440.919669 425.539812,440.385819 425.163907,439.927948 C424.787003,439.471076 424.316122,439.10118 423.782258,438.844253 C422.720527,438.417373 421.537827,438.402377 420.465099,438.803264 C420.694041,438.180439 420.693041,437.494632 420.4611,436.871808 C420.230158,436.248983 419.784271,435.730129 419.203419,435.40722 C418.138689,434.925355 416.95099,435.326242 415.933248,435.924074 C416.868011,435.33324 417.546839,434.4115 417.832766,433.3428 C419.190422,430.177691 416.341144,425.572986 412.820037,426.876619 C411.320417,427.707386 410.146715,429.022016 409.488882,430.60557 C412.311166,425.769931 407.637351,422.835756 405.981771,427.209526 C407.596361,422.454863 404.482151,419.669647 402.256715,420.743345 L402.256715,441.493508 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M426.80849,415.512816 C426.601543,415.71076 426.355605,415.862717 426.087673,415.96069 C425.818741,416.059662 425.532814,416.100651 425.247886,416.083655 C425.319868,415.420842 425.578802,414.792019 425.993697,414.270165 C426.202644,414.07522 426.447582,413.924263 426.716514,413.82729 C426.984446,413.730317 427.269374,413.689329 427.554301,413.706324 C427.48232,414.367138 427.223385,414.993962 426.80849,415.512816 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M408.546121,423.154666 C408.2332,423.757497 407.697336,424.213368 407.053499,424.424309 C406.915534,423.662523 406.993514,422.876744 407.277442,422.155947 C407.589363,421.553117 408.125227,421.097245 408.769064,420.886304 C408.907029,421.64809 408.830049,422.433869 408.546121,423.154666 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M424.901974,412.918546 C425.037939,413.855282 424.913971,414.812013 424.542065,415.682768 C423.862237,415.100932 423.386358,414.315153 423.185409,413.441399 C423.049444,412.504662 423.174412,411.547931 423.545318,410.677176 C424.226145,411.259012 424.702025,412.044791 424.901974,412.918546 Z"
                id="Path" fill="#54CF6E"></path>
              <polygon id="Path" fill="#F0F0F0"
                points="403.451412 10.2767092 92.8531588 10.2767092 92.8531588 418.318027 403.451412 418.318027">
              </polygon>
              <polygon id="Path" fill="#404040"
                points="415.262418 418.312028 81.0421532 418.312028 81.0421532 441.493508 415.262418 441.493508">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="336.981265 433.763682 159.329305 433.763682 159.329305 441.493508 336.981265 441.493508">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="323.800606 426.040854 172.509963 426.040854 172.509963 433.77068 323.800606 433.77068">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="307.552726 418.312028 188.757844 418.312028 188.757844 426.040854 307.552726 426.040854">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="307.552726 424.486292 188.757844 424.486292 188.757844 426.034856 307.552726 426.034856">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="323.800606 432.215118 172.509963 432.215118 172.509963 433.763682 323.800606 433.763682">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="403.457411 257.26833 273.639324 257.26833 273.639324 336.540031 403.457411 336.540031">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="276.488601 257.26833 273.639324 257.26833 273.639324 336.540031 276.488601 336.540031">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="403.451412 348.195752 92.8531588 353.432279 92.8531588 346.810142 403.451412 346.810142">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="273.632325 263.782498 92.8531588 269.026023 92.8531588 262.402886 273.632325 262.402886">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="403.451412 264.882188 273.632325 272.041175 273.632325 262.402886 403.451412 262.402886">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="291.935685 324.192504 287.010934 324.192504 287.010934 336.540031 291.935685 336.540031">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="348.921237 324.192504 343.996486 324.192504 343.996486 336.540031 348.921237 336.540031">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="405.913788 324.192504 400.988037 324.192504 400.988037 336.540031 405.913788 336.540031">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="377.421012 324.192504 372.495261 324.192504 372.495261 336.540031 377.421012 336.540031">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="320.428461 324.192504 315.50371 324.192504 315.50371 336.540031 320.428461 336.540031">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="291.935685 324.192504 287.010934 324.192504 287.010934 326.657811 291.935685 326.657811">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="348.921237 324.192504 343.996486 324.192504 343.996486 326.657811 348.921237 326.657811">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="405.913788 324.192504 400.988037 324.192504 400.988037 326.657811 405.913788 326.657811">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="377.421012 324.192504 372.495261 324.192504 372.495261 326.657811 377.421012 326.657811">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="320.428461 324.192504 315.50371 324.192504 315.50371 326.657811 320.428461 326.657811">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="411.503371 321.957133 273.639324 321.957133 273.639324 324.192504 411.503371 324.192504">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="208.329882 346.810142 192.990771 346.810142 192.990771 418.312028 208.329882 418.312028">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="303.318799 346.810142 287.980688 346.810142 287.980688 418.312028 303.318799 418.312028">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="92.852159 252.676622 222.671246 252.676622 222.671246 173.404921 92.852159 173.404921">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="219.821968 252.676622 222.671246 252.676622 222.671246 173.404921 219.821968 173.404921">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="222.671246 179.926087 403.451412 185.162614 403.451412 178.540476 222.671246 178.540476">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="92.8531588 181.018779 222.671246 188.184763 222.671246 178.540476 92.8531588 178.540476">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="204.367886 252.676622 209.293637 252.676622 209.293637 240.329095 204.367886 240.329095">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="147.382334 252.676622 152.308085 252.676622 152.308085 240.329095 147.382334 240.329095">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="90.3967815 252.676622 95.3225327 252.676622 95.3225327 240.329095 90.3967815 240.329095">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="118.882559 252.676622 123.808311 252.676622 123.808311 240.329095 118.882559 240.329095">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="175.882108 252.676622 180.80686 252.676622 180.80686 240.329095 175.882108 240.329095">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="204.367886 242.794402 209.293637 242.794402 209.293637 240.329095 204.367886 240.329095">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="147.382334 242.794402 152.308085 242.794402 152.308085 240.329095 147.382334 240.329095">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="90.3967815 242.794402 95.3225327 242.794402 95.3225327 240.329095 90.3967815 240.329095">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="118.882559 242.794402 123.808311 242.794402 123.808311 240.329095 118.882559 240.329095">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="175.882108 242.794402 180.80686 242.794402 180.80686 240.329095 175.882108 240.329095">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="84.8071987 240.335094 222.671246 240.335094 222.671246 238.100722 84.8071987 238.100722">
              </polygon>
              <g id="multifamilyWindow" transform="translate(138.569568, 192.178640)">
                <polygon id="Path" fill="#F0F0F0" points="0 60.4979819 54.2312507 60.4979819 54.2312507 0 0 0">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.9112619 57.3938551 25.5425242 57.3938551 25.5425242 3.28107703 2.9112619 3.28107703">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="28.8456867 57.3938551 51.4779487 57.3938551 51.4779487 3.28107703 28.8456867 3.28107703">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(319.824614, 193.333315)">
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6536365 46.8938087 68.0637437 46.8938087 68.0637437 15.3026953 56.6536365 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6536365 12.5864594 68.0637437 12.5864594 68.0637437 1.8474803 56.6536365 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.423836 46.8938087 54.3192283 46.8938087 54.3192283 15.3026953 16.423836 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.423836 12.5864594 54.3192283 12.5864594 54.3192283 1.8474803 16.423836 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68631893 46.8938087 14.0974259 46.8938087 14.0974259 15.3026953 2.68631893 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68631893 12.5864594 14.0974259 12.5864594 14.0974259 1.8474803 2.68631893 1.8474803">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="70.8800297 0 72.7045671 0 72.7045671 51.1726051 0 51.1726051 0 49.6310387 70.8800297 49.6310387">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(231.076115, 193.333315)">
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6466383 46.8938087 68.0577452 46.8938087 68.0577452 15.3026953 56.6466383 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6466383 12.5864594 68.0577452 12.5864594 68.0577452 1.8474803 56.6466383 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.4178376 46.8938087 54.3132299 46.8938087 54.3132299 15.3026953 16.4178376 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.4178376 12.5864594 54.3132299 12.5864594 54.3132299 1.8474803 16.4178376 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68032045 46.8938087 14.0914274 46.8938087 14.0914274 15.3026953 2.68032045 15.3026953">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68032045 12.5864594 14.0914274 12.5864594 14.0914274 1.8474803 2.68032045 1.8474803">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="70.8790299 0 72.6975689 0 72.6975689 51.1726051 0 51.1726051 0 49.6310387 70.8790299 49.6310387">
                </polygon>
              </g>
              <polygon id="Path" fill="#404040"
                points="411.503371 252.126777 84.8071987 252.126777 84.8071987 262.402886 411.503371 262.402886">
              </polygon>
              <polygon id="Path" fill="#404040"
                points="411.503371 336.540031 84.8071987 336.540031 84.8071987 346.81714 411.503371 346.81714">
              </polygon>
              <polygon id="Path" fill="#543434"
                points="218.499303 411.580922 218.791229 403.953068 219.327094 403.953068 219.666008 411.437962">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="221.259604 418.312028 216.952696 418.312028 216.110909 411.580922 222.10139 411.580922">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="215.670021 410.534216 215.866971 412.877557 222.250352 412.97253 222.57627 410.439243">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="216.232878 412.884555 221.931433 412.966532 221.90444 413.197467 216.388839 413.856282">
              </polygon>
              <path
                d="M218.390331,393.269073 C218.31535,393.300064 218.233371,393.312061 218.152391,393.304063 C218.071412,393.296065 217.994431,393.268073 217.926449,393.222086 C217.858466,393.177099 217.80348,393.116116 217.76549,393.044136 C217.726499,392.972157 217.706504,392.892179 217.706504,392.810202 C217.706504,392.729225 217.726499,392.648248 217.76549,392.577268 C217.80348,392.505288 217.858466,392.443305 217.926449,392.398318 C217.994431,392.353331 218.071412,392.325339 218.152391,392.317341 C218.233371,392.309343 218.31535,392.32134 218.390331,392.352331 C218.496304,392.648248 218.496304,392.972157 218.390331,393.269073 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M216.884713,392.74622 C216.876715,392.856189 216.841724,392.964159 216.782739,393.058132 C216.724753,393.152106 216.643774,393.231084 216.547798,393.286068 C216.451823,393.342053 216.34385,393.373044 216.232878,393.377043 C216.121906,393.381042 216.011934,393.358048 215.911959,393.309062 C215.811985,393.261075 215.725007,393.189096 215.660023,393.099121 C215.59504,393.009146 215.552051,392.905175 215.536055,392.795206 C215.520059,392.685237 215.532056,392.573269 215.569046,392.468298 C215.607037,392.364328 215.669021,392.270354 215.752,392.195375 C216.180891,392.245361 216.57979,392.439306 216.884713,392.74622 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M224.353819,403.973062 C224.039899,403.977061 223.733976,403.87409 223.485039,403.681144 C223.584014,403.559178 223.706983,403.458207 223.845948,403.384228 C223.984913,403.310248 224.136874,403.266261 224.293834,403.253265 C224.450795,403.241268 224.608755,403.260263 224.757717,403.310248 C224.906679,403.360234 225.043644,403.440212 225.160615,403.545182 C224.947669,403.774118 224.662741,403.925076 224.353819,403.973062 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M224.122878,402.390507 C223.939924,402.783397 223.629003,403.102307 223.241101,403.294253 C223.149125,403.098308 223.097138,402.885368 223.08914,402.669429 C223.081142,402.45249 223.117133,402.236551 223.194113,402.034607 C223.272093,401.832664 223.389064,401.647716 223.539026,401.49176 C223.689987,401.335804 223.869942,401.211839 224.068891,401.127862 C224.227851,401.530749 224.246846,401.975624 224.122878,402.390507 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M226.633241,399.449335 C226.399301,398.477608 225.959412,397.566864 225.341569,396.780085 C224.724725,395.994306 223.945923,395.349488 223.058148,394.892616 C221.325587,394.046854 219.359085,393.806922 217.474563,394.212808 C214.618287,394.729662 212.223894,396.128269 211.511075,399.117428 C211.021199,401.237831 211.153166,403.454208 211.890979,405.501632 C212.264884,406.383384 212.844737,407.162165 213.58155,407.772993 C214.317364,408.383821 215.190142,408.809701 216.124905,409.013644 C218.424322,409.495509 221.280598,409.400535 223.282091,408.075908 C224.023903,407.620036 224.707729,407.075189 225.316575,406.452365 C226.096377,405.534623 226.589252,404.40794 226.734216,403.212276 C226.964157,401.964627 226.930166,400.682988 226.633241,399.449335 Z"
                id="Path" fill="#379F4D"></path>
              <polygon id="Path" fill="#543434"
                points="216.89771 404.509911 215.263124 402.689423 213.532563 402.59445 213.532563 402.431496 214.937207 402.329524 213.532563 400.598011 213.845483 400.251109 217.501556 403.464205">
              </polygon>
              <polygon id="Path" fill="#543434"
                points="219.909946 400.183128 221.701492 398.987464 221.999416 397.832789 222.087394 397.860781 222.067399 398.716541 222.467297 398.417625 222.57627 398.64856 220.44581 400.936916">
              </polygon>
              <polygon id="Path" fill="#543434"
                points="276.468606 411.580922 276.759533 403.953068 277.295397 403.953068 277.642309 411.437962">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="279.235905 418.312028 274.920999 418.312028 274.08721 411.580922 280.077691 411.580922">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="273.646322 410.534216 273.843272 412.877557 280.219655 412.97253 280.552571 410.439243">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="274.202181 412.884555 279.900736 412.966532 279.873743 413.197467 274.36514 413.856282">
              </polygon>
              <path
                d="M276.359634,393.269073 C276.214671,393.552993 275.974732,393.775931 275.681806,393.900895 C275.578832,393.603979 275.578832,393.28107 275.681806,392.983154 C275.826769,392.700233 276.066708,392.476296 276.359634,392.352331 C276.462608,392.649247 276.462608,392.972157 276.359634,393.269073 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M274.847018,392.74622 C274.976985,392.759217 275.100953,392.809202 275.203927,392.892179 C275.305901,392.974156 275.381882,393.084125 275.422872,393.20909 C275.463861,393.333055 275.46886,393.467017 275.434868,393.593982 C275.401877,393.720946 275.331895,393.835914 275.234919,393.923889 C275.137944,394.012864 275.017974,394.070848 274.888007,394.091842 C274.75904,394.112836 274.626074,394.095841 274.506104,394.043855 C274.386134,393.99087 274.28416,393.903895 274.212178,393.794925 C274.140197,393.684956 274.101207,393.555992 274.100207,393.425029 C273.97024,393.412033 273.846271,393.361047 273.744297,393.27907 C273.641323,393.197093 273.565342,393.087124 273.524353,392.962159 C273.483363,392.837195 273.479364,392.703232 273.512356,392.576268 C273.545348,392.449304 273.61533,392.335336 273.712305,392.246361 C273.809281,392.158386 273.92925,392.100402 274.059217,392.079408 C274.188185,392.057414 274.321151,392.074409 274.44112,392.127394 C274.56109,392.180379 274.663064,392.266355 274.735046,392.376324 C274.807028,392.486293 274.846018,392.614257 274.847018,392.74622 Z"
                id="Path" fill="#54CF6E"></path>
              <path
                d="M282.309126,403.973062 C281.995205,403.975061 281.690283,403.87209 281.441346,403.681144 C281.654292,403.44821 281.942219,403.297252 282.255139,403.253265 C282.56706,403.248266 282.871983,403.351237 283.116921,403.545182 C282.902975,403.774118 282.619047,403.925076 282.309126,403.973062 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M282.092181,402.390507 C281.911227,402.782397 281.602305,403.100308 281.217402,403.294253 C281.058443,402.893366 281.041447,402.449491 281.169415,402.037606 C281.119427,401.914641 281.106431,401.780679 281.131424,401.649716 C281.156418,401.519752 281.219402,401.399786 281.311379,401.304813 C281.404355,401.209839 281.522325,401.143858 281.651292,401.115866 C281.781259,401.087874 281.916225,401.097871 282.039194,401.144858 C282.163163,401.191844 282.270136,401.274821 282.348116,401.382791 C282.425096,401.489761 282.469085,401.617725 282.475084,401.750687 C282.481082,401.88265 282.447091,402.013613 282.379108,402.127581 C282.311125,402.241549 282.21215,402.333523 282.092181,402.390507 Z"
                id="Path" fill="#C9CFD1"></path>
              <path
                d="M284.609542,399.449335 C284.374602,398.476608 283.932714,397.565864 283.314871,396.780085 C282.697027,395.993307 281.916225,395.349488 281.027451,394.892616 C279.29489,394.045855 277.328388,393.806922 275.443866,394.212808 C272.594589,394.729662 270.200196,396.128269 269.487376,399.116428 C268.994501,401.236832 269.126468,403.455208 269.86728,405.501632 C270.240186,406.383384 270.820039,407.163165 271.556852,407.773993 C272.293665,408.385821 273.165444,408.810701 274.101207,409.013644 C276.393625,409.495509 279.249901,409.400535 281.258392,408.075908 C282.000204,407.621036 282.684031,407.075189 283.292876,406.452365 C284.077677,405.538622 284.571552,404.409939 284.711517,403.212276 C284.937459,401.964627 284.902468,400.682988 284.609542,399.449335 Z"
                id="Path" fill="#379F4D"></path>
              <polygon id="Path" fill="#543434"
                points="274.867012 404.509911 273.238425 402.689423 271.501866 402.59445 271.501866 402.431496 272.913508 402.329524 271.501866 400.598011 271.813787 400.251109 275.477858 403.464205">
              </polygon>
              <polygon id="Path" fill="#543434"
                points="277.879249 400.183128 279.676793 398.987464 279.975717 397.832789 280.056697 397.860781 280.036702 398.716541 280.4366 398.417625 280.552571 398.64856 278.415113 400.936916">
              </polygon>
              <g id="multifamilyWindow" transform="translate(303.508751, 276.035051)">
                <polygon id="Path" fill="#F0F0F0" points="54.2312507 0 0 0 0 60.4979819 54.2312507 60.4979819">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="51.3209885 3.28807506 28.6897262 3.28807506 28.6897262 57.4008531 51.3209885 57.4008531">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="25.3795655 3.28807506 2.74830322 3.28807506 2.74830322 57.4008531 25.3795655 57.4008531">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(103.781388, 361.101122)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.6408234 15.3026953 4.6408234 46.892809 16.0509306 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.6408234 1.8474803 4.6408234 12.5854597 16.0509306 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 15.3026953 18.3853387 15.3026953 18.3853387 46.892809 56.280731 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 1.8474803 18.3853387 1.8474803 18.3853387 12.5854597 56.280731 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 15.3026953 58.6071412 15.3026953 58.6071412 46.892809 70.0182481 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 1.8474803 58.6071412 1.8474803 58.6071412 12.5854597 70.0182481 12.5854597">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.81853894 5.68434189e-14 0 5.68434189e-14 0 51.1726051 72.6975689 51.1726051 72.6975689 49.630039 1.81853894 49.630039">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(103.781388, 277.196724)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.6408234 15.3026953 4.6408234 46.8938087 16.0509306 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.6408234 1.8474803 4.6408234 12.5864594 16.0509306 12.5864594">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 15.3026953 18.3853387 15.3026953 18.3853387 46.8938087 56.280731 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 1.8474803 18.3853387 1.8474803 18.3853387 12.5864594 56.280731 12.5864594">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 15.3026953 58.6071412 15.3026953 58.6071412 46.8938087 70.0182481 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 1.8474803 58.6071412 1.8474803 58.6071412 12.5864594 70.0182481 12.5864594">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.81853894 0 0 0 0 51.1726051 72.6975689 51.1726051 72.6975689 49.6310387 1.81853894 49.6310387">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(192.536886, 277.196724)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.63982366 15.3026953 4.63982366 46.8938087 16.0509306 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.63982366 1.8474803 4.63982366 12.5864594 16.0509306 12.5864594">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2797313 15.3026953 18.384339 15.3026953 18.384339 46.8938087 56.2797313 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2797313 1.8474803 18.384339 1.8474803 18.384339 12.5864594 56.2797313 12.5864594">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 15.3026953 58.6071412 15.3026953 58.6071412 46.8938087 70.0172484 46.8938087">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 1.8474803 58.6071412 1.8474803 58.6071412 12.5864594 70.0172484 12.5864594">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.8175392 0 0 0 0 51.1726051 72.6965691 51.1726051 72.6965691 49.6310387 1.8175392 49.6310387">
                </polygon>
              </g>
              <polygon id="Path" fill="#D4DADC"
                points="403.457411 88.9986646 273.639324 88.9986646 273.639324 168.270365 403.457411 168.270365">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="276.488601 88.9986646 273.639324 88.9986646 273.639324 168.270365 276.488601 168.270365">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="273.632325 95.5188305 92.8531588 100.755357 92.8531588 94.1332202 273.632325 94.1332202">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="403.451412 96.6125228 273.632325 103.771509 273.632325 94.1332202 403.451412 94.1332202">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="291.935685 155.921839 287.010934 155.921839 287.010934 168.270365 291.935685 168.270365">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="348.921237 155.921839 343.996486 155.921839 343.996486 168.270365 348.921237 168.270365">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="405.913788 155.921839 400.988037 155.921839 400.988037 168.270365 405.913788 168.270365">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="377.421012 155.921839 372.495261 155.921839 372.495261 168.270365 377.421012 168.270365">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="320.428461 155.921839 315.50371 155.921839 315.50371 168.270365 320.428461 168.270365">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="291.935685 155.921839 287.010934 155.921839 287.010934 158.387146 291.935685 158.387146">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="348.921237 155.921839 343.996486 155.921839 343.996486 158.387146 348.921237 158.387146">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="405.913788 155.921839 400.988037 155.921839 400.988037 158.387146 405.913788 158.387146">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="377.421012 155.921839 372.495261 155.921839 372.495261 158.387146 377.421012 158.387146">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="320.428461 155.921839 315.50371 155.921839 315.50371 158.387146 320.428461 158.387146">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="411.503371 153.687468 273.639324 153.687468 273.639324 155.921839 411.503371 155.921839">
              </polygon>
              <polygon id="Path" fill="#404040"
                points="411.503371 168.270365 84.8071987 168.270365 84.8071987 178.546475 411.503371 178.546475">
              </polygon>
              <g id="multifamilyWindow" transform="translate(303.508751, 107.765386)">
                <polygon id="Path" fill="#F0F0F0" points="54.2312507 0 0 0 0 60.4979819 54.2312507 60.4979819">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="51.3209885 3.29407337 28.6897262 3.29407337 28.6897262 57.4078511 51.3209885 57.4078511">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="25.3795655 3.29407337 2.74830322 3.29407337 2.74830322 57.4078511 25.3795655 57.4078511">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(103.781388, 108.927059)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.6408234 15.3026953 4.6408234 46.892809 16.0509306 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.6408234 1.8474803 4.6408234 12.5854597 16.0509306 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 15.3026953 18.3853387 15.3026953 18.3853387 46.892809 56.280731 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.280731 1.8474803 18.3853387 1.8474803 18.3853387 12.5854597 56.280731 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 15.3026953 58.6071412 15.3026953 58.6071412 46.892809 70.0182481 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0182481 1.8474803 58.6071412 1.8474803 58.6071412 12.5854597 70.0182481 12.5854597">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.81853894 0 0 0 0 51.1716054 72.6975689 51.1716054 72.6975689 49.630039 1.81853894 49.630039">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(192.536886, 108.927059)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.63982366 15.3026953 4.63982366 46.892809 16.0509306 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.63982366 1.8474803 4.63982366 12.5854597 16.0509306 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2797313 15.3026953 18.384339 15.3026953 18.384339 46.892809 56.2797313 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2797313 1.8474803 18.384339 1.8474803 18.384339 12.5854597 56.2797313 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 15.3026953 58.6071412 15.3026953 58.6071412 46.892809 70.0172484 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 1.8474803 58.6071412 1.8474803 58.6071412 12.5854597 70.0172484 12.5854597">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.8175392 0 0 0 0 51.1716054 72.6965691 51.1716054 72.6965691 49.630039 1.8175392 49.630039">
                </polygon>
              </g>
              <polygon id="Path" fill="#D4DADC"
                points="92.852159 84.4069563 222.671246 84.4069563 222.671246 5.13495553 92.852159 5.13495553">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="219.821968 84.4069563 222.671246 84.4069563 222.671246 5.13495553 219.821968 5.13495553">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="222.671246 11.6555213 403.451412 16.8990463 403.451412 10.2767092 222.671246 10.2767092">
              </polygon>
              <polygon id="Path" fill="#C9CFD1"
                points="92.8531588 12.7490137 222.671246 19.914798 222.671246 10.2767092 92.8531588 10.2767092">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="204.367886 84.4069563 209.293637 84.4069563 209.293637 72.0586298 204.367886 72.0586298">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="147.382334 84.4069563 152.308085 84.4069563 152.308085 72.0586298 147.382334 72.0586298">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="90.3967815 84.4069563 95.3225327 84.4069563 95.3225327 72.0586298 90.3967815 72.0586298">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="118.882559 84.4069563 123.808311 84.4069563 123.808311 72.0586298 118.882559 72.0586298">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="175.882108 84.4069563 180.80686 84.4069563 180.80686 72.0586298 175.882108 72.0586298">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="204.367886 74.5309344 209.293637 74.5309344 209.293637 72.065328 204.367886 72.065328">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="147.382334 74.5309344 152.308085 74.5309344 152.308085 72.065328 147.382334 72.065328">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="90.3967815 74.5309344 95.3225327 74.5309344 95.3225327 72.065328 90.3967815 72.065328">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="118.882559 74.5309344 123.808311 74.5309344 123.808311 72.065328 118.882559 72.065328">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="175.882108 74.5309344 180.80686 74.5309344 180.80686 72.065328 175.882108 72.065328">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="84.8071987 72.0585299 222.671246 72.0585299 222.671246 69.8239585 84.8071987 69.8239585">
              </polygon>
              <polygon id="Path" fill="#F0F0F0"
                points="138.569568 84.4069563 192.800819 84.4069563 192.800819 23.9086745 138.569568 23.9086745">
              </polygon>
              <g id="multifamilyWindow" transform="translate(141.480830, 27.196050)" fill="#3B4D5A">
                <polygon id="Path" points="0 54.1137777 22.6312623 54.1137777 22.6312623 0 0 0">
                </polygon>
                <polygon id="Path" points="25.9344248 54.1137777 48.5666868 54.1137777 48.5666868 0 25.9344248 0">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(319.824614, 25.063250)">
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6536365 46.9002069 68.0637437 46.9002069 68.0637437 15.3096934 56.6536365 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6536365 12.5859596 68.0637437 12.5859596 68.0637437 1.8474803 56.6536365 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.423836 46.9002069 54.3192283 46.9002069 54.3192283 15.3096934 16.423836 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.423836 12.5859596 54.3192283 12.5859596 54.3192283 1.8474803 16.423836 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68631893 46.9002069 14.0974259 46.9002069 14.0974259 15.3096934 2.68631893 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68631893 12.5859596 14.0974259 12.5859596 14.0974259 1.8474803 2.68631893 1.8474803">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="70.8800297 0 72.7045671 0 72.7045671 51.1725051 0 51.1725051 0 49.6374369 70.8800297 49.6374369">
                </polygon>
              </g>
              <g id="multifamilyWindow" transform="translate(231.076115, 25.063250)">
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6466383 46.9002069 68.0577452 46.9002069 68.0577452 15.3096934 56.6466383 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.6466383 12.5859596 68.0577452 12.5859596 68.0577452 1.8474803 56.6466383 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.4178376 46.9002069 54.3132299 46.9002069 54.3132299 15.3096934 16.4178376 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.4178376 12.5859596 54.3132299 12.5859596 54.3132299 1.8474803 16.4178376 1.8474803">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68032045 46.9002069 14.0914274 46.9002069 14.0914274 15.3096934 2.68032045 15.3096934">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="2.68032045 12.5859596 14.0914274 12.5859596 14.0914274 1.8474803 2.68032045 1.8474803">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="70.8790299 0 72.6975689 0 72.6975689 51.1725051 0 51.1725051 0 49.6374369 70.8790299 49.6374369">
                </polygon>
              </g>
              <polygon id="Path" fill="#404040"
                points="411.503371 83.8631092 84.8071987 83.8631092 84.8071987 94.1402183 411.503371 94.1402183">
              </polygon>
              <polygon id="Path" fill="#404040"
                points="411.503371 -1.42108547e-14 84.8071987 -1.42108547e-14 84.8071987 10.2766092 411.503371 10.2766092">
              </polygon>
              <g id="multifamilyWindow" transform="translate(317.579184, 361.101122)">
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 15.3026953 4.63982366 15.3026953 4.63982366 46.892809 16.0509306 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="16.0509306 1.8474803 4.63982366 1.8474803 4.63982366 12.5854597 16.0509306 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2737328 15.3026953 18.3783405 15.3026953 18.3783405 46.892809 56.2737328 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="56.2737328 1.8474803 18.3783405 1.8474803 18.3783405 12.5854597 56.2737328 12.5854597">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 15.3026953 58.6071412 15.3026953 58.6071412 46.892809 70.0172484 46.892809">
                </polygon>
                <polygon id="Path" fill="#3B4D5A"
                  points="70.0172484 1.8474803 58.6071412 1.8474803 58.6071412 12.5854597 70.0172484 12.5854597">
                </polygon>
                <polygon id="Path" fill="#D4DADC"
                  points="1.81853894 5.68434189e-14 0 5.68434189e-14 0 51.1726051 72.6975689 51.1726051 72.6975689 49.630039 1.81853894 49.630039">
                </polygon>
              </g>
              <polygon id="Path" fill="#3B4D5A"
                points="263.822813 361.101122 232.297805 361.101122 232.297805 418.312028 263.822813 418.312028">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="229.977393 361.101122 228.973648 361.101122 228.973648 418.312028 229.977393 418.312028">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="267.153968 361.101122 266.149223 361.101122 266.149223 418.312028 267.153968 418.312028">
              </polygon>
              <polygon id="Path" fill="#D4DADC"
                points="270.077227 356.781337 226.042391 356.781337 226.042391 361.101122 270.077227 361.101122">
              </polygon>
              <g id="treeTrunk">
                <polygon id="path" fill="#543434"
                  points="40.093635 441.751435 41.3962047 422.875745 30.9623501 410.779147 31.6000884 410.263293 37.7531284 415.53381 38.6553996 410.263293 39.6255537 410.514222 39.4558967 417.089372 41.9660603 419.520688 42.9158195 400.238113 37.8074146 393.906894 38.3772701 393.418031 43.6620303 397.649841 44.0080426 387.570676 45.3851934 387.570676 46.3009612 399.966189 47.6374224 412.293721 54.5228767 404.808827 55.1747115 405.236707 52.6643479 409.196593 57.0402385 409.13561 57.0402385 409.869403 52.2573511 410.596199 47.556043 417.972124 48.621073 441.751435">
                </polygon>
                <path
                  d="M58.6958187,411.328993 C58.1169655,411.037075 57.6530831,410.55821 57.3791526,409.970375 C57.6780768,409.840412 58.0009949,409.771431 58.327912,409.76942 C58.6538294,409.768432 58.9777473,409.832414 59.2776712,409.959378 C59.5785949,410.086342 59.850526,410.27329 60.0774684,410.509223 C60.3034112,410.744157 60.4793666,411.023079 60.5953372,411.328993 C59.980493,411.544932 59.3106629,411.544932 58.6958187,411.328993 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M59.7945402,408.21187 C59.0897189,408.761715 58.2179399,409.054633 57.3251662,409.040637 C57.3401624,408.591763 57.4451358,408.151887 57.6350877,407.746001 C57.8260393,407.340115 58.0959708,406.976217 58.4298862,406.678301 C58.7638015,406.379385 59.1557021,406.152449 59.5795947,406.009489 C60.004487,405.866529 60.4533731,405.811545 60.9002598,405.847535 C60.8112824,406.739284 60.4203815,407.573049 59.7945402,408.21187 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <g id=tree>
                <path
                  d="M84.6302435,373.476641 C84.8151966,371.117304 84.4402917,368.748971 83.5385203,366.561586 C82.5547697,364.429186 80.6282582,362.615696 78.3008482,362.28279 C77.6320178,362.155825 76.941193,362.204811 76.2973562,362.42475 C75.6525197,362.645687 75.0756659,363.029579 74.6237805,363.539436 C77.2491149,360.279353 79.6445076,356.69936 80.6752463,352.637503 C81.7069847,348.575645 81.1981137,343.929952 78.4977983,340.723854 C75.8994571,337.640721 71.5575579,336.316094 67.5685692,336.89993 C63.5735821,337.617728 59.9644971,339.740131 57.393149,342.884246 C57.6780768,336.574021 59.1297087,330.21681 57.9760012,324.008556 C56.8232935,317.800303 51.8706491,311.593049 45.5954401,311.87097 C41.328322,312.060917 37.7328335,315.124055 35.0124232,318.425127 C29.3436604,325.336183 25.9186288,333.814798 25.196012,342.72829 C23.5542283,340.472925 21.8921496,338.183569 19.7009052,336.466052 C17.5097607,334.747535 14.6943745,333.62685 11.9468711,334.074725 C7.42191831,334.815516 4.54554756,339.420221 3.4465262,343.868969 C2.27302372,348.653623 2.44198088,353.669213 3.93490238,358.363892 C6.36358663,366.099716 12.1368229,372.212996 17.733604,378.020363 C15.1624558,376.872685 11.9128797,378.42125 10.6714944,380.947539 C8.97542444,384.39157 10.5900151,388.351456 12.93732,391.081688 C15.0007968,393.319059 17.4832674,395.12855 20.2436676,396.40719 C35.41942,404.034045 53.8920367,402.934354 69.210153,396.291223 C74.8957116,393.825916 78.8637055,389.750063 81.8899383,384.296597 L81.8899383,384.235614 C83.634496,380.913549 84.573258,377.228585 84.6302435,373.476641 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M26.7969061,391.78849 C26.3231262,390.565834 26.1418722,389.249204 26.2677403,387.943571 C26.3367228,387.291755 26.5644651,386.665931 26.9311721,386.122084 C27.2978791,385.579236 27.7922538,385.133362 28.3708071,384.826448 C28.706422,384.650497 29.0819268,384.566521 29.4602309,384.580517 C29.838535,384.594513 30.2066417,384.707481 30.5281601,384.907425 C28.5496618,383.670773 26.8587904,382.024236 25.5690174,380.078784 C24.3682219,378.081345 23.9001406,375.528064 24.8296049,373.394664 C25.3119826,372.380949 26.040198,371.503196 26.9477679,370.842382 C27.8553378,370.181568 28.9135695,369.758687 30.0260874,369.611728 C32.2702185,369.350801 34.5336446,369.900647 36.4098689,371.160292 C35.2565613,367.852223 33.4724137,364.701109 33.09251,361.21609 C32.7126063,357.73207 34.3406935,353.64322 37.7395318,352.780463 C40.0392488,352.203625 42.4340416,353.256329 44.4081411,354.573958 C48.5290963,357.33918 51.7079904,361.299066 53.5191312,365.923765 C53.9620189,364.440183 54.6078552,363.024581 55.4386445,361.718948 C55.8575383,361.065132 56.4063992,360.50529 57.0502359,360.073411 C57.6950725,359.640533 58.4208884,359.345616 59.183695,359.205655 C61.7070553,358.872749 63.9734807,360.863189 65.2891471,363.057572 C66.6657981,365.416908 67.3696197,368.109151 67.3246311,370.841382 C67.2496501,375.331119 65.1531816,379.507944 63.104701,383.508819 C64.2854016,382.483107 66.259901,382.788021 67.3246311,383.936698 C68.776263,385.492261 68.5523198,387.855596 67.7385261,389.68908 C66.9987137,391.208653 65.9659755,392.566271 64.6992967,393.682957 C57.8470339,400.170132 47.8476691,402.513473 38.6418031,401.460769 C35.3435393,401.043886 32.287914,399.507318 29.9853977,397.105994 C28.5189695,395.610414 27.4264465,393.787927 26.7969061,391.78849 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M58.4378841,356.298473 C57.5301143,357.110245 56.375407,357.58911 55.160715,357.657091 C55.1377208,357.046263 55.2386952,356.436434 55.4586395,355.865595 C55.677584,355.294755 56.0104996,354.773902 56.4373913,354.336025 C56.8632833,353.897148 57.3741538,353.550246 57.9380109,353.316312 C58.5028677,353.081378 59.1087141,352.963411 59.7195592,352.970409 C59.6825686,354.194065 59.2296834,355.367735 58.4378841,356.298473 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M53.6890881,352.264608 C54.5498699,354.593952 53.4381517,357.148234 53.4381517,357.148234 C53.4381517,357.148234 50.9411848,355.925578 50.0796032,353.595233 C49.2180217,351.265889 50.3374379,348.711607 50.3374379,348.711607 C50.3374379,348.711607 52.861098,349.934263 53.6890881,352.264608 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M27.9842051,366.561586 C28.7513106,366.918486 29.3753524,367.523315 29.7548562,368.280103 C29.3719533,368.462051 28.9562587,368.564023 28.532866,368.580018 C28.1094734,368.597013 27.6871804,368.527033 27.2914807,368.375076 C26.8957811,368.223119 26.5349725,367.993183 26.2307497,367.697266 C25.9265268,367.402349 25.685288,367.048449 25.5216295,366.656559 C26.3079301,366.346646 27.1763099,366.312656 27.9842051,366.561586 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M4.37569062,379.317998 C6.85186283,379.175038 8.96842622,380.974532 8.96842622,380.974532 C8.96842622,380.974532 7.07570608,383.012958 4.59953387,383.161916 C2.12336166,383.311874 -2.84217094e-14,381.504383 -2.84217094e-14,381.504383 C-2.84217094e-14,381.504383 1.89951841,379.466956 4.37569062,379.317998 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M9.59276793,375.975938 C10.1103367,377.080627 10.2348052,378.329276 9.94557848,379.514942 C9.3550282,379.358986 8.80216837,379.085063 8.32049049,378.709169 C7.83881261,378.333275 7.43841413,377.863407 7.1434889,377.328557 C6.8484637,376.792708 6.66521016,376.202874 6.60462552,375.595045 C6.54404088,374.986216 6.60742481,374.372389 6.79097827,373.788553 C7.94638534,374.177444 8.93473476,374.949226 9.59276793,375.975938 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <g id="tree">
                <path
                  d="M35.8873014,405.494634 C35.174982,404.672865 33.0855118,404.346957 33.1195032,405.847535 C32.5021597,401.201842 26.4507939,402.968345 29.2729784,407.397099 C24.6191583,402.560459 20.494504,408.754717 26.5186767,411.512941 C21.4034735,411.614912 24.2595494,418.929855 28.7506108,415.744751 C26.9324718,416.837443 28.0721828,419.235769 30.0462823,418.922857 C35.2564614,418.053101 40.5819112,409.733442 35.8873014,405.494634 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M32.8685668,416.165632 C32.4182809,416.224616 31.9620966,416.224616 31.5118108,416.165632 C30.8765718,416.105649 30.2875211,415.806733 29.8632287,415.329867 C29.6829744,415.112928 29.558306,414.856001 29.5004207,414.580078 C29.4424354,414.304156 29.4529327,414.018236 29.530813,413.747313 C29.8022442,412.993525 30.7858948,413.176473 31.2403796,412.606633 C31.4522259,412.265729 31.5229079,411.856844 31.4371297,411.464955 C31.345053,411.078063 31.3266577,410.677176 31.3828435,410.283287 C31.4131358,410.082343 31.5181092,409.901394 31.6767689,409.77543 C31.8354287,409.649465 32.0357779,409.588482 32.2376267,409.604478 C32.5293528,409.672459 32.7125063,409.957379 32.9160547,410.161321 C32.9708408,410.228302 33.0389236,410.283287 33.1159041,410.323276 C33.1929845,410.363264 33.2773631,410.386258 33.3638412,410.392256 C34.0964555,410.392256 34.2661125,409.284568 35.0327181,409.42053 C35.7992238,409.556491 36.2944982,410.405253 36.5115432,411.105056 C36.7140918,411.715884 36.7579807,412.367701 36.6393108,412.999523 C36.5206409,413.632345 36.2432112,414.223179 35.8332151,414.719039 C35.0611109,415.555804 34.0029792,416.072658 32.8685668,416.165632 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg class="building-drawing" id="commercialSVG" viewBox="0 0 528 369" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <clipPath id="commercialClipPath">
            <rect class="commercialCenterRect" x="0" y="100%" width="100%" height="100%" fill="none"></rect>
          </clipPath>
        </defs>
        <g id="commercialClipReveal" clip-path="url(#commercialClipPath)">
          <g id="Figma-Imports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Commercial_Updated">
              <g id="bottomSquare" transform="translate(0.000000, 51.825000)">
                <polygon id="Path" fill="#D4DADC" fill-rule="nonzero"
                  points="493.8448 5.756 33.2212 5.756 33.2212 293.643 493.8448 293.643">
                </polygon>
                <polygon id="Path" fill="#404040" fill-rule="nonzero"
                  points="505.3638 293.643 21.709 293.643 21.709 316.782 505.3638 316.782">
                </polygon>
                <line x1="33.2212" y1="161.212" x2="493.8448" y2="161.212" id="Path" stroke="#C9CFD1" stroke-width="3">
                </line>
                <path
                  d="M113.8338,143.944 C113.8338,140.887 112.6198,137.955 110.4578,135.794 C108.2968,133.632 105.3648,132.418 102.3078,132.418 C99.2508,132.418 96.3198,133.632 94.1578,135.794 C91.9968,137.955 90.7818,140.887 90.7818,143.944 L113.8338,143.944 Z"
                  id="Path" fill="#000000" fill-rule="nonzero"></path>
                <path
                  d="M252.0068,143.944 C252.0068,140.887 250.7928,137.955 248.6308,135.794 C246.4698,133.632 243.5378,132.418 240.4818,132.418 C237.4248,132.418 234.4928,133.632 232.3318,135.794 C230.1698,137.955 228.9558,140.887 228.9558,143.944 L252.0068,143.944 Z"
                  id="Path" fill="#000000" fill-rule="nonzero"></path>
                <path
                  d="M298.1098,143.944 C298.1098,140.887 296.8958,137.955 294.7338,135.794 C292.5728,133.632 289.6408,132.418 286.5848,132.418 C283.5278,132.418 280.5958,133.632 278.4348,135.794 C276.2728,137.955 275.0588,140.887 275.0588,143.944 L298.1098,143.944 Z"
                  id="Path" fill="#000000" fill-rule="nonzero"></path>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero"
                  points="317.7648 190.006 209.3008 190.006 209.3008 293.643 317.7648 293.643">
                </polygon>
                <polygon id="Path" fill="#404040" fill-rule="nonzero"
                  points="505.3638 0 21.709 0 21.709 17.275 505.3638 17.275"></polygon>
                <g id="pillar" transform="translate(165.651800, 115.149000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="28.788 0 5.756 0 5.756 178.494 28.788 178.494"></polygon>
                  <polygon id="Path" fill="#F0F0F0"
                    points="34.543 -5.68434189e-14 0 -5.68434189e-14 0 17.276 34.543 17.276">
                  </polygon>
                  <polygon id="Path" fill="#C9CFD1" points="28.788 23.323 5.756 25.811 5.756 17.275 28.788 17.275">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC"
                    points="34.543 -5.68434189e-14 0 -5.68434189e-14 0 5.18 34.543 5.18">
                  </polygon>
                </g>
                <g id="pillar" transform="translate(326.869800, 115.149000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="28.788 0 5.757 0 5.757 178.494 28.788 178.494"></polygon>
                  <polygon id="Path" fill="#F0F0F0"
                    points="34.544 -5.68434189e-14 0 -5.68434189e-14 0 17.276 34.544 17.276">
                  </polygon>
                  <polygon id="Path" fill="#C9CFD1" points="28.788 23.323 5.757 25.811 5.757 17.275 28.788 17.275">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC"
                    points="34.544 -5.68434189e-14 0 -5.68434189e-14 0 5.18 34.544 5.18">
                  </polygon>
                </g>
                <g id="commercialWindow" transform="translate(217.470800, 201.518000)" fill="#3B4D5A"
                  fill-rule="nonzero" stroke="#D4DADC" stroke-width="3">
                  <polygon id="Path" points="46.062 40.306 23.031 40.306 23.031 92.125 46.062 92.125">
                  </polygon>
                  <polygon id="Path" points="23.031 40.306 0 40.306 0 92.125 23.031 92.125">
                  </polygon>
                  <polygon id="Path" points="69.093 40.306 46.062 40.306 46.062 92.125 69.093 92.125">
                  </polygon>
                  <polygon id="Path" points="92.124 40.306 69.093 40.306 69.093 92.125 92.124 92.125">
                  </polygon>
                  <polygon id="Path" points="92.125 0 0 0 0 28.787 92.125 28.787"></polygon>
                </g>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="493.8448 117.922 33.2212 126.872 33.2212 115.156 493.8448 115.156">
                </polygon>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="33.2212 20.72 171.4078 27.635 171.4078 17.269 33.2212 17.269"></polygon>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="355.6578 20.72 493.8448 27.635 493.8448 17.269 355.6578 17.269">
                </polygon>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero"
                  points="367.1768 97.881 159.8958 97.881 159.8958 115.156 367.1768 115.156">
                </polygon>
                <polygon id="Path" fill="#404040" fill-rule="nonzero"
                  points="159.8958 97.881 21.709 97.881 21.709 115.156 159.8958 115.156">
                </polygon>
                <polygon id="Path" fill="#404040" fill-rule="nonzero"
                  points="505.3568 97.881 367.1698 97.881 367.1698 115.156 505.3568 115.156">
                </polygon>
                <g id="commercialWindow" transform="translate(62.008300, 189.999000)">
                  <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero"
                    points="79.5885 1.024 1.0171 1.024 1.0171 79.602 79.5885 79.602"></polygon>
                  <path
                    d="M78.5785,2.034 L78.5785,78.579 L2.0339,78.579 L2.0339,2.034 L78.5785,2.034 Z M80.6125,0 L0,0 L0,80.613 L80.6125,80.613 L80.6125,0 Z"
                    id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                  <line x1="40.3065" y1="80.613" x2="40.3065" y2="0.007" id="Path" stroke="#F0F0F0" stroke-width="3">
                  </line>
                </g>
                <g id="canopy" transform="translate(50.496100, 172.731000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="103.6367 28.787 0 28.787 11.5122 0 92.1247 0">
                  </polygon>
                  <polygon id="Path" fill="#C9CFD1" points="103.6367 28.787 0 28.787 0 32.374 103.6367 32.374">
                  </polygon>
                </g>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="142.6138 270.612 62.0083 270.612 62.0083 282.124 142.6138 282.124">
                </polygon>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="142.6138 282.131 62.0083 282.131 62.0083 293.643 142.6138 293.643">
                </polygon>
                <g id="commercialWindow" transform="translate(384.444800, 189.999000)">
                  <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero"
                    points="79.589 1.024 1.017 1.024 1.017 79.602 79.589 79.602"></polygon>
                  <path
                    d="M78.579,2.034 L78.579,78.579 L2.034,78.579 L2.034,2.034 L78.579,2.034 Z M80.613,0 L2.27373675e-13,0 L2.27373675e-13,80.613 L80.613,80.613 L80.613,0 Z"
                    id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                  <line x1="40.306" y1="80.613" x2="40.306" y2="0.007" id="Path" stroke="#F0F0F0" stroke-width="3">
                  </line>
                </g>
                <g id="canopy" transform="translate(372.932800, 172.731000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="103.637 28.787 -2.27373675e-13 28.787 11.512 0 92.125 0">
                  </polygon>
                  <polygon id="Path" fill="#C9CFD1"
                    points="103.637 28.787 -2.27373675e-13 28.787 -2.27373675e-13 32.374 103.637 32.374">
                  </polygon>
                </g>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="465.0508 270.612 384.4448 270.612 384.4448 282.124 465.0508 282.124">
                </polygon>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="465.0508 282.131 384.4448 282.131 384.4448 293.643 465.0508 293.643">
                </polygon>
                <path
                  d="M436.2638,143.944 C436.2638,140.887 435.0488,137.955 432.8878,135.794 C430.7258,133.632 427.7948,132.418 424.7378,132.418 C421.6808,132.418 418.7488,133.632 416.5878,135.794 C414.4258,137.955 413.2118,140.887 413.2118,143.944 L436.2638,143.944 Z"
                  id="Path" fill="#000000" fill-rule="nonzero"></path>
                <g id="commercialWindow" transform="translate(379.366800, 32.510000)">
                  <path
                    d="M94.159,2.034 L94.159,50.401 L2.034,50.401 L2.034,2.034 L94.159,2.034 Z M96.193,5.68434189e-14 L0,5.68434189e-14 L0,52.435 L96.193,52.435 L96.193,5.68434189e-14 Z"
                    id="Shape" fill="#C9CFD1" fill-rule="nonzero"></path>
                  <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero"
                    points="95.176 1.017 3.024 1.017 3.024 49.113 95.176 49.113"></polygon>
                  <line x1="49.1" y1="48.876" x2="49.1" y2="1.254" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
                  <line x1="2.081" y1="16.143" x2="95.474" y2="16.143" id="Path" stroke="#F0F0F0" stroke-width="3">
                  </line>
                  <path
                    d="M94.159,2.034 L94.159,48.096 L4.041,48.096 L4.041,2.034 L94.159,2.034 Z M96.192,0 L2.007,0 L2.007,50.13 L96.192,50.13 L96.192,0 Z"
                    id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                </g>
                <g id="commercialWindow" transform="translate(51.366800, 32.510000)">
                  <path
                    d="M94.159,2.034 L94.159,50.401 L2.034,50.401 L2.034,2.034 L94.159,2.034 Z M96.193,5.68434189e-14 L0,5.68434189e-14 L0,52.435 L96.193,52.435 L96.193,5.68434189e-14 Z"
                    id="Shape" fill="#C9CFD1" fill-rule="nonzero"></path>
                  <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero"
                    points="95.176 1.017 3.024 1.017 3.024 49.113 95.176 49.113"></polygon>
                  <line x1="49.1" y1="48.876" x2="49.1" y2="1.254" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
                  <line x1="2.081" y1="16.143" x2="95.474" y2="16.143" id="Path" stroke="#F0F0F0" stroke-width="3">
                  </line>
                  <path
                    d="M94.159,2.034 L94.159,48.096 L4.041,48.096 L4.041,2.034 L94.159,2.034 Z M96.192,0 L2.007,0 L2.007,50.13 L96.192,50.13 L96.192,0 Z"
                    id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                </g>
                <polygon id="Path" fill="#404040" fill-rule="nonzero"
                  points="326.8638 172.731 200.1958 172.731 200.1958 190.006 326.8638 190.006">
                </polygon>
                <g id="lampPost" transform="translate(2.813500, 236.658000)" fill="#404040" fill-rule="nonzero">
                  <polygon id="Path" points="7.1188 80.124 0 80.124 1.3492 61.609 5.7696 61.609">
                  </polygon>
                  <polygon id="Path" points="4.7253 -5.68434189e-14 2.393 -5.68434189e-14 2.393 80.124 4.7253 80.124">
                  </polygon>
                  <path
                    d="M5.8713,62.293 L1.261,62.293 C1.0811,62.293 0.9087,62.222 0.7816,62.095 C0.6544,61.968 0.583,61.795 0.583,61.615 C0.583,61.436 0.6544,61.263 0.7816,61.136 C0.9087,61.009 1.0811,60.938 1.261,60.938 L5.8713,60.938 C6.0511,60.938 6.2235,61.009 6.3507,61.136 C6.4778,61.263 6.5493,61.436 6.5493,61.615 C6.5493,61.795 6.4778,61.968 6.3507,62.095 C6.2235,62.222 6.0511,62.293 5.8713,62.293 Z"
                    id="Path"></path>
                </g>
                <g id="lampHead" transform="translate(0.000000, 217.946000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#404040" points="10.0477 18.712 2.6984 18.712 0 5.39 12.7461 5.39">
                  </polygon>
                  <polygon id="Path" fill="#FFFFFF" points="8.685 16.244 4.0611 16.244 2.3594 7.858 10.3867 7.858">
                  </polygon>
                  <polygon id="Path" fill="#404040" points="10.0477 2 2.6984 2 0 5.39 12.7461 5.39">
                  </polygon>
                  <path
                    d="M10.5969,18.712 L2.156,18.712 C1.9762,18.712 1.8037,18.641 1.6766,18.514 C1.5494,18.386 1.478,18.214 1.478,18.034 C1.478,17.854 1.5494,17.682 1.6766,17.555 C1.8037,17.428 1.9762,17.356 2.156,17.356 L10.5969,17.356 C10.7767,17.356 10.9492,17.428 11.0763,17.555 C11.2035,17.682 11.2749,17.854 11.2749,18.034 C11.2749,18.214 11.2035,18.386 11.0763,18.514 C10.9492,18.641 10.7767,18.712 10.5969,18.712 Z"
                    id="Path" fill="#404040"></path>
                  <path
                    d="M6.3794,4 C7.484,4 8.3795,3.104 8.3795,2 C8.3795,0.895 7.484,-5.68434189e-14 6.3794,-5.68434189e-14 C5.2748,-5.68434189e-14 4.3794,0.895 4.3794,2 C4.3794,3.104 5.2748,4 6.3794,4 Z"
                    id="Path" fill="#404040"></path>
                </g>
                <g id="lampPost" transform="translate(517.132800, 236.658000)" fill="#404040" fill-rule="nonzero">
                  <polygon id="Path" points="7.119 80.124 -2.27373675e-13 80.124 1.35 61.609 5.77 61.609">
                  </polygon>
                  <polygon id="Path" points="4.726 0 2.394 0 2.394 80.124 4.726 80.124"></polygon>
                  <path
                    d="M5.872,62.293 L1.261,62.293 C1.082,62.293 0.909,62.222 0.782,62.095 C0.655,61.968 0.584,61.795 0.584,61.615 C0.584,61.436 0.655,61.263 0.782,61.136 C0.909,61.009 1.082,60.938 1.261,60.938 L5.872,60.938 C6.052,60.938 6.224,61.009 6.351,61.136 C6.478,61.263 6.55,61.436 6.55,61.615 C6.55,61.795 6.478,61.968 6.351,62.095 C6.224,62.222 6.052,62.293 5.872,62.293 Z"
                    id="Path"></path>
                </g>
                <g id="lampHead" transform="translate(514.319800, 217.946000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#404040" points="10.048 18.712 2.698 18.712 0 5.39 12.746 5.39"></polygon>
                  <polygon id="Path" fill="#FFFFFF" points="8.685 16.244 4.061 16.244 2.359 7.858 10.387 7.858">
                  </polygon>
                  <polygon id="Path" fill="#404040" points="10.048 2 2.698 2 0 5.39 12.746 5.39">
                  </polygon>
                  <path
                    d="M10.604,18.712 L2.163,18.712 C1.983,18.712 1.811,18.641 1.683,18.514 C1.556,18.386 1.485,18.214 1.485,18.034 C1.485,17.854 1.556,17.682 1.683,17.555 C1.811,17.428 1.983,17.356 2.163,17.356 L10.604,17.356 C10.784,17.356 10.956,17.428 11.083,17.555 C11.21,17.682 11.282,17.854 11.282,18.034 C11.282,18.214 11.21,18.386 11.083,18.514 C10.956,18.641 10.784,18.712 10.604,18.712 Z"
                    id="Path" fill="#404040"></path>
                  <path
                    d="M6.38,4 C7.485,4 8.38,3.104 8.38,2 C8.38,0.895 7.485,0 6.38,0 C5.275,0 4.38,0.895 4.38,2 C4.38,3.104 5.275,4 6.38,4 Z"
                    id="Path" fill="#404040"></path>
                </g>
                <g id="fencePost" transform="translate(42.726600, 259.005000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="4.9357 0 0 0 0 34.638 4.9357 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="62.0562 0 57.1202 0 57.1202 34.638 62.0562 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="119.1762 0 114.2402 0 114.2402 34.638 119.1762 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="90.6192 0 85.6832 0 85.6832 34.638 90.6192 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="33.4923 0 28.5566 0 28.5566 34.638 33.4923 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="327.3722 0 322.4362 0 322.4362 34.638 327.3722 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="384.4922 0 379.5562 0 379.5562 34.638 384.4922 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="441.6132 0 436.6772 0 436.6772 34.638 441.6132 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="413.0492 0 408.1142 0 408.1142 34.638 413.0492 34.638">
                  </polygon>
                  <polygon id="Path" fill="#F0F0F0" points="355.9292 0 350.9932 0 350.9932 34.638 355.9292 34.638">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="62.0562 0 57.1202 0 57.1202 3.457 62.0562 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="33.4923 0 28.5566 0 28.5566 3.457 33.4923 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="4.9357 0 0 0 0 3.457 4.9357 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="119.1762 0 114.2402 0 114.2402 3.457 119.1762 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="90.6192 0 85.6832 0 85.6832 3.457 90.6192 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="327.3722 0 322.4362 0 322.4362 3.457 327.3722 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="384.4922 0 379.5562 0 379.5562 3.457 384.4922 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="441.6132 0 436.6772 0 436.6772 3.457 441.6132 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="413.0492 0 408.1142 0 408.1142 3.457 413.0492 3.457">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="355.9292 0 350.9932 0 350.9932 3.457 355.9292 3.457">
                  </polygon>
                </g>
                <g id="fenceRail" transform="translate(21.709000, 256.218000)" fill="#F0F0F0" fill-rule="nonzero">
                  <polygon id="Path" points="149.6988 0 0 0 0 2.787 149.6988 2.787"></polygon>
                  <polygon id="Path" points="483.6478 0 333.9488 0 333.9488 2.787 483.6478 2.787">
                  </polygon>
                </g>
                <polygon id="Path" fill="#C9CFD1" fill-rule="nonzero"
                  points="171.4148 17.269 167.0418 17.269 167.0418 80.606 171.4148 80.606">
                </polygon>
                <polygon id="Path" fill="#D4DADC" fill-rule="nonzero"
                  points="159.8888 100.159 367.1698 104.722 367.1698 97.881 159.8888 97.881">
                </polygon>
                <polygon id="Path" fill="#D4DADC" fill-rule="nonzero"
                  points="200.1958 191.497 326.8698 194.501 326.8698 190.006 200.1958 190.006">
                </polygon>
                <g id="stair" transform="translate(174.757800, 309.067000)" fill="#F0F0F0" fill-rule="nonzero">
                  <polygon id="Path" points="177.544 0 -1.13686838e-13 0 -1.13686838e-13 7.715 177.544 7.715">
                  </polygon>
                </g>
                <g id="stair" transform="translate(187.937800, 301.358000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="151.197 0 0 0 0 7.716 151.197 7.716">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="151.197 6.163 0 6.163 0 7.709 151.197 7.709">
                  </polygon>
                </g>
                <g id="stair" transform="translate(204.174800, 293.643000)" fill-rule="nonzero">
                  <polygon id="Path" fill="#F0F0F0" points="118.723 0 0 0 0 7.715 118.723 7.715">
                  </polygon>
                  <polygon id="Path" fill="#D4DADC" points="118.723 6.163 0 6.163 0 7.708 118.723 7.708">
                  </polygon>
                </g>
              </g>
              <g id="topSquare" transform="translate(159.895800, 0.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="195.762 17.275 11.512 17.275 11.512 149.706 195.762 149.706">
                </polygon>
                <polygon id="Path" fill="#404040"
                  points="207.281 2.84217094e-14 0 2.84217094e-14 0 17.275 207.281 17.275">
                </polygon>
                <polygon id="Path" fill="#D4DADC" points="11.512 20.726 195.762 27.642 195.762 17.275 11.512 17.275">
                </polygon>
                <polygon id="Path" fill="#404040" points="207.281 132.431 0 132.431 0 149.706 207.281 149.706">
                </polygon>
                <g id="commercialWindow" transform="translate(59.262812, 31.486000)">
                  <path
                    d="M42.3401876,4.068 C50.3121876,4.068 58.1051876,6.432 64.7331876,10.86 C71.3611876,15.289 76.5281876,21.584 79.5781876,28.949 C82.6291876,36.314 83.4271876,44.419 81.8721876,52.237 C80.3171876,60.056 76.4781876,67.238 70.8411876,72.875 C65.2041876,78.512 58.0221876,82.35 50.2031876,83.906 C42.3851876,85.461 34.2811876,84.663 26.9161876,81.612 C19.5511876,78.561 13.2561876,73.395 8.82718756,66.767 C4.39818756,60.139 2.03418756,52.346 2.03418756,44.374 C2.03418756,33.684 6.28018756,23.432 13.8391876,15.873 C21.3981876,8.314 31.6501876,4.068 42.3401876,4.068 Z M42.3401876,2.034 C33.9661876,2.034 25.7801876,4.517 18.8171876,9.169 C11.8541876,13.822 6.42718756,20.434 3.22318756,28.171 C0.0181875605,35.908 -0.81981244,44.421 0.81318756,52.634 C2.44718756,60.847 6.48018756,68.392 12.4011876,74.313 C18.3221876,80.234 25.8671876,84.267 34.0801876,85.901 C42.2931876,87.534 50.8061876,86.696 58.5431876,83.491 C66.2801876,80.287 72.8921876,74.86 77.5451876,67.897 C82.1971876,60.934 84.6801876,52.748 84.6801876,44.374 C84.6681876,33.148 80.2031876,22.386 72.2651876,14.449 C64.3281876,6.511 53.5661876,2.046 42.3401876,2.034 Z"
                    id="Shape" fill="#D4DADC"></path>
                  <path
                    d="M44.3741876,83.663 C67.1961876,83.663 85.6971876,65.162 85.6971876,42.34 C85.6971876,19.518 67.1961876,1.017 44.3741876,1.017 C21.5521876,1.017 3.05118756,19.518 3.05118756,42.34 C3.05118756,65.162 21.5521876,83.663 44.3741876,83.663 Z"
                    id="Path" fill="#404040"></path>
                  <path
                    d="M44.3741876,2.034 C52.3461876,2.034 60.1391876,4.398 66.7671876,8.826 C73.3961876,13.255 78.5621876,19.55 81.6121876,26.915 C84.6631876,34.28 85.4611876,42.385 83.9061876,50.203 C82.3511876,58.022 78.5121876,65.204 72.8751876,70.841 C67.2381876,76.478 60.0561876,80.316 52.2381876,81.872 C44.4191876,83.427 36.3151876,82.629 28.9501876,79.578 C21.5851876,76.527 15.2901876,71.361 10.8611876,64.733 C6.43218756,58.105 4.06818756,50.312 4.06818756,42.34 C4.06818756,31.65 8.31518756,21.398 15.8731876,13.839 C23.4321876,6.28 33.6841876,2.034 44.3741876,2.034 Z M44.3741876,0 C36.0001876,0 27.8141876,2.483 20.8511876,7.135 C13.8891876,11.788 8.46218756,18.4 5.25718756,26.137 C2.05218756,33.874 1.21418756,42.387 2.84818756,50.6 C4.48118756,58.813 8.51418756,66.358 14.4351876,72.279 C20.3571876,78.2 27.9011876,82.233 36.1141876,83.867 C44.3271876,85.5 52.8411876,84.662 60.5771876,81.457 C68.3141876,78.253 74.9261876,72.826 79.5791876,65.863 C84.2311876,58.9 86.7151876,50.714 86.7151876,42.34 C86.7001876,31.115 82.2351876,20.354 74.2971876,12.417 C66.3601876,4.479 55.5991876,0.014 44.3741876,0 Z"
                    id="Shape" fill="#F0F0F0"></path>
                  <path
                    d="M44.3741876,66.388 C57.6561876,66.388 68.4221876,55.621 68.4221876,42.34 C68.4221876,29.059 57.6561876,18.292 44.3741876,18.292 C31.0931876,18.292 20.3261876,29.059 20.3261876,42.34 C20.3261876,55.621 31.0931876,66.388 44.3741876,66.388 Z"
                    id="Path" fill="#C9CFD1"></path>
                  <path
                    d="M44.3741876,19.309 C48.9331876,19.309 53.3901876,20.661 57.1811876,23.194 C60.9721876,25.727 63.9261876,29.327 65.6711876,33.539 C67.4161876,37.751 67.8721876,42.386 66.9831876,46.857 C66.0931876,51.329 63.8981876,55.436 60.6741876,58.66 C57.4501876,61.884 53.3431876,64.079 48.8711876,64.969 C44.4001876,65.858 39.7651876,65.402 35.5531876,63.657 C31.3401876,61.912 27.7401876,58.958 25.2071876,55.167 C22.6741876,51.376 21.3221876,46.919 21.3221876,42.36 C21.3221876,36.247 23.7511876,30.383 28.0741876,26.06 C32.3971876,21.737 38.2601876,19.309 44.3741876,19.309 Z M44.3741876,17.275 C39.4131876,17.275 34.5631876,18.746 30.4371876,21.502 C26.3121876,24.259 23.0971876,28.177 21.1981876,32.76 C19.2991876,37.344 18.8031876,42.388 19.7701876,47.254 C20.7381876,52.12 23.1281876,56.59 26.6361876,60.098 C30.1441876,63.607 34.6141876,65.996 39.4801876,66.964 C44.3461876,67.932 49.3901876,67.435 53.9741876,65.536 C58.5581876,63.638 62.4751876,60.422 65.2321876,56.297 C67.9881876,52.172 69.4591876,47.322 69.4591876,42.36 C69.4591876,35.707 66.8171876,29.327 62.1121876,24.622 C57.4081876,19.918 51.0271876,17.275 44.3741876,17.275 Z"
                    id="Shape" fill="#F0F0F0"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg class="building-drawing" id="highriseSVG" viewBox="0 0 785 765" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Highrise</title>
        <defs>
          <clipPath id="highRiseClipPath">
            <rect class="highRiseCenterRect" x="0" y="100%" width="100%" height="100%" fill="none"></rect>
          </clipPath>
        </defs>
        <g id="highRiseClipReveal" clip-path="url(#highRiseClipPath)">
          <g id="Figma-Imports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Highrise">
              <g id="tree" transform="translate(716.080100, 679.635312)" fill-rule="nonzero">
                <path
                  d="M0,50.364588 L59.3,50.364588 C58.93,48.931588 58.251,47.596588 57.309,46.454588 C56.367,45.312588 55.187,44.390588 53.85,43.754588 C51.195,42.678588 48.233,42.639588 45.55,43.644588 C46.05,43.474588 46.72,42.444588 47.07,42.014588 C47.966,41.007588 48.556,39.765588 48.77,38.434588 C48.982,36.855588 48.605,35.253588 47.71,33.934588 C46.999,32.966588 46.038,32.211588 44.929,31.749588 C43.821,31.288588 42.608,31.137588 41.42,31.314588 L41.08,31.314588 C40.632,31.394588 40.195,31.528588 39.78,31.714588 C37.923,32.626588 36.283,33.925588 34.97,35.524588 C34.71,35.814588 34.45,36.114588 34.21,36.434588 C34.21,36.434588 38.07,32.774588 38.97,29.984588 C42.32,22.064588 35.24,10.564588 26.43,13.824588 C22.9,15.524588 20.01,19.014588 18.1,23.134588 C25.1,11.064588 13.47,3.72458797 9.32,14.594588 C11.88,5.59458797 8.05,-3.73541203 0.32,1.52458797 L0,1.68458797 L0,50.364588 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M0,50.364588 L34.93,50.364588 C34.714,49.521588 34.316,48.735588 33.763,48.063588 C33.21,47.391588 32.516,46.848588 31.73,46.474588 C30.163,45.837588 28.414,45.812588 26.83,46.404588 C27.172,45.486588 27.172,44.476588 26.83,43.558588 C26.489,42.640588 25.829,41.875588 24.97,41.404588 C23.41,40.694588 21.66,41.284588 20.15,42.174588 C21.529,41.299588 22.531,39.940588 22.96,38.364588 C24.96,33.704588 20.76,26.924588 15.57,28.844588 C13.36,30.066588 11.629,32.001588 10.66,34.334588 C14.81,27.224588 7.93,22.894588 5.49,29.334588 C7.86,22.334588 3.28,18.234588 0,19.814588 L0,50.364588 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M36.22,12.114588 C35.918,12.407588 35.559,12.633588 35.164,12.777588 C34.77,12.922588 34.349,12.982588 33.93,12.954588 C34.028,11.979588 34.41,11.054588 35.03,10.294588 C35.335,10.004588 35.697,9.77958797 36.093,9.63558797 C36.488,9.49058797 36.909,9.42958797 37.33,9.45458797 C37.224,10.429588 36.838,11.353588 36.22,12.114588 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M9.27,23.334588 C8.806,24.220588 8.018,24.894588 7.07,25.214588 C6.861,24.093588 6.972,22.935588 7.39,21.874588 C7.857,20.986588 8.649,20.313588 9.6,19.994588 C9.803,21.116588 9.689,22.273588 9.27,23.334588 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M33.38,8.29458797 C33.58,9.67458797 33.393,11.083588 32.84,12.364588 C31.838,11.510588 31.137,10.356588 30.84,9.07458797 C30.645,7.69058797 30.832,6.27958797 31.38,4.99458797 C32.383,5.85158797 33.085,7.00858797 33.38,8.29458797 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <g id="tree" transform="translate(8.780100, 679.685312)" fill-rule="nonzero">
                <path
                  d="M59.3,50.314588 L0,50.314588 C0.3697,48.881588 1.0493,47.546588 1.991,46.404588 C2.9327,45.262588 4.1134,44.340588 5.45,43.704588 C8.1051,42.629588 11.0673,42.589588 13.75,43.594588 C13.25,43.424588 12.58,42.394588 12.23,41.964588 C11.334,40.957588 10.7443,39.715588 10.5299,38.384588 C10.318,36.805588 10.6953,35.203588 11.59,33.884588 C12.3002,32.916588 13.2615,32.160588 14.3701,31.698588 C15.4786,31.236588 16.6923,31.086588 17.88,31.264588 L18.2199,31.264588 C18.668,31.344588 19.1046,31.478588 19.52,31.664588 C21.3707,32.594588 23.0006,33.911588 24.3,35.524588 C24.56,35.814588 24.8199,36.114588 25.0599,36.434588 C25.0599,36.434588 21.2,32.774588 20.3,29.984588 C16.95,22.064588 24.0299,10.564588 32.84,13.824588 C36.37,15.524588 39.26,19.014588 41.17,23.134588 C34.12,11.064588 45.8,3.72458797 49.95,14.594588 C47.39,5.59458797 51.22,-3.73541203 58.95,1.52458797 L59.27,1.68458797 L59.3,50.314588 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M59.3001,50.314588 L24.3701,50.314588 C24.5859,49.471588 24.9843,48.685588 25.5373,48.013588 C26.0903,47.341588 26.7842,46.798588 27.5701,46.424588 C29.1368,45.787588 30.8858,45.762588 32.4701,46.354588 C32.1283,45.436588 32.1282,44.426588 32.4697,43.508588 C32.8112,42.590588 33.4715,41.825588 34.3301,41.354588 C35.8901,40.644588 37.6401,41.234588 39.1501,42.124588 C37.7601,41.261588 36.7434,39.909588 36.3001,38.334588 C34.3001,33.674588 38.5001,26.894588 43.6901,28.814588 C45.9004,30.036588 47.6312,31.971588 48.6001,34.304588 C44.4501,27.194588 51.3301,22.864588 53.7701,29.304588 C51.4001,22.304588 55.9801,18.204588 59.2601,19.784588 L59.3001,50.314588 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M23.0799,12.064588 C23.3815,12.357588 23.7412,12.583588 24.1357,12.727588 C24.5302,12.872588 24.9507,12.932588 25.37,12.904588 C25.2806,11.932588 24.9086,11.007588 24.3,10.244588 C23.9947,9.95458797 23.633,9.72958797 23.2373,9.58558797 C22.8416,9.44058797 22.4204,9.37958797 22,9.40458797 C22.0973,10.376588 22.4722,11.299588 23.0799,12.064588 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M50.03,23.284588 C50.4943,24.170588 51.2822,24.844588 52.23,25.164588 C52.4387,24.043588 52.3278,22.885588 51.91,21.824588 C51.4425,20.936588 50.651,20.263588 49.7,19.944588 C49.4971,21.066588 49.6114,22.223588 50.03,23.284588 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M25.9201,8.24458797 C25.7202,9.62458797 25.9071,11.033588 26.4601,12.314588 C27.4617,11.460588 28.1628,10.306588 28.4601,9.02458797 C28.6552,7.64058797 28.4684,6.22958797 27.9201,4.94458797 C26.9167,5.80158797 26.2155,6.95858797 25.9201,8.24458797 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <polygon id="Path" fill="#D4DADC" fill-rule="nonzero"
                points="722.0801 199.9999 552.0801 199.9999 552.0801 729.9999 722.0801 729.9999"></polygon>
              <g id="highriseWindow" transform="translate(649.080100, 226.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(579.080100, 226.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(649.080100, 326.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(579.080100, 326.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(649.080100, 426.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(579.080100, 426.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(649.080100, 526.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(579.080100, 526.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(649.080100, 626.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(579.080100, 626.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="552.0801 199.9999 736.0801 199.9999 736.0801 179.9999 552.0801 179.9999"></polygon>
              <polygon id="Path" fill="#D4DADC" fill-rule="nonzero"
                points="232.0801 199.9999 62.0801 199.9999 62.0801 729.9999 232.0801 729.9999"></polygon>
              <g id="highriseWindow" transform="translate(89.080100, 226.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(159.080100, 226.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(89.080100, 326.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(159.080100, 326.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(89.080100, 426.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(159.080100, 426.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(89.080100, 526.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(159.080100, 526.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(89.080100, 626.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <g id="highriseWindow" transform="translate(159.080100, 626.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#3B4D5A" points="44.5 1.5 1.5 1.5 1.5 74.5 44.5 74.5"></polygon>
                <path d="M43,3 L43,73 L3,73 L3,3 L43,3 Z M46,0 L0,0 L0,76 L46,76 L46,0 Z" id="Shape" fill="#F0F0F0">
                </path>
              </g>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="232.0801 179.9999 48.0801 179.9999 48.0801 199.9999 232.0801 199.9999"></polygon>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="784.1601 729.9999 0 729.9999 0 764.1299 784.1601 764.1299"></polygon>
              <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero"
                points="552.0801 0 232.0801 0 232.0801 729.9999 552.0801 729.9999"></polygon>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="566.0901 2.13162821e-14 218.0801 2.13162821e-14 218.0801 20 566.0901 20"></polygon>
              <g id="highriseWindow" transform="translate(245.960100, 37.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.4999 44.7 77.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,75.9999 L3,75.9999 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,78.9999 L46.2,78.9999 L46.2,0 Z"
                  id="Shape" fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.4999 44.7 74.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,72.9999 L5.81,72.9999 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,75.9999 L46.2,75.9999 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(306.720100, 37.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.4999 44.7 77.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,75.9999 L3,75.9999 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,78.9999 L46.2,78.9999 L46.2,0 Z"
                  id="Shape" fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.4999 44.7 74.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,72.9999 L5.81,72.9999 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,75.9999 L46.2,75.9999 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(367.480100, 37.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.4999 44.7 77.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,75.9999 L3,75.9999 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,78.9999 L46.2,78.9999 L46.2,0 Z"
                  id="Shape" fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.4999 44.7 74.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,72.9999 L5.81,72.9999 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,75.9999 L46.2,75.9999 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(428.240100, 37.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.4999 44.7 77.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,75.9999 L3,75.9999 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,78.9999 L46.2,78.9999 L46.2,0 Z"
                  id="Shape" fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.4999 44.69 74.4999">
                </polygon>
                <path
                  d="M43.2,3 L43.2,72.9999 L5.84,72.9999 L5.84,3 L43.24,3 L43.2,3 Z M46.24,0 L2.84,0 L2.84,75.9999 L46.24,75.9999 L46.24,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(489.000100, 37.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.4999 44.7 77.4999">
                </polygon>
                <path
                  d="M43.19,3 L43.19,75.9999 L3,75.9999 L3,3 L43.19,3 Z M46.19,0 L0,0 L0,78.9999 L46.19,78.9999 L46.19,0 Z"
                  id="Shape" fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.4999 44.69 74.4999">
                </polygon>
                <path
                  d="M43.19,3 L43.19,72.9999 L5.8,72.9999 L5.8,3 L43.19,3 Z M46.19,0 L2.8,0 L2.8,75.9999 L46.19,75.9999 L46.19,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(245.960100, 146.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(306.720100, 146.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(367.480100, 146.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(428.240100, 146.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path
                  d="M43.2,3 L43.2,73 L5.84,73 L5.84,3 L43.24,3 L43.2,3 Z M46.24,0 L2.84,0 L2.84,76 L46.24,76 L46.24,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(489.000100, 146.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.19,3 L43.19,76 L3,76 L3,3 L43.19,3 Z M46.19,0 L0,0 L0,79 L46.19,79 L46.19,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path d="M43.19,3 L43.19,73 L5.8,73 L5.8,3 L43.19,3 Z M46.19,0 L2.8,0 L2.8,76 L46.19,76 L46.19,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(245.960100, 256.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(306.720100, 256.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(367.480100, 256.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(428.240100, 256.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path
                  d="M43.2,3 L43.2,73 L5.84,73 L5.84,3 L43.24,3 L43.2,3 Z M46.24,0 L2.84,0 L2.84,76 L46.24,76 L46.24,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(489.000100, 256.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.19,3 L43.19,76 L3,76 L3,3 L43.19,3 Z M46.19,0 L0,0 L0,79 L46.19,79 L46.19,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path d="M43.19,3 L43.19,73 L5.8,73 L5.8,3 L43.19,3 Z M46.19,0 L2.8,0 L2.8,76 L46.19,76 L46.19,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(245.960100, 366.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(306.720100, 366.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(367.480100, 366.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(428.240100, 366.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path
                  d="M43.2,3 L43.2,73 L5.84,73 L5.84,3 L43.24,3 L43.2,3 Z M46.24,0 L2.84,0 L2.84,76 L46.24,76 L46.24,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(489.000100, 366.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.19,3 L43.19,76 L3,76 L3,3 L43.19,3 Z M46.19,0 L0,0 L0,79 L46.19,79 L46.19,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path d="M43.19,3 L43.19,73 L5.8,73 L5.8,3 L43.19,3 Z M46.19,0 L2.8,0 L2.8,76 L46.19,76 L46.19,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(245.960100, 476.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(306.720100, 476.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(367.480100, 476.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.7 1.5 4.31 1.5 4.31 74.5 44.7 74.5"></polygon>
                <path d="M43.2,3 L43.2,73 L5.81,73 L5.81,3 L43.2,3 Z M46.2,0 L2.81,0 L2.81,76 L46.2,76 L46.2,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(428.240100, 476.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.2,3 L43.2,76 L3,76 L3,3 L43.2,3 Z M46.2,0 L0,0 L0,79 L46.2,79 L46.2,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path
                  d="M43.2,3 L43.2,73 L5.84,73 L5.84,3 L43.24,3 L43.2,3 Z M46.24,0 L2.84,0 L2.84,76 L46.24,76 L46.24,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(489.000100, 476.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#A0A0A0" points="44.7 1.5 1.5 1.5 1.5 77.5 44.7 77.5"></polygon>
                <path d="M43.19,3 L43.19,76 L3,76 L3,3 L43.19,3 Z M46.19,0 L0,0 L0,79 L46.19,79 L46.19,0 Z" id="Shape"
                  fill="#DCDCDC"></path>
                <polygon id="Path" fill="#3B4D5A" points="44.69 1.5 4.3 1.5 4.3 74.5 44.69 74.5"></polygon>
                <path d="M43.19,3 L43.19,73 L5.8,73 L5.8,3 L43.19,3 Z M46.19,0 L2.8,0 L2.8,76 L46.19,76 L46.19,0 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="highriseWindow" transform="translate(340.080100, 607.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#DCDCDC" points="53 31 1 31 1 123 53 123"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="103 31 51 31 51 123 103 123"></polygon>
                <path d="M52,32 L52,122 L2,122 L2,32 L52,32 Z M54,30 L0,30 L0,124 L54,124 L54,30 Z" id="Shape"
                  fill="#F0F0F0"></path>
                <path d="M102,32 L102,122 L52,122 L52,32 L102,32 Z M104,30 L50,30 L50,124 L104,124 L104,30 Z" id="Shape"
                  fill="#F0F0F0"></path>
                <polygon id="Path" fill="#3B4D5A" points="41 43 13 43 13 111 41 111"></polygon>
                <path d="M40,44 L40,110 L14,110 L14,44 L40,44 Z M42,42 L12,42 L12,112 L42,112 L42,42 Z" id="Shape"
                  fill="#F0F0F0"></path>
                <polygon id="Path" fill="#3B4D5A" points="91 43 63 43 63 111 91 111"></polygon>
                <path d="M90,44 L90,110 L64,110 L64,44 L90,44 Z M92,42 L62,42 L62,112 L92,112 L92,42 Z" id="Shape"
                  fill="#F0F0F0"></path>
                <polygon id="Path" fill="#DCDCDC" points="103 1 1 1 1 33 103 33"></polygon>
                <path d="M102,2 L102,32 L2,32 L2,2 L102,2 Z M104,0 L0,0 L0,34 L104,34 L104,0 Z" id="Shape"
                  fill="#F0F0F0">
                </path>
                <polygon id="Path" fill="#C8C8C8" points="2 4.4 102 10.73 102 2 2 2"></polygon>
              </g>
              <g id="highriseWindow" transform="translate(260.080100, 617.999900)">
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="51 1 1 1 1 81 51 81">
                </polygon>
                <path d="M50,2 L50,80 L2,80 L2,2 L50,2 Z M52,0 L0,0 L0,82 L52,82 L52,0 Z" id="Shape" fill="#DCDCDC"
                  fill-rule="nonzero"></path>
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="51 3 3 3 3 81 51 81">
                </polygon>
                <path d="M50,4 L50,80 L4,80 L4,4 L50,4 Z M52,2 L2,2 L2,82 L52,82 L52,2 Z" id="Shape" fill="#F0F0F0"
                  fill-rule="nonzero"></path>
                <line x1="27" y1="2" x2="27" y2="82" id="Path" stroke="#F0F0F0" stroke-width="2"></line>
                <line x1="2" y1="42" x2="52" y2="42" id="Path" stroke="#F0F0F0" stroke-width="2"></line>
              </g>
              <g id="highriseWindow" transform="translate(470.080100, 617.999900)">
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="51 1 1 1 1 81 51 81">
                </polygon>
                <path d="M50,2 L50,80 L2,80 L2,2 L50,2 Z M52,0 L0,0 L0,82 L52,82 L52,0 Z" id="Shape" fill="#DCDCDC"
                  fill-rule="nonzero"></path>
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="51 3 3 3 3 81 51 81">
                </polygon>
                <path d="M50,4 L50,80 L4,80 L4,4 L50,4 Z M52,2 L2,2 L2,82 L52,82 L52,2 Z" id="Shape" fill="#F0F0F0"
                  fill-rule="nonzero"></path>
                <line x1="27" y1="2" x2="27" y2="82" id="Path" stroke="#F0F0F0" stroke-width="2"></line>
                <line x1="2" y1="42" x2="52" y2="42" id="Path" stroke="#F0F0F0" stroke-width="2"></line>
              </g>
              <polygon id="highriseWindowSill" fill="#DCDCDC" fill-rule="nonzero"
                points="322.0801 700.9999 252.0801 700.9999 252.0801 710.9999 322.0801 710.9999"></polygon>
              <polygon id="highriseWindowSill" fill="#DCDCDC" fill-rule="nonzero"
                points="532.0801 700.9999 462.0801 700.9999 462.0801 710.9999 532.0801 710.9999"></polygon>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="232.0801 593.1699 552.0801 601.5199 552.0801 589.9999 232.0801 589.9999"></polygon>
              <g id="stair" transform="translate(261.140100, 752.749900)" fill="#F0F0F0" fill-rule="nonzero">
                <polygon id="Path" points="261.87 0 0 0 0 11.38 261.87 11.38"></polygon>
              </g>
              <g id="stair" transform="translate(280.580100, 741.379900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="223.01 0 0 0 0 11.38 223.01 11.38"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="223.01 9.09 0 9.09 0 11.37 223.01 11.37">
                </polygon>
              </g>
              <g id="stair" transform="translate(304.530100, 729.999900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="175.11 0 0 0 0 11.38 175.11 11.38"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="175.11 9.09 0 9.09 0 11.37 175.11 11.37">
                </polygon>
              </g>
              <g id="fencePost" transform="translate(78.940100, 685.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="5.61 0 0 0 0 44.92 5.61 44.92"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="5.61 2.27373675e-13 0 2.27373675e-13 0 4.49 5.61 4.49">
                </polygon>
              </g>
              <g id="fencePost" transform="translate(143.810100, 685.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="5.61 0 0 0 0 44.92 5.61 44.92"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="5.61 2.27373675e-13 0 2.27373675e-13 0 4.49 5.61 4.49">
                </polygon>
              </g>
              <g id="fencePost" transform="translate(208.680100, 685.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="5.61 0 0 0 0 44.92 5.61 44.92"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="5.61 2.27373675e-13 0 2.27373675e-13 0 4.49 5.61 4.49">
                </polygon>
              </g>
              <g id="fencePost" transform="translate(176.240100, 685.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="5.61 0 0 0 0 44.92 5.61 44.92"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="5.61 2.27373675e-13 0 2.27373675e-13 0 4.49 5.61 4.49">
                </polygon>
              </g>
              <g id="fencePost" transform="translate(111.380100, 685.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="5.61 0 0 0 0 44.92 5.61 44.92"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="5.61 2.27373675e-13 0 2.27373675e-13 0 4.49 5.61 4.49">
                </polygon>
              </g>
              <g id="fenceRail" transform="translate(55.070100, 681.459900)" fill="#F0F0F0" fill-rule="nonzero">
                <polygon id="Path" points="177.01 0 0 0 0 3.62 177.01 3.62"></polygon>
              </g>
              <g id="fencePost" transform="translate(699.610100, 685.069900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="0 44.93 5.61 44.93 5.61 0.01 0 0.01"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="0 4.49 5.61 4.49 5.61 0 0 0"></polygon>
              </g>
              <g id="fencePost" transform="translate(634.740100, 685.069900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="0 44.93 5.61 44.93 5.61 0.01 0 0.01"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="0 4.49 5.61 4.49 5.61 0 0 0"></polygon>
              </g>
              <g id="fencePost" transform="translate(569.870100, 685.069900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="0 44.93 5.61 44.93 5.61 0.01 0 0.01"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="0 4.49 5.61 4.49 5.61 0 0 0"></polygon>
              </g>
              <g id="fencePost" transform="translate(602.310100, 685.069900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="0 44.93 5.61 44.93 5.61 0.01 0 0.01"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="0 4.49 5.61 4.49 5.61 0 0 0"></polygon>
              </g>
              <g id="fencePost" transform="translate(667.170100, 685.069900)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="0 44.93 5.61 44.93 5.61 0.01 0 0.01"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="0 4.49 5.61 4.49 5.61 0 0 0"></polygon>
              </g>
              <g id="fenceRail" transform="translate(552.080100, 681.459900)" fill="#F0F0F0" fill-rule="nonzero">
                <polygon id="Path" points="0 3.62 177.01 3.62 177.01 0 0 0"></polygon>
              </g>
              <g id="treeTrunk" transform="translate(112.880100, 670.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#543434"
                  points="13.46 79.77 15.38 51.98 0 34.17 0.95 33.41 10.01 41.17 11.34 33.41 12.77 33.79 12.52 43.46 16.22 47.05 17.62 18.65 10.09 9.33 10.93 8.61 18.73 14.85 19.23 0 21.26 0 22.61 18.26 24.58 36.4 34.74 25.39 35.69 26.02 31.99 31.85 38.44 31.76 38.44 32.83 31.39 33.91 24.46 44.76 26.04 79.77">
                </polygon>
                <path
                  d="M40.89,34.98 C40.031,34.555 39.343,33.849 38.94,32.98 C39.847,32.665 40.833,32.665 41.74,32.98 C42.595,33.406 43.28,34.112 43.68,34.98 C42.777,35.298 41.793,35.298 40.89,34.98 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M42.48,30.37 C41.437,31.18 40.151,31.614 38.83,31.6 C38.853,30.939 39.011,30.29 39.292,29.692 C39.572,29.094 39.972,28.558 40.465,28.118 C40.958,27.678 41.535,27.343 42.162,27.132 C42.788,26.92 43.451,26.838 44.11,26.89 C43.974,28.2 43.4,29.427 42.48,30.37 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <g id="tree" transform="translate(67.250000, 558.596602)" fill-rule="nonzero">
                <path
                  d="M124.7501,90.7332976 C125.0221,87.2562976 124.4781,83.7632976 123.1601,80.5332976 C121.7001,77.3832976 118.8601,74.7132976 115.4301,74.2232976 C114.4431,74.0382976 113.4251,74.1122976 112.4751,74.4382976 C111.5251,74.7642976 110.6761,75.3312976 110.0101,76.0832976 C113.8801,71.2732976 117.4101,66.0032976 118.9301,60.0232976 C120.4501,54.0432976 119.7001,47.2132976 115.7201,42.4932976 C111.8901,37.9532976 105.4901,35.9932976 99.6201,36.8532976 C93.7111,37.9022976 88.3701,41.0262976 84.5601,45.6632976 C84.9801,36.3832976 87.1201,27.0232976 85.4201,17.8832976 C83.7201,8.74329759 76.4201,-0.396702414 67.1701,0.0132975864 C60.8801,0.293297586 55.5901,4.79329759 51.5701,9.65329759 C43.2121,19.8282976 38.1571,32.3102976 37.0801,45.4332976 C34.6701,42.1132976 32.2101,38.7532976 28.9801,36.2132976 C25.7501,33.6732976 21.6001,32.0332976 17.5501,32.6932976 C10.8901,33.7832976 6.6401,40.5732976 5.0201,47.1232976 C3.2907,54.1672976 3.5399,61.5522976 5.7401,68.4632976 C9.3701,79.8032976 17.8801,88.8132976 26.1401,97.4032976 C22.3501,95.7032976 17.5601,97.9932976 15.7301,101.713298 C13.2301,106.773298 15.6101,112.603298 19.0701,116.633298 C22.1031,119.948298 25.7591,122.632298 29.8301,124.533298 C52.2001,135.773298 79.4301,134.143298 102.0101,124.373298 C110.3901,120.743298 116.2401,114.743298 120.7001,106.713298 L120.7501,106.623298 C123.3101,101.713298 124.6801,96.2702976 124.7501,90.7332976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M39.4901,117.703298 C38.7921,115.900298 38.5241,113.958298 38.7101,112.033298 C38.8151,111.074298 39.1521,110.154298 39.6921,109.355298 C40.2331,108.555298 40.9591,107.899298 41.8101,107.443298 C42.3071,107.189298 42.8601,107.068298 43.4171,107.091298 C43.9741,107.113298 44.5161,107.280298 44.9901,107.573298 C42.0761,105.745298 39.5841,103.318298 37.6801,100.453298 C35.9101,97.5232976 35.2301,93.7632976 36.5901,90.6232976 C37.3011,89.1302976 38.3751,87.8382976 39.7121,86.8652976 C41.0501,85.8922976 42.6101,85.2692976 44.2501,85.0532976 C47.5601,84.6642976 50.9001,85.4692976 53.6701,87.3232976 C51.9601,82.4532976 49.3301,77.8232976 48.7701,72.6932976 C48.2101,67.5632976 50.6201,61.5332976 55.6201,60.2732976 C59.0101,59.4132976 62.5501,60.9632976 65.4601,62.9032976 C71.5321,66.9802976 76.2141,72.8132976 78.8801,79.6232976 C79.5321,77.4362976 80.4851,75.3492976 81.7101,73.4232976 C82.3321,72.4642976 83.1411,71.6422976 84.0901,71.0062976 C85.0391,70.3702976 86.1071,69.9342976 87.2301,69.7232976 C90.9501,69.2432976 94.2901,72.1732976 96.2301,75.3932976 C98.2661,78.8682976 99.3041,82.8372976 99.2301,86.8632976 C99.1101,93.4732976 96.0201,99.6232976 93.0001,105.503298 C94.7501,104.003298 97.6501,104.453298 99.2301,106.143298 C101.3601,108.433298 101.0301,111.913298 99.8301,114.603298 C98.7421,116.844298 97.2191,118.846298 95.3501,120.493298 C85.2501,130.043298 70.5101,133.493298 56.9401,131.933298 C52.0811,131.319298 47.5781,129.061298 44.1801,125.533298 C42.0241,123.327298 40.4181,120.645298 39.4901,117.703298 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M14.1401,94.4232976 C14.8981,96.0512976 15.0811,97.8872976 14.6601,99.6332976 C13.7901,99.4022976 12.9761,98.9982976 12.2661,98.4452976 C11.5571,97.8912976 10.9671,97.2002976 10.5311,96.4122976 C10.0961,95.6252976 9.8241,94.7572976 9.7331,93.8622976 C9.6421,92.9672976 9.7331,92.0622976 10.0001,91.2032976 C11.7051,91.7762976 13.1651,92.9112976 14.1401,94.4232976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M6.4401,99.3432976 C10.0901,99.1232976 13.2201,101.783298 13.2201,101.783298 C13.2201,101.783298 10.4201,104.783298 6.7701,105.003298 C3.12,105.223298 0,102.533298 0,102.533298 C0,102.533298 2.83,99.5332976 6.4401,99.3432976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M86.1201,65.4432976 C84.7811,66.6412976 83.0741,67.3472976 81.2801,67.4432976 C81.2471,66.5442976 81.3981,65.6472976 81.7221,64.8082976 C82.0461,63.9682976 82.5371,63.2032976 83.1651,62.5592976 C83.7931,61.9152976 84.5461,61.4052976 85.3771,61.0602976 C86.2081,60.7162976 87.1001,60.5432976 88.0001,60.5532976 C87.9461,62.3492976 87.2831,64.0732976 86.1201,65.4432976 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M79.1801,59.5332976 C80.4401,62.9632976 78.8001,66.7232976 78.8001,66.7232976 C78.8001,66.7232976 75.1201,64.9232976 73.8601,61.4832976 C72.6001,58.0432976 74.2301,54.2932976 74.2301,54.2932976 C74.2301,54.2932976 77.9101,56.0732976 79.1801,59.5332976 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M41.2401,80.5332976 C42.3731,81.0532976 43.2951,81.9462976 43.8501,83.0632976 C43.2861,83.3322976 42.6741,83.4842976 42.0501,83.5102976 C41.4261,83.5352976 40.8031,83.4332976 40.2201,83.2112976 C39.6361,82.9882976 39.1041,82.6492976 38.6551,82.2152976 C38.2071,81.7802976 37.8511,81.2592976 37.6101,80.6832976 C38.7691,80.2262976 40.0481,80.1732976 41.2401,80.5332976 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="tree" transform="translate(101.899921, 692.941744)" fill-rule="nonzero">
                <path
                  d="M18.240179,3.50815614 C17.190179,2.29815614 14.110179,1.81815614 14.160179,4.02815614 C13.250179,-2.80184386 4.33017904,-0.211843855 8.50017904,6.31815614 C1.64017904,-0.811843855 -4.44982096,8.31815614 4.44017904,12.3781561 C-3.10982096,12.5281561 1.10017904,23.2981561 7.72017904,18.5981561 C5.04017904,20.2081561 6.72017904,23.7381561 9.63017904,23.2781561 C17.320179,22.0181561 25.180179,9.77815614 18.240179,3.50815614 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M13.790179,19.2481561 C13.126179,19.3301561 12.454179,19.3301561 11.790179,19.2481561 C10.858179,19.1541561 9.99517904,18.7151561 9.37017904,18.0181561 C9.10317904,17.7001561 8.91817904,17.3211561 8.83117904,16.9151561 C8.74417904,16.5091561 8.75717904,16.0881561 8.87017904,15.6881561 C9.28017904,14.5781561 10.720179,14.8381561 11.390179,14.0081561 C11.547179,13.7591561 11.653179,13.4821561 11.703179,13.1921561 C11.753179,12.9021561 11.745179,12.6051561 11.680179,12.3181561 C11.544179,11.7481561 11.517179,11.1581561 11.600179,10.5781561 C11.645179,10.2831561 11.799179,10.0161561 12.033179,9.83015614 C12.267179,9.64415614 12.562179,9.55415614 12.860179,9.57815614 C13.290179,9.66815614 13.560179,10.0881561 13.860179,10.3881561 C14.026179,10.5861561 14.263179,10.7111561 14.520179,10.7381561 C15.600179,10.7381561 15.860179,9.09815614 16.990179,9.29815614 C18.120179,9.49815614 18.840179,10.7481561 19.160179,11.7881561 C19.458179,12.6851561 19.523179,13.6431561 19.348179,14.5731561 C19.173179,15.5021561 18.764179,16.3711561 18.160179,17.0981561 C17.026179,18.3391561 15.465179,19.1071561 13.790179,19.2481561 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="treeTrunk" transform="translate(628.000000, 670.079900)" fill-rule="nonzero">
                <polygon id="Path" fill="#543434"
                  points="31.3701 79.77 29.4501 51.98 44.8301 34.17 43.8901 33.41 34.8201 41.17 33.4901 33.41 32.0601 33.79 32.3101 43.46 28.6201 47.05 27.2201 18.65 34.7401 9.33 33.9101 8.61 26.1101 14.85 25.6001 0 23.5701 0 22.2201 18.26 20.2501 36.4 10.1001 25.39 9.1401 26.02 12.8501 31.85 6.4001 31.76 6.4001 32.83 13.4401 33.91 20.3701 44.76 18.8001 79.77">
                </polygon>
                <path
                  d="M2.06,35.0252637 C1.201,34.6002637 0.513,33.8942637 0.11,33.0252637 C1.017,32.7102637 2.003,32.7102637 2.91,33.0252637 C3.765,33.4512637 4.45,34.1572637 4.85,35.0252637 C3.947,35.3432637 2.963,35.3432637 2.06,35.0252637 Z"
                  id="Path" fill="#379F4D"
                  transform="translate(2.480000, 34.026389) scale(-1, 1) translate(-2.480000, -34.026389) ">
                </path>
                <path
                  d="M3.65,30.4152637 C2.607,31.2252637 1.321,31.6592637 0,31.6452637 C0.023,30.9842637 0.181,30.3352637 0.462,29.7372637 C0.742,29.1392637 1.142,28.6032637 1.635,28.1632637 C2.128,27.7232637 2.705,27.3882637 3.332,27.1772637 C3.958,26.9652637 4.621,26.8832637 5.28,26.9352637 C5.144,28.2452637 4.57,29.4722637 3.65,30.4152637 Z"
                  id="Path" fill="#379F4D"
                  transform="translate(2.640000, 29.282846) scale(-1, 1) translate(-2.640000, -29.282846) ">
                </path>
              </g>
              <g id="tree" transform="translate(593.647329, 558.596602)" fill-rule="nonzero">
                <path
                  d="M0.0727705534,90.7332976 C-0.210229447,87.2552976 0.334770553,83.7602976 1.66277055,80.5332976 C3.11277055,77.3832976 5.95277055,74.7132976 9.38277055,74.2232976 C10.3697706,74.0372976 11.3887706,74.1112976 12.3387706,74.4372976 C13.2887706,74.7632976 14.1377706,75.3302976 14.8027706,76.0832976 C10.9427706,71.2732976 7.41277055,66.0032976 5.88277055,60.0232976 C4.35277055,54.0432976 5.12277055,47.2132976 9.09277055,42.4932976 C12.9227706,37.9532976 19.3227706,35.9932976 25.2027706,36.8532976 C31.1097706,37.9002976 36.4477706,41.0252976 40.2527706,45.6632976 C39.8427706,36.3832976 37.7027706,27.0232976 39.4027706,17.8832976 C41.1027706,8.74329759 48.3527706,-0.396702414 57.6427706,0.0132975864 C63.9327706,0.293297586 69.2327706,4.79329759 73.2427706,9.65329759 C81.6087706,19.8232976 86.6647706,32.3082976 87.7327706,45.4332976 C90.1527706,42.1132976 92.6127706,38.7532976 95.8327706,36.2132976 C99.0527706,33.6732976 103.222771,32.0332976 107.272771,32.6932976 C113.932771,33.7832976 118.172771,40.5732976 119.792771,47.1232976 C121.520771,54.1672976 121.274771,61.5502976 119.082771,68.4632976 C115.432771,79.8332976 106.932771,88.8432976 98.6727706,97.4332976 C102.472771,95.7332976 107.252771,98.0232976 109.092771,101.743298 C111.582771,106.803298 109.202771,112.633298 105.742771,116.663298 C102.707771,119.972298 99.0467706,122.647298 94.9727706,124.533298 C72.6027706,135.773298 45.3727706,134.143298 22.8027706,124.373298 C14.4127706,120.743298 8.56277055,114.743298 4.11277055,106.713298 L4.11277055,106.623298 C1.53477055,101.718298 0.150770553,96.2742976 0.0727705534,90.7332976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M85.3227706,117.703298 C86.0257706,115.901298 86.2937706,113.958298 86.1027706,112.033298 C86.0007706,111.073298 85.6637706,110.152298 85.1237706,109.352298 C84.5837706,108.552298 83.8547706,107.897298 83.0027706,107.443298 C82.5067706,107.189298 81.9527706,107.068298 81.3957706,107.091298 C80.8387706,107.113298 80.2967706,107.280298 79.8227706,107.573298 C82.7417706,105.751298 85.2347706,103.323298 87.1327706,100.453298 C88.9027706,97.5232976 89.5927706,93.7632976 88.2227706,90.6232976 C87.5117706,89.1302976 86.4387706,87.8382976 85.1007706,86.8652976 C83.7627706,85.8922976 82.2027706,85.2692976 80.5627706,85.0532976 C77.2557706,84.6612976 73.9177706,85.4672976 71.1527706,87.3232976 C72.8527706,82.4532976 75.4927706,77.8232976 76.0427706,72.6932976 C76.5927706,67.5632976 74.2027706,61.5332976 69.1927706,60.2732976 C65.8027706,59.4132976 62.2727706,60.9632976 59.3627706,62.9032976 C53.2887706,66.9792976 48.6027706,72.8122976 45.9327706,79.6232976 C45.2807706,77.4362976 44.3277706,75.3492976 43.1027706,73.4232976 C42.4827706,72.4642976 41.6727706,71.6412976 40.7237706,71.0052976 C39.7747706,70.3692976 38.7057706,69.9322976 37.5827706,69.7232976 C33.8627706,69.2432976 30.5827706,72.1732976 28.5827706,75.3932976 C26.5477706,78.8682976 25.5097706,82.8372976 25.5827706,86.8632976 C25.7027706,93.4732976 28.7927706,99.6232976 31.8127706,105.503298 C30.0627706,104.003298 27.1527706,104.453298 25.5827706,106.143298 C23.4427706,108.433298 23.7827706,111.913298 24.9827706,114.603298 C26.0567706,116.856298 27.5697706,118.872298 29.4327706,120.533298 C39.5327706,130.083298 54.2727706,133.533298 67.8427706,131.973298 C72.7197706,131.360298 77.2357706,129.085298 80.6327706,125.533298 C82.7927706,123.330298 84.3997706,120.647298 85.3227706,117.703298 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M110.682771,94.4232976 C109.924771,96.0512976 109.741771,97.8872976 110.162771,99.6332976 C111.032771,99.4042976 111.847771,99.0002976 112.557771,98.4472976 C113.267771,97.8942976 113.858771,97.2022976 114.292771,96.4142976 C114.727771,95.6262976 114.997771,94.7572976 115.087771,93.8622976 C115.176771,92.9662976 115.082771,92.0612976 114.812771,91.2032976 C113.109771,91.7752976 111.652771,92.9112976 110.682771,94.4232976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M118.372771,99.3432976 C114.722771,99.1232976 111.602771,101.783298 111.602771,101.783298 C111.602771,101.783298 114.392771,104.783298 118.042771,105.003298 C121.692771,105.223298 124.822771,102.563298 124.822771,102.563298 C124.822771,102.563298 122.022771,99.5332976 118.372771,99.3432976 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M38.7027706,65.4432976 C40.0407706,66.6432976 41.7487706,67.3482976 43.5427706,67.4432976 C43.5737706,66.5432976 43.4217706,65.6472976 43.0967706,64.8072976 C42.7717706,63.9682976 42.2797706,63.2032976 41.6507706,62.5592976 C41.0217706,61.9152976 40.2687706,61.4052976 39.4377706,61.0602976 C38.6057706,60.7152976 37.7127706,60.5432976 36.8127706,60.5532976 C36.8667706,62.3512976 37.5337706,64.0762976 38.7027706,65.4432976 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M45.6427706,59.5332976 C44.3827706,62.9632976 46.0127706,66.7232976 46.0127706,66.7232976 C46.0127706,66.7232976 49.7027706,64.9232976 50.9627706,61.4832976 C52.2227706,58.0432976 50.5927706,54.2932976 50.5927706,54.2932976 C50.5927706,54.2932976 46.9027706,56.0732976 45.6427706,59.5332976 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M83.5727706,80.5332976 C82.4417706,81.0582976 81.5217706,81.9492976 80.9627706,83.0632976 C82.1217706,83.5202976 83.4007706,83.5732976 84.5927706,83.2132976 C85.7257706,82.6932976 86.6477706,81.8002976 87.2027706,80.6832976 C86.0447706,80.2252976 84.7647706,80.1722976 83.5727706,80.5332976 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="tree" transform="translate(662.904821, 692.941744)" fill-rule="nonzero">
                <path
                  d="M2.67527925,3.50815614 C3.67527925,2.29815614 6.80527925,1.81815614 6.74527925,4.02815614 C7.65527925,-2.80184386 16.5852792,-0.211843855 12.4152792,6.31815614 C19.2752792,-0.811843855 25.3552792,8.31815614 16.4752792,12.3781561 C24.0252792,12.5281561 19.8052792,23.2981561 13.1852792,18.5981561 C15.8652792,20.2081561 14.1852792,23.7381561 11.2752792,23.2781561 C3.59527925,22.0181561 -4.25472075,9.77815614 2.67527925,3.50815614 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M7.17527925,19.2481561 C7.83927925,19.3301561 8.51127925,19.3301561 9.17527925,19.2481561 C10.1072792,19.1541561 10.9702792,18.7151561 11.5952792,18.0181561 C11.8602792,17.6991561 12.0432792,17.3201561 12.1292792,16.9141561 C12.2142792,16.5081561 12.1992792,16.0871561 12.0852792,15.6881561 C11.6852792,14.5781561 10.2352792,14.8381561 9.57527925,14.0081561 C9.41627925,13.7601561 9.30827925,13.4831561 9.25727925,13.1931561 C9.20527925,12.9031561 9.21127925,12.6061561 9.27527925,12.3181561 C9.41127925,11.7481561 9.43827925,11.1581561 9.35527925,10.5781561 C9.31027925,10.2831561 9.15627925,10.0161561 8.92227925,9.83015614 C8.68827925,9.64415614 8.39327925,9.55415614 8.09527925,9.57815614 C7.66527925,9.66815614 7.39527925,10.0881561 7.09527925,10.3881561 C7.01527925,10.4891561 6.91527925,10.5721561 6.80227925,10.6321561 C6.68827925,10.6921561 6.56327925,10.7281561 6.43527925,10.7381561 C5.35527925,10.7381561 5.10527925,9.09815614 3.97527925,9.29815614 C2.84527925,9.49815614 2.12527925,10.7481561 1.79527925,11.7881561 C1.49527925,12.6851561 1.42927925,13.6441561 1.60427925,14.5731561 C1.77927925,15.5031561 2.18927925,16.3721561 2.79527925,17.0981561 C3.93227925,18.3411561 5.49627925,19.1091561 7.17527925,19.2481561 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="232.0801 23.17 552.0801 31.52 552.0801 20 232.0801 20"></polygon>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="232.0801 243.1699 552.0801 248.5199 552.0801 239.9999 232.0801 239.9999"></polygon>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="232.0801 463.1699 552.0801 468.5199 552.0801 459.9999 232.0801 459.9999"></polygon>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="552.0801 353.1699 232.0801 358.5199 232.0801 349.9999 552.0801 349.9999"></polygon>
              <polygon id="Path" fill="#DCDCDC" fill-rule="nonzero"
                points="552.0801 133.1699 232.0801 138.5199 232.0801 129.9999 552.0801 129.9999"></polygon>
              <polygon id="Path" fill="#C8C8C8" fill-rule="nonzero"
                points="232.0801 205.0899 62.0801 213.2899 62.0801 199.9999 232.0801 199.9999"></polygon>
              <polygon id="Path" fill="#C8C8C8" fill-rule="nonzero"
                points="722.0801 205.0899 552.0801 213.2899 552.0801 199.9999 722.0801 199.9999"></polygon>
              <polygon id="Path" fill="#C8C8C8" fill-rule="nonzero"
                points="558.5301 199.9999 552.0801 199.9999 552.0801 729.9999 558.5301 729.9999"></polygon>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="566.0901 569.9999 218.0801 569.9999 218.0801 589.9999 566.0901 589.9999"></polygon>
              <polygon id="Path" fill="#404040" fill-rule="nonzero"
                points="452.0801 569.9999 332.0801 569.9999 332.0801 609.9999 452.0801 609.9999"></polygon>
              <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero"
                points="441.0801 580.9999 343.0801 580.9999 343.0801 598.9999 441.0801 598.9999"></polygon>
              <path
                d="M440.0801,581.9999 L440.0801,597.9999 L344.0801,597.9999 L344.0801,581.9999 L440.0801,581.9999 Z M442.0801,579.9999 L342.0801,579.9999 L342.0801,599.9999 L442.0801,599.9999 L442.0801,579.9999 Z"
                id="Shape" fill="#D4DADC" fill-rule="nonzero"></path>
              <line x1="232.0801" y1="129.9999" x2="552.0801" y2="129.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="239.9999" x2="552.0801" y2="239.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="349.9999" x2="552.0801" y2="349.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="459.9999" x2="552.0801" y2="459.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="129.9999" x2="552.0801" y2="129.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="239.9999" x2="552.0801" y2="239.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="349.9999" x2="552.0801" y2="349.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
              <line x1="232.0801" y1="459.9999" x2="552.0801" y2="459.9999" id="Path" stroke="#C8C8C8" stroke-width="3">
              </line>
            </g>
          </g>
        </g>
      </svg>
      <svg class="building-drawing" id="mobilehomeSVG" viewBox="0 0 820 397" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Mobile Home</title>
        <defs>
          <clipPath id="mobileHomeClipPath">
            <rect class="mobileHomeCenterRect" x="0" y="100%" width="100%" height="100%" fill="none"></rect>
          </clipPath>
        </defs>
        <g id="mobileHomeClipReveal" clip-path="url(#mobileHomeClipPath)">
          <g id="Figma-Imports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group">
              <polygon id="Path" fill="#404040" fill-rule="nonzero" points="10 50 100 20 720 20 810 50">
              </polygon>
              <polygon id="Path" fill="#D4DADC" fill-rule="nonzero" points="810 69.01 10 69.01 10 359.01 810 359.01">
              </polygon>
              <polygon id="Path" fill="#404040" fill-rule="nonzero" points="810 362.34 10 362.34 10 396.47 810 396.47">
              </polygon>
              <polygon id="Path" fill="#C8C8C8" fill-rule="nonzero" points="810 309.01 10 309.01 10 367.32 810 367.32">
              </polygon>
              <polygon id="Path" fill="#D4DADC" fill-rule="nonzero" points="560 74.01 410 19.01 260 74.01">
              </polygon>
              <polygon id="shadow" fill="#C8C8C8" fill-rule="nonzero"
                points="810 74.01 560 84.02 410 24.01 260 84.02 10 74.01 10 65.01 260 65.01 410 15.01 560 65.01 810 65.01">
              </polygon>
              <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero"
                points="820 69.01 560 69.01 410 19.01 260 69.01 0 69.01 0 50 260 50 410 0 560 50 820 50">
              </polygon>
              <g id="mobileHomeWindow" transform="translate(60.000000, 118.510000)">
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="118.5 2 31.5 2 31.5 139 118.5 139">
                </polygon>
                <path
                  d="M117,3.5 L117,137.5 L33,137.5 L33,3.5 L117,3.5 Z M120,0.5 L30,0.5 L30,140.5 L120,140.5 L120,0.5 Z"
                  id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="30 0.5 0 0.5 0 140.5 30 140.5">
                </polygon>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="150 0.5 120 0.5 120 140.5 150 140.5">
                </polygon>
                <line x1="30" y1="70.5" x2="120" y2="70.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="60" y1="0.5" x2="60" y2="140.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="90" y1="0.5" x2="90" y2="140.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="0" y1="0.5" x2="30" y2="0.5" id="Path" stroke="#DCDCDC" stroke-width="3"></line>
                <line x1="0" y1="14.53" x2="30" y2="14.53" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="28.56" x2="30" y2="28.56" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="42.59" x2="30" y2="42.59" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="56.62" x2="30" y2="56.62" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="70.66" x2="30" y2="70.66" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="84.69" x2="30" y2="84.69" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="98.72" x2="30" y2="98.72" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="112.75" x2="30" y2="112.75" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="126.78" x2="30" y2="126.78" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="140.5" x2="30" y2="140.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="0.5" x2="150" y2="0.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="14.53" x2="150" y2="14.53" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="28.56" x2="150" y2="28.56" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="42.59" x2="150" y2="42.59" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="56.62" x2="150" y2="56.62" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="70.66" x2="150" y2="70.66" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="84.69" x2="150" y2="84.69" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="98.72" x2="150" y2="98.72" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="112.75" x2="150" y2="112.75" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="126.78" x2="150" y2="126.78" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="140.5" x2="150" y2="140.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
              </g>
              <polygon id="shadow" fill="#C8C8C8" fill-rule="nonzero"
                points="180 262.1 90.18 266.76 90.18 259.01 180 259.01"></polygon>
              <g id="mobileHomeWindow" transform="translate(610.000000, 118.510000)">
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="118.5 2 31.5 2 31.5 139 118.5 139">
                </polygon>
                <path
                  d="M117,3.5 L117,137.5 L33,137.5 L33,3.5 L117,3.5 Z M120,0.5 L30,0.5 L30,140.5 L120,140.5 L120,0.5 Z"
                  id="Shape" fill="#F0F0F0" fill-rule="nonzero"></path>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="30 0.5 0 0.5 0 140.5 30 140.5">
                </polygon>
                <polygon id="Path" fill="#F0F0F0" fill-rule="nonzero" points="150 0.5 120 0.5 120 140.5 150 140.5">
                </polygon>
                <line x1="30" y1="70.5" x2="120" y2="70.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="60" y1="0.5" x2="60" y2="140.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="90" y1="0.5" x2="90" y2="140.5" id="Path" stroke="#F0F0F0" stroke-width="3">
                </line>
                <line x1="0" y1="0.5" x2="30" y2="0.5" id="Path" stroke="#DCDCDC" stroke-width="3"></line>
                <line x1="0" y1="14.53" x2="30" y2="14.53" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="28.56" x2="30" y2="28.56" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="42.59" x2="30" y2="42.59" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="56.62" x2="30" y2="56.62" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="70.66" x2="30" y2="70.66" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="84.69" x2="30" y2="84.69" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="98.72" x2="30" y2="98.72" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="112.75" x2="30" y2="112.75" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="126.78" x2="30" y2="126.78" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="0" y1="140.5" x2="30" y2="140.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="0.5" x2="150" y2="0.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="14.53" x2="150" y2="14.53" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="28.56" x2="150" y2="28.56" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="42.59" x2="150" y2="42.59" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="56.62" x2="150" y2="56.62" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="70.66" x2="150" y2="70.66" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="84.69" x2="150" y2="84.69" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="98.72" x2="150" y2="98.72" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="112.75" x2="150" y2="112.75" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="126.78" x2="150" y2="126.78" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
                <line x1="120" y1="140.5" x2="150" y2="140.5" id="Path" stroke="#DCDCDC" stroke-width="3">
                </line>
              </g>
              <g id="mobileHomeWindow" transform="translate(340.000000, 99.010000)" fill-rule="nonzero">
                <polygon id="Path" stroke="#C8C8C8" stroke-width="3" fill="#F0F0F0" points="140 0 0 0 0 210 140 210">
                </polygon>
                <polygon id="Path" fill="#DCDCDC" points="120 20 20 20 20 210 120 210"></polygon>
                <polygon id="Path" fill="#3B4D5A" points="108.5 31.5 31.5 31.5 31.5 98.5 108.5 98.5">
                </polygon>
                <path d="M107,33 L107,97 L33,97 L33,33 L107,33 Z M110,30 L30,30 L30,100 L110,100 L110,30 Z" id="Shape"
                  fill="#F0F0F0"></path>
                <polygon id="Path" fill="#3B4D5A" points="108.5 111.5 31.5 111.5 31.5 188.5 108.5 188.5">
                </polygon>
                <path d="M107,113 L107,187 L33,187 L33,113 L107,113 Z M110,110 L30,110 L30,190 L110,190 L110,110 Z"
                  id="Shape" fill="#F0F0F0"></path>
              </g>
              <g id="stair" transform="translate(310.000000, 309.010000)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="200 0 0 0 0 29.15 200 29.15"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="200 22.61 0 22.61 0 29.15 200 29.15"></polygon>
              </g>
              <g id="stair" transform="translate(288.370000, 338.160000)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="243.27 0 0 0 0 29.15 243.27 29.15"></polygon>
                <polygon id="Path" fill="#DCDCDC" points="243.27 22.62 0 22.62 0 29.16 243.27 29.16">
                </polygon>
              </g>
              <g id="stair" transform="translate(265.910000, 367.320000)" fill-rule="nonzero">
                <polygon id="Path" fill="#F0F0F0" points="288.19 0 4.54747351e-13 0 4.54747351e-13 29.15 288.19 29.15">
                </polygon>
                <polygon id="Path" fill="#DCDCDC"
                  points="288.19 22.61 4.54747351e-13 22.61 4.54747351e-13 29.15 288.19 29.15"></polygon>
              </g>
              <g id="mobileHomeWindow" transform="translate(247.000000, 126.010000)">
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="54.5 1.5 1.5 1.5 1.5 74.5 54.5 74.5">
                </polygon>
                <path d="M53,3 L53,73 L3,73 L3,3 L53,3 Z M56,0 L0,0 L0,76 L56,76 L56,0 Z" id="Shape" fill="#F0F0F0"
                  fill-rule="nonzero"></path>
                <line x1="28" y1="3" x2="28" y2="73" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
                <line x1="3" y1="38" x2="53" y2="38" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
              </g>
              <g id="mobileHomeWindow" transform="translate(517.000000, 126.010000)">
                <polygon id="Path" fill="#3B4D5A" fill-rule="nonzero" points="54.5 1.5 1.5 1.5 1.5 74.5 54.5 74.5">
                </polygon>
                <path d="M53,3 L53,73 L3,73 L3,3 L53,3 Z M56,0 L0,0 L0,76 L56,76 L56,0 Z" id="Shape" fill="#F0F0F0"
                  fill-rule="nonzero"></path>
                <line x1="28" y1="3" x2="28" y2="73" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
                <line x1="3" y1="38" x2="53" y2="38" id="Path" stroke="#F0F0F0" stroke-width="3"></line>
              </g>
              <g id="mobileHomeWindow" transform="translate(380.000000, 39.010000)" fill="#3B4D5A" fill-rule="nonzero">
                <path
                  d="M60,30 C60,22.044 56.839,14.413 51.213,8.787 C45.587,3.161 37.956,0 30,0 C22.044,0 14.413,3.161 8.787,8.787 C3.161,14.413 0,22.044 0,30 L60,30 Z"
                  id="Path"></path>
              </g>
              <line x1="780" y1="309.01" x2="780" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="750" y1="309.01" x2="750" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="720" y1="309.01" x2="720" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="690" y1="309.01" x2="690" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="660" y1="309.01" x2="660" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="630" y1="309.01" x2="630" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="600" y1="309.01" x2="600" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="570" y1="309.01" x2="570" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="540" y1="309.01" x2="540" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="280" y1="309.01" x2="280" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="250" y1="309.01" x2="250" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="220" y1="309.01" x2="220" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="190" y1="309.01" x2="190" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="160" y1="309.01" x2="160" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="130" y1="309.01" x2="130" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="100" y1="309.01" x2="100" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="70" y1="309.01" x2="70" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <line x1="40" y1="309.01" x2="40" y2="367.32" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <polygon id="mobileHomeWindowSill" fill="#F0F0F0" fill-rule="nonzero"
                points="310 199.01 240 199.01 240 219.01 310 219.01"></polygon>
              <polygon id="mobileHomeWindowSill" fill="#F0F0F0" fill-rule="nonzero"
                points="580 199.01 510 199.01 510 219.01 580 219.01"></polygon>
              <polygon id="shadow" fill="#C8C8C8" fill-rule="nonzero" points="310 223 240 229.01 240 219.01 310 219.01">
              </polygon>
              <polygon id="shadow" fill="#C8C8C8" fill-rule="nonzero"
                points="729.91 262.1 640.09 266.76 640.09 259.01 729.91 259.01"></polygon>
              <polygon id="shadow" fill="#C8C8C8" fill-rule="nonzero" points="580 223 510 229.01 510 219.01 580 219.01">
              </polygon>
              <g id="fencePost" transform="translate(317.570000, 236.010000)" fill="#543434" fill-rule="nonzero">
                <polygon id="Path" points="9.26 5.99 3.26 5.99 3.26 73.17 9.26 73.17"></polygon>
                <path
                  d="M9.53,0 L3,0 C1.343,0 0,1.343 0,3 C0,4.657 1.343,6 3,6 L9.53,6 C11.187,6 12.53,4.657 12.53,3 C12.53,1.343 11.187,0 9.53,0 Z"
                  id="Path"></path>
              </g>
              <g id="fencePost" transform="translate(489.550000, 236.010000)" fill="#543434" fill-rule="nonzero">
                <polygon id="Path" points="9.27 5.99 3.27 5.99 3.27 73.17 9.27 73.17"></polygon>
                <path
                  d="M9.53,0 L3,0 C1.343,0 0,1.343 0,3 C0,4.657 1.343,6 3,6 L9.53,6 C11.187,6 12.53,4.657 12.53,3 C12.53,1.343 11.187,0 9.53,0 Z"
                  id="Path"></path>
              </g>
              <line x1="810" y1="309.01" x2="10" y2="309.01" id="Path" stroke="#F0F0F0" stroke-width="3">
              </line>
              <g id="treeTrunk" transform="translate(266.480000, 327.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#543434" points="7.93 21.42 8.76 0 10.26 0 11.22 21.01"></polygon>
                <polygon id="Path" fill="#D4DADC" points="15.71 40.32 3.59 40.32 1.23 21.42 18.08 21.42">
                </polygon>
                <polygon id="Path" fill="#D4DADC" points="0 18.48 0.54 25.05 18.48 25.33 19.4 18.21">
                </polygon>
                <polygon id="Path" fill="#C8C8C8" points="1.56 25.07 17.59 25.31 17.51 25.94 2.01 27.8">
                </polygon>
              </g>
              <g id="tree" transform="translate(253.975689, 294.000000)" fill-rule="nonzero">
                <path
                  d="M20.1343107,3.04 C19.7323107,3.824 19.0763107,4.448 18.2743107,4.81 C17.9793107,3.979 17.9793107,3.071 18.2743107,2.24 C18.6783107,1.455 19.3383107,0.831 20.1443107,0.47 C20.4333107,1.303 20.4303107,2.209 20.1343107,3.04 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M15.9043107,1.53 C16.6433107,2.496 17.0313107,3.684 17.0043107,4.9 C16.3963107,4.874 15.7993107,4.725 15.2503107,4.462 C14.7013107,4.198 14.2113107,3.826 13.8103107,3.368 C13.4093107,2.91 13.1053107,2.376 12.9173107,1.797 C12.7283107,1.218 12.6593107,0.606 12.7143107,0 C13.9203107,0.134 15.0453107,0.673 15.9043107,1.53 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M43.3143107,20.36 C42.6503107,17.631 41.4093107,15.076 39.6733107,12.868 C37.9383107,10.66 35.7493107,8.85 33.2543107,7.56 C28.4843107,4.99 22.7943107,4.77 17.5543107,5.72 C9.55431072,7.17 2.81431072,11.09 0.804310717,19.47 C-0.495689283,24.92 -0.275689283,32.19 1.87431072,37.4 C2.92331072,39.877 4.55131072,42.067 6.62231072,43.784 C8.69331072,45.502 11.1453107,46.697 13.7743107,47.27 C20.2343107,48.62 28.2543107,48.35 33.8843107,44.64 C35.9573107,43.354 37.8643107,41.819 39.5643107,40.07 C41.9643107,37.47 42.9543107,34.4 43.5643107,30.97 C44.2263107,27.455 44.1403107,23.84 43.3143107,20.36 Z"
                  id="Path" fill="#379F4D"></path>
                <polygon id="Path" fill="#543434"
                  points="15.9443107 34.57 11.3543107 29.45 6.48431072 29.18 6.48431072 28.73 10.4343107 28.44 6.48431072 23.59 7.36431072 22.61 17.6443107 31.64">
                </polygon>
                <polygon id="Path" fill="#543434"
                  points="24.4043107 22.42 29.4443107 19.07 30.2843107 15.82 30.5343107 15.91 30.4643107 18.3 31.5843107 17.46 31.9043107 18.11 25.9143107 24.53">
                </polygon>
              </g>
              <g id="treeTrunk" transform="translate(534.530000, 327.000000)" fill-rule="nonzero">
                <polygon id="Path" fill="#543434" points="7.94 21.42 8.76 0 10.27 0 11.22 21.01"></polygon>
                <polygon id="Path" fill="#D4DADC" points="15.71 40.32 3.6 40.32 1.23 21.42 18.08 21.42">
                </polygon>
                <polygon id="Path" fill="#D4DADC" points="0 18.48 0.55 25.05 18.48 25.33 19.41 18.21">
                </polygon>
                <polygon id="Path" fill="#C8C8C8" points="1.57 25.07 17.59 25.31 17.51 25.94 2.02 27.8">
                </polygon>
              </g>
              <g id="tree" transform="translate(522.032753, 294.000000)" fill-rule="nonzero">
                <path
                  d="M43.3172474,20.36 C42.6532474,17.631 41.4122474,15.076 39.6762474,12.868 C37.9412474,10.66 35.7522474,8.85 33.2572474,7.56 C28.4872474,4.99 22.7872474,4.77 17.5572474,5.72 C9.55724743,7.17 2.80724743,11.09 0.807247429,19.47 C-0.492752571,24.92 -0.282752571,32.19 1.87724743,37.4 C2.92524743,39.878 4.55224743,42.068 6.62324743,43.786 C8.69424743,45.504 11.1482474,46.699 13.7772474,47.27 C20.2272474,48.62 28.2572474,48.35 33.8872474,44.64 C35.9582474,43.352 37.8652474,41.817 39.5672474,40.07 C41.9572474,37.47 42.9472474,34.4 43.5672474,30.97 C44.2242474,27.454 44.1382474,23.841 43.3172474,20.36 Z"
                  id="Path" fill="#379F4D"></path>
                <polygon id="Path" fill="#543434"
                  points="15.9372474 34.57 11.3572474 29.45 6.48724743 29.18 6.48724743 28.73 10.4372474 28.44 6.48724743 23.59 7.35724743 22.61 17.6372474 31.64">
                </polygon>
                <polygon id="Path" fill="#543434"
                  points="24.3972474 22.42 29.4472474 19.07 30.2872474 15.82 30.5272474 15.91 30.4672474 18.3 31.5872474 17.46 31.9072474 18.11 25.9172474 24.53">
                </polygon>
                <path
                  d="M14.8472474,1.53 C15.5862474,2.496 15.9742474,3.684 15.9472474,4.9 C15.3392474,4.874 14.7422474,4.725 14.1932474,4.462 C13.6442474,4.198 13.1542474,3.826 12.7532474,3.368 C12.3522474,2.91 12.0482474,2.376 11.8602474,1.797 C11.6712474,1.218 11.6022474,0.606 11.6572474,0 C12.8632474,0.134 13.9882474,0.673 14.8472474,1.53 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M19.0772474,3.04 C18.6752474,3.824 18.0192474,4.448 17.2172474,4.81 C16.9222474,3.979 16.9222474,3.071 17.2172474,2.24 C17.6212474,1.455 18.2812474,0.831 19.0872474,0.47 C19.3762474,1.303 19.3732474,2.209 19.0772474,3.04 Z"
                  id="Path" fill="#379F4D"></path>
              </g>
              <g id="bush" transform="translate(112.164396, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.2956038,32.404 C65.6856038,32.404 66.1656038,31.514 66.4256038,31.194 C67.0926038,30.445 67.5326038,29.523 67.6956038,28.534 C67.7996038,27.798 67.7286038,27.047 67.4876038,26.344 C67.2456038,25.64 66.8416038,25.004 66.3066038,24.487 C65.7716038,23.97 65.1226038,23.587 64.4116038,23.369 C63.7006038,23.151 62.9476038,23.105 62.2156038,23.234 L61.9656038,23.234 C61.6226038,23.293 61.2876038,23.391 60.9656038,23.524 C60.0816038,23.941 59.2686038,24.495 58.5556038,25.164 C59.3196038,24.3 59.9306038,23.312 60.3656038,22.244 C62.8556038,16.344 57.5856038,7.784 51.0256038,10.244 C48.6576038,11.517 46.7296038,13.476 45.4956038,15.864 C49.1956038,7.684 41.2156038,3.134 38.2756038,10.864 C39.3356038,7.174 38.9356038,3.394 37.3856038,1.404 L37.3856038,1.244 L37.1556038,1.134 C36.4086038,0.407 35.4076038,0 34.3656038,0 C33.3236038,0 32.3226038,0.407 31.5756038,1.134 L31.3356038,1.244 L31.3356038,1.404 C29.7956038,3.404 29.3956038,7.174 30.4456038,10.864 C27.5156038,3.174 19.5256038,7.724 23.2356038,15.864 C21.9976038,13.478 20.0716038,11.52 17.7056038,10.244 C11.1456038,7.824 5.87560381,16.384 8.36560381,22.244 C8.79960381,23.312 9.41160381,24.3 10.1756038,25.164 C9.46260381,24.495 8.64960381,23.941 7.76560381,23.524 C7.44360381,23.392 7.10860381,23.295 6.76560381,23.234 L6.50560381,23.234 C5.77360381,23.102 5.01960381,23.147 4.30760381,23.364 C3.59660381,23.58 2.94660381,23.964 2.41160381,24.482 C1.87760381,24.999 1.47360381,25.637 1.23460381,26.342 C0.995603807,27.046 0.926603807,27.798 1.03560381,28.534 C1.19360381,29.524 1.63460381,30.448 2.30560381,31.194 C-0.0443961929,31.514 -1.85439619,36.984 3.43560381,37.414 L67.3056038,37.414 C67.3056038,37.414 68.8356038,32.194 65.2956038,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.3056038,17.274 C41.6166038,16.482 41.6996038,15.62 41.5456038,14.784 C40.8396038,15.022 40.2516038,15.524 39.9056038,16.184 C39.5946038,16.975 39.5076038,17.837 39.6556038,18.674 C40.3656038,18.437 40.9556038,17.935 41.3056038,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.2256038,37.404 C56.2256038,35.654 56.2256038,31.984 52.9956038,30.744 C52.3926038,30.56 51.7566038,30.516 51.1346038,30.615 C50.5126038,30.714 49.9216038,30.953 49.4056038,31.314 C50.4306038,30.664 51.1756038,29.655 51.4956038,28.484 C52.9656038,25.004 49.8556038,19.964 45.9956038,21.394 C44.5936038,22.147 43.4526038,23.308 42.7256038,24.724 C44.9256038,19.884 40.2156038,17.194 38.4856038,21.724 C40.2556038,16.504 36.9156038,14.624 34.3956038,14.624 C31.8756038,14.624 28.5356038,16.504 30.3056038,21.724 C28.5756038,17.184 23.8556038,19.874 26.0656038,24.724 C25.3476038,23.318 24.2216038,22.161 22.8356038,21.404 C18.9656038,19.974 15.8356038,25.014 17.3356038,28.494 C17.6556038,29.665 18.4006038,30.674 19.4256038,31.324 C18.9066038,30.969 18.3156038,30.734 17.6946038,30.636 C17.0736038,30.537 16.4386038,30.578 15.8356038,30.754 C15.1966038,30.999 14.6336038,31.41 14.2046038,31.944 C13.7756038,32.477 13.4966038,33.115 13.3946038,33.793 C13.2926038,34.47 13.3726038,35.162 13.6256038,35.798 C13.8786038,36.434 14.2966038,36.992 14.8356038,37.414 L55.2256038,37.414 L55.2256038,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.1256038,18.674 C29.2796038,17.838 29.1966038,16.975 28.8856038,16.184 C28.5396038,15.524 27.9516038,15.022 27.2456038,14.784 C27.0916038,15.62 27.1756038,16.482 27.4856038,17.274 C27.8316038,17.934 28.4196038,18.435 29.1256038,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="bush" transform="translate(170.124396, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.2956038,32.404 C65.6856038,32.404 66.1656038,31.514 66.4256038,31.194 C67.0966038,30.448 67.5376038,29.524 67.6956038,28.534 C67.8016038,27.798 67.7326038,27.048 67.4916038,26.344 C67.2516038,25.641 66.8486038,25.004 66.3146038,24.487 C65.7806038,23.969 65.1316038,23.586 64.4206038,23.368 C63.7096038,23.151 62.9576038,23.104 62.2256038,23.234 L61.9656038,23.234 C61.6226038,23.293 61.2876038,23.391 60.9656038,23.524 C60.0816038,23.941 59.2686038,24.495 58.5556038,25.164 C59.3196038,24.3 59.9316038,23.312 60.3656038,22.244 C62.8556038,16.344 57.5856038,7.784 51.0256038,10.244 C48.6606038,11.52 46.7336038,13.478 45.4956038,15.864 C49.2056038,7.684 41.2156038,3.134 38.2856038,10.864 C39.3356038,7.174 38.9356038,3.394 37.3956038,1.404 L37.3956038,1.244 L37.1556038,1.134 C36.4086038,0.407 35.4076038,0 34.3656038,0 C33.3236038,0 32.3226038,0.407 31.5756038,1.134 C31.4956038,1.134 31.4256038,1.214 31.3356038,1.244 L31.3356038,1.404 C29.7956038,3.404 29.3956038,7.174 30.4556038,10.864 C27.5156038,3.174 19.5256038,7.724 23.2356038,15.864 C21.9986038,13.478 20.0716038,11.52 17.7056038,10.244 C11.1456038,7.824 5.87560381,16.384 8.36560381,22.244 C8.80060381,23.312 9.41260381,24.3 10.1756038,25.164 C9.46260381,24.495 8.64960381,23.941 7.76560381,23.524 C7.44460381,23.391 7.10860381,23.293 6.76560381,23.234 L6.51560381,23.234 C5.78360381,23.105 5.03060381,23.151 4.31960381,23.369 C3.60860381,23.587 2.95960381,23.97 2.42460381,24.487 C1.88960381,25.004 1.48560381,25.64 1.24460381,26.344 C1.00260381,27.047 0.931603807,27.798 1.03560381,28.534 C1.19460381,29.524 1.63560381,30.448 2.30560381,31.194 C-0.0443961929,31.514 -1.85439619,36.984 3.43560381,37.414 L67.3056038,37.414 C67.3056038,37.414 68.8756038,32.194 65.2956038,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.3056038,17.274 C41.6166038,16.482 41.6996038,15.62 41.5456038,14.784 C40.8396038,15.022 40.2516038,15.524 39.9056038,16.184 C39.5946038,16.975 39.5116038,17.838 39.6656038,18.674 C40.3716038,18.435 40.9596038,17.934 41.3056038,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.2356038,37.404 C56.2356038,35.654 56.1756038,31.984 52.9956038,30.744 C52.3936038,30.56 51.7566038,30.516 51.1346038,30.615 C50.5126038,30.714 49.9216038,30.953 49.4056038,31.314 C50.4306038,30.664 51.1756038,29.655 51.4956038,28.484 C52.9656038,25.004 49.8556038,19.964 45.9956038,21.394 C44.5936038,22.147 43.4526038,23.308 42.7256038,24.724 C44.9356038,19.884 40.2156038,17.194 38.4856038,21.724 C40.2556038,16.504 36.9256038,14.624 34.3956038,14.624 C31.8656038,14.624 28.5356038,16.504 30.3056038,21.724 C28.5756038,17.184 23.8556038,19.874 26.0656038,24.724 C25.3386038,23.308 24.1976038,22.147 22.7956038,21.394 C18.9356038,19.964 15.7956038,25.004 17.2956038,28.484 C17.6156038,29.655 18.3606038,30.664 19.3856038,31.314 C18.8666038,30.959 18.2756038,30.724 17.6546038,30.626 C17.0336038,30.527 16.3986038,30.568 15.7956038,30.744 C15.1566038,30.989 14.5936038,31.4 14.1646038,31.934 C13.7356038,32.467 13.4566038,33.105 13.3546038,33.783 C13.2536038,34.46 13.3326038,35.152 13.5856038,35.788 C13.8396038,36.424 14.2566038,36.982 14.7956038,37.404 L55.1956038,37.404 L55.2356038,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.1256038,18.674 C29.2796038,17.838 29.1966038,16.975 28.8856038,16.184 C28.5396038,15.524 27.9516038,15.022 27.2456038,14.784 C27.0916038,15.62 27.1746038,16.482 27.4856038,17.274 C27.8316038,17.934 28.4196038,18.435 29.1256038,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="bush" transform="translate(54.213402, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.286598,32.404 C65.676598,32.404 66.156598,31.514 66.416598,31.194 C67.079598,30.443 67.519598,29.521 67.686598,28.534 C67.790598,27.798 67.719598,27.047 67.478598,26.344 C67.236598,25.64 66.832598,25.004 66.297598,24.487 C65.763598,23.97 65.113598,23.587 64.402598,23.369 C63.691598,23.151 62.938598,23.105 62.206598,23.234 L61.956598,23.234 C61.613598,23.293 61.278598,23.391 60.956598,23.524 C60.071598,23.939 59.257598,24.492 58.546598,25.164 C59.310598,24.3 59.922598,23.312 60.356598,22.244 C62.846598,16.344 57.576598,7.784 51.016598,10.244 C48.648598,11.517 46.721598,13.476 45.486598,15.864 C49.186598,7.684 41.206598,3.134 38.266598,10.864 C39.326598,7.174 38.926598,3.394 37.376598,1.404 L37.376598,1.244 L37.146598,1.134 C36.399598,0.407 35.398798,0 34.356698,0 C33.314498,0 32.313498,0.407 31.566698,1.134 C31.476698,1.134 31.406698,1.214 31.326698,1.244 L31.326698,1.404 C29.786698,3.404 29.376698,7.174 30.436698,10.864 C27.506698,3.174 19.516698,7.724 23.216698,15.864 C21.982198,13.476 20.054498,11.517 17.686698,10.244 C11.126698,7.824 5.85669804,16.384 8.35669804,22.244 C8.78399804,23.314 9.39299804,24.301 10.156698,25.164 C9.44759804,24.495 8.63769804,23.941 7.75669804,23.524 C7.43479804,23.391 7.09949804,23.294 6.75669804,23.234 L6.50669804,23.234 C5.77429804,23.102 5.02099804,23.147 4.30919804,23.364 C3.59739804,23.58 2.94739804,23.964 2.41289804,24.482 C1.87849804,24.999 1.47499804,25.637 1.23559804,26.342 C0.996298037,27.046 0.928098037,27.798 1.03669804,28.534 C1.19609804,29.522 1.63299804,30.445 2.29669804,31.194 C-0.0433019632,31.514 -1.85330196,36.984 3.43669804,37.414 L67.306598,37.414 C67.306598,37.414 68.786598,32.194 65.286598,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.286598,17.274 C41.597598,16.483 41.684598,15.621 41.536598,14.784 C40.827598,15.021 40.236598,15.522 39.886598,16.184 C39.575598,16.975 39.492598,17.838 39.646598,18.674 C40.352598,18.435 40.940598,17.934 41.286598,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.216598,37.404 C56.216598,35.654 56.166598,31.984 52.986598,30.744 C52.383598,30.56 51.747598,30.516 51.125598,30.615 C50.503598,30.714 49.912598,30.953 49.396598,31.314 C50.418598,30.661 51.163598,29.653 51.486598,28.484 C52.946598,25.004 49.846598,19.964 45.986598,21.394 C44.584598,22.147 43.443598,23.308 42.716598,24.724 C44.916598,19.884 40.206598,17.194 38.476598,21.724 C40.246598,16.504 36.906598,14.624 34.386498,14.624 C31.866498,14.624 28.526498,16.504 30.296498,21.724 C28.566498,17.184 23.846498,19.874 26.046498,24.724 C25.321398,23.313 24.184498,22.155 22.786498,21.404 C18.916498,19.974 15.786498,25.014 17.286498,28.494 C17.602798,29.664 18.344598,30.673 19.366498,31.324 C18.849298,30.97 18.259798,30.735 17.640698,30.636 C17.021498,30.537 16.388198,30.578 15.786498,30.754 C15.149398,31 14.588798,31.411 14.161598,31.944 C13.734398,32.477 13.455898,33.113 13.354298,33.788 C13.252698,34.464 13.331698,35.154 13.583298,35.789 C13.834798,36.424 14.249998,36.981 14.786498,37.404 L55.186598,37.404 L55.216598,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.116698,18.674 C29.270398,17.838 29.187298,16.975 28.876698,16.184 C28.530298,15.524 27.942998,15.022 27.236698,14.784 C27.082998,15.62 27.166198,16.482 27.476698,17.274 C27.823098,17.934 28.410398,18.435 29.116698,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="bush" transform="translate(653.063302, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.286698,32.404 C65.676698,32.404 66.156698,31.514 66.416698,31.194 C67.079698,30.443 67.518698,29.521 67.686698,28.534 C67.790698,27.798 67.719698,27.047 67.478698,26.344 C67.236698,25.64 66.832698,25.004 66.297698,24.487 C65.762698,23.97 65.113698,23.587 64.402698,23.369 C63.691698,23.151 62.938698,23.105 62.206698,23.234 L61.956698,23.234 C61.613698,23.293 61.278698,23.391 60.956698,23.524 C60.070698,23.939 59.257698,24.492 58.546698,25.164 C59.310698,24.3 59.922698,23.312 60.356698,22.244 C62.846698,16.344 57.576698,7.784 51.016698,10.244 C48.648698,11.517 46.720698,13.476 45.486698,15.864 C49.186698,7.684 41.206698,3.134 38.266698,10.864 C39.326698,7.174 38.926698,3.394 37.376698,1.404 L37.376698,1.244 L37.146698,1.134 C36.399698,0.407 35.398698,0 34.356698,0 C33.314698,0 32.313698,0.407 31.566698,1.134 C31.476698,1.134 31.406698,1.214 31.326698,1.244 L31.326698,1.404 C29.786698,3.404 29.376698,7.174 30.436698,10.864 C27.506698,3.174 19.516698,7.724 23.216698,15.864 C21.982698,13.476 20.054698,11.517 17.686698,10.244 C11.126698,7.824 5.85669804,16.384 8.35669804,22.244 C8.78369804,23.314 9.39269804,24.301 10.156698,25.164 C9.44769804,24.495 8.63769804,23.941 7.75669804,23.524 C7.43469804,23.391 7.09969804,23.294 6.75669804,23.234 L6.50669804,23.234 C5.77469804,23.102 5.02069804,23.147 4.30869804,23.364 C3.59769804,23.58 2.94769804,23.964 2.41269804,24.482 C1.87869804,24.999 1.47469804,25.637 1.23569804,26.342 C0.996698037,27.046 0.927698037,27.798 1.03669804,28.534 C1.19569804,29.522 1.63269804,30.445 2.29669804,31.194 C-0.0433019632,31.514 -1.85330196,36.984 3.43669804,37.414 L67.306698,37.414 C67.306698,37.414 68.816698,32.194 65.286698,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.286698,17.274 C41.597698,16.483 41.684698,15.621 41.536698,14.784 C40.828698,15.024 40.238698,15.524 39.886698,16.184 C39.575698,16.975 39.492698,17.838 39.646698,18.674 C40.352698,18.435 40.939698,17.934 41.286698,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.216698,37.404 C56.216698,35.654 56.216698,31.984 52.986698,30.744 C52.384698,30.56 51.747698,30.516 51.125698,30.615 C50.503698,30.714 49.912698,30.953 49.396698,31.314 C50.418698,30.661 51.163698,29.653 51.486698,28.484 C52.946698,25.004 49.846698,19.964 45.986698,21.394 C44.584698,22.147 43.443698,23.308 42.716698,24.724 C44.916698,19.884 40.206698,17.194 38.476698,21.724 C40.246698,16.504 36.906698,14.624 34.386698,14.624 C31.866698,14.624 28.526698,16.504 30.296698,21.724 C28.566698,17.184 23.846698,19.874 26.046698,24.724 C25.322698,23.309 24.185698,22.147 22.786698,21.394 C18.916698,19.964 15.786698,25.004 17.286698,28.484 C17.602698,29.654 18.344698,30.663 19.366698,31.314 C18.849698,30.96 18.259698,30.725 17.640698,30.626 C17.021698,30.527 16.388698,30.568 15.786698,30.744 C15.147698,30.989 14.584698,31.4 14.155698,31.934 C13.726698,32.467 13.447698,33.105 13.345698,33.783 C13.243698,34.46 13.323698,35.152 13.576698,35.788 C13.830698,36.424 14.247698,36.982 14.786698,37.404 L55.186698,37.404 L55.216698,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.116698,18.674 C29.270698,17.838 29.187698,16.975 28.876698,16.184 C28.530698,15.524 27.942698,15.022 27.236698,14.784 C27.082698,15.62 27.165698,16.482 27.476698,17.274 C27.822698,17.934 28.410698,18.435 29.116698,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="bush" transform="translate(724.024396, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.2956038,32.404 C65.6856038,32.404 66.1656038,31.514 66.4256038,31.194 C67.0926038,30.445 67.5326038,29.523 67.6956038,28.534 C67.7996038,27.798 67.7286038,27.047 67.4876038,26.344 C67.2456038,25.64 66.8416038,25.004 66.3066038,24.487 C65.7716038,23.97 65.1226038,23.587 64.4116038,23.369 C63.7006038,23.151 62.9476038,23.105 62.2156038,23.234 L61.9656038,23.234 C61.6226038,23.293 61.2876038,23.391 60.9656038,23.524 C60.0816038,23.941 59.2686038,24.495 58.5556038,25.164 C59.3196038,24.3 59.9316038,23.312 60.3656038,22.244 C62.8556038,16.344 57.5856038,7.784 51.0256038,10.244 C48.6596038,11.52 46.7336038,13.478 45.4956038,15.864 C49.1956038,7.684 41.2156038,3.134 38.2756038,10.864 C39.3356038,7.174 38.9356038,3.394 37.3956038,1.404 L37.3956038,1.244 C37.3056038,1.244 37.2356038,1.164 37.1556038,1.134 C36.4086038,0.407 35.4076038,0 34.3656038,0 C33.3236038,0 32.3226038,0.407 31.5756038,1.134 L31.3356038,1.244 L31.3356038,1.404 C29.7956038,3.404 29.3956038,7.174 30.4456038,10.864 C27.5156038,3.174 19.5256038,7.724 23.2356038,15.864 C21.9976038,13.478 20.0716038,11.52 17.7056038,10.244 C11.1456038,7.824 5.87560381,16.384 8.36560381,22.244 C8.79960381,23.312 9.41160381,24.3 10.1756038,25.164 C9.46260381,24.495 8.64960381,23.941 7.76560381,23.524 C7.44360381,23.391 7.10860381,23.293 6.76560381,23.234 L6.50560381,23.234 C5.77360381,23.104 5.02160381,23.151 4.31060381,23.368 C3.59960381,23.586 2.95060381,23.969 2.41660381,24.487 C1.88260381,25.004 1.47960381,25.641 1.23960381,26.344 C0.999603807,27.048 0.929603807,27.798 1.03560381,28.534 C1.19360381,29.524 1.63460381,30.448 2.30560381,31.194 C-0.0443961929,31.514 -1.85439619,36.984 3.43560381,37.414 L67.3056038,37.414 C67.3056038,37.414 68.8356038,32.194 65.2956038,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.3056038,17.274 C41.6156038,16.482 41.6996038,15.62 41.5456038,14.784 C40.8396038,15.022 40.2516038,15.524 39.9056038,16.184 C39.5946038,16.975 39.5116038,17.838 39.6656038,18.674 C40.3716038,18.435 40.9586038,17.934 41.3056038,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.2356038,37.404 C56.2356038,35.654 56.1756038,31.984 52.9956038,30.744 C52.3936038,30.56 51.7566038,30.516 51.1346038,30.615 C50.5126038,30.714 49.9216038,30.953 49.4056038,31.314 C50.4306038,30.664 51.1756038,29.655 51.4956038,28.484 C52.9656038,25.004 49.8556038,19.964 45.9956038,21.394 C44.5936038,22.147 43.4526038,23.308 42.7256038,24.724 C44.9356038,19.884 40.2156038,17.194 38.4856038,21.724 C40.2556038,16.504 36.9156038,14.624 34.3956038,14.624 C31.8756038,14.624 28.5356038,16.504 30.3056038,21.724 C28.5756038,17.184 23.8556038,19.874 26.0656038,24.724 C25.3386038,23.308 24.1976038,22.147 22.7956038,21.394 C18.9356038,19.964 15.7956038,25.004 17.2956038,28.484 C17.6156038,29.655 18.3606038,30.664 19.3856038,31.314 C18.8666038,30.959 18.2756038,30.724 17.6546038,30.626 C17.0336038,30.527 16.3986038,30.568 15.7956038,30.744 C15.1566038,30.989 14.5936038,31.4 14.1646038,31.934 C13.7356038,32.467 13.4566038,33.105 13.3546038,33.783 C13.2526038,34.46 13.3326038,35.152 13.5856038,35.788 C13.8386038,36.424 14.2566038,36.982 14.7956038,37.404 L55.1956038,37.404 L55.2356038,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.1256038,18.674 C29.2796038,17.838 29.1956038,16.975 28.8856038,16.184 C28.5386038,15.524 27.9516038,15.022 27.2456038,14.784 C27.0916038,15.62 27.1746038,16.482 27.4856038,17.274 C27.8316038,17.934 28.4196038,18.435 29.1256038,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
              <g id="bush" transform="translate(582.078302, 359.066000)" fill-rule="nonzero">
                <path
                  d="M65.2916984,32.404 C65.6816984,32.404 66.1716984,31.514 66.4316984,31.194 C67.0956984,30.445 67.5326984,29.522 67.6916984,28.534 C67.8006984,27.798 67.7316984,27.046 67.4926984,26.342 C67.2536984,25.637 66.8496984,24.999 66.3156984,24.482 C65.7806984,23.964 65.1306984,23.58 64.4196984,23.364 C63.7076984,23.147 62.9536984,23.102 62.2216984,23.234 L61.9616984,23.234 C61.6186984,23.295 61.2836984,23.392 60.9616984,23.524 C60.0796984,23.939 59.2686984,24.493 58.5616984,25.164 C59.3226984,24.299 59.9306984,23.312 60.3616984,22.244 C62.8616984,16.344 57.5816984,7.784 51.0316984,10.244 C48.6636984,11.517 46.7366984,13.476 45.5016984,15.864 C49.2016984,7.684 41.2116984,3.134 38.2816984,10.864 C39.3316984,7.174 38.9316984,3.394 37.3916984,1.404 L37.3916984,1.244 C37.3116984,1.244 37.2416984,1.164 37.1516984,1.134 C36.4046984,0.407 35.4036984,0 34.3616984,0 C33.3196984,0 32.3186984,0.407 31.5716984,1.134 L31.3416984,1.244 L31.3416984,1.404 C29.7916984,3.404 29.3416984,7.174 30.4516984,10.864 C27.5116984,3.174 19.5316984,7.724 23.2316984,15.864 C21.9976984,13.476 20.0696984,11.517 17.7016984,10.244 C11.1416984,7.824 5.87169844,16.384 8.36169844,22.244 C8.79569844,23.312 9.40769844,24.3 10.1716984,25.164 C9.46069844,24.492 8.64769844,23.939 7.76169844,23.524 C7.43969844,23.391 7.10469844,23.293 6.76169844,23.234 L6.51169844,23.234 C5.77969844,23.105 5.02669844,23.151 4.31569844,23.369 C3.60469844,23.587 2.95569844,23.97 2.42069844,24.487 C1.88569844,25.004 1.48169844,25.64 1.23969844,26.344 C0.998698437,27.047 0.927698437,27.798 1.03169844,28.534 C1.19869844,29.521 1.63869844,30.443 2.30169844,31.194 C-0.0483015627,31.514 -1.84830156,36.984 3.43169844,37.414 L67.3116984,37.414 C67.3116984,37.414 68.8316984,32.194 65.2916984,32.404 Z"
                  id="Path" fill="#379F4D"></path>
                <path
                  d="M41.3016984,17.274 C41.6116984,16.482 41.6956984,15.62 41.5416984,14.784 C40.8356984,15.022 40.2476984,15.524 39.9016984,16.184 C39.5906984,16.975 39.5076984,17.838 39.6616984,18.674 C40.3676984,18.435 40.9556984,17.934 41.3016984,17.274 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M55.2316984,37.404 C56.2316984,35.654 56.2316984,31.984 52.9916984,30.744 C52.3906984,30.561 51.7556984,30.516 51.1356984,30.615 C50.5156984,30.714 49.9256984,30.953 49.4116984,31.314 C50.4336984,30.663 51.1756984,29.654 51.4916984,28.484 C52.9616984,25.004 49.8616984,19.964 45.9916984,21.394 C44.5926984,22.147 43.4556984,23.309 42.7316984,24.724 C44.9316984,19.884 40.2116984,17.194 38.4816984,21.724 C40.2516984,16.504 36.9216984,14.624 34.3916984,14.624 C31.8616984,14.624 28.5316984,16.504 30.3016984,21.724 C28.5716984,17.184 23.8616984,19.874 26.0616984,24.724 C25.3346984,23.308 24.1936984,22.147 22.7916984,21.394 C18.9316984,19.964 15.7916984,25.004 17.2916984,28.484 C17.6116984,29.655 18.3566984,30.664 19.3816984,31.314 C18.8626984,30.959 18.2716984,30.723 17.6506984,30.625 C17.0296984,30.526 16.3946984,30.567 15.7916984,30.744 C15.1526984,30.989 14.5896984,31.4 14.1606984,31.934 C13.7316984,32.467 13.4526984,33.105 13.3506984,33.783 C13.2486984,34.46 13.3286984,35.152 13.5816984,35.788 C13.8356984,36.424 14.2526984,36.982 14.7916984,37.404 L55.1916984,37.404 L55.2316984,37.404 Z"
                  id="Path" fill="#54CF6E"></path>
                <path
                  d="M29.1316984,18.674 C29.2796984,17.837 29.1926984,16.975 28.8816984,16.184 C28.5356984,15.524 27.9476984,15.022 27.2416984,14.784 C27.0936984,15.621 27.1806984,16.483 27.4916984,17.274 C27.8376984,17.934 28.4256984,18.435 29.1316984,18.674 Z"
                  id="Path" fill="#54CF6E"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
  <div id="animationSection-0" class="animation-section"></div>
  <div id="animationSection-1" class="animation-section"></div>
  <div id="animationSection-2" class="animation-section"></div>
  <div id="animationSection-3" class="animation-section"></div>
</div>
