import { Injectable } from '@angular/core';
import { fromEvent, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  public scroll$: Observable<Event> = fromEvent(document, 'scroll');
  public resize$: Observable<Event> = fromEvent(window, 'resize');
  public isMobile$ = new BehaviorSubject<boolean>(false);


  constructor() {
    this.isMobile$.next(this.isMobileScreen());
    window.matchMedia('(max-width: 776px) and (orientation: portrait)').addEventListener('change', (val) => {
      this.isMobile$.next(this.isMobileScreen());
    });
  }

  private isMobileScreen(): boolean {
    return window.matchMedia('(max-width: 776px) and (orientation: portrait)').matches;
  }
}
