<div #featureListContainer class="feature-details-container">
  <div class="feature-details-title">
    {{featureList?.title}}
  </div>
  <div #featureItem class="feature-item" *ngFor="let i of featureList?.items">
    <i [class]="i.icon"></i>
    {{i.name}}
    <span class="coming-soon" *ngIf="i.comingSoon">(coming soon)</span>
  </div>
</div>
