import { Subject } from 'rxjs';
import { Component, OnInit, Input, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Location } from '@angular/common';
gsap.registerPlugin(ScrollTrigger);

export interface ISlideInfo {
  imgUrl?: string;
  title?: string;
}

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() public slides: ISlideInfo[] = [];
  @Output() slideSelected$ = new Subject<ISlideInfo>();

  public selectedSlide?: ISlideInfo;

  constructor(
    private location: Location
  ) { }

  ngOnInit(): void {
    if (this.slides?.length) {
      if (this.location.path() === '/leak-monitoring') {
        this.selectSlide(this.slides[2]);
      } else {
        this.selectSlide(this.slides[0]);
      }
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  selectSlide(slide: ISlideInfo): void {
    this.selectedSlide = slide;
    this.slideSelected$.next(this.selectedSlide);
  }

}
