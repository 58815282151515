<div class="backdrop">
  <div class="close-container">
    <i class="icon-x" (click)="close()"></i>
  </div>
  <div class="link-container">
    <div class="link" *ngFor="let l of links" (click)="close(l.link)">
      <i *ngIf="l?.icon" class="{{l.icon}}"></i>
      {{l.label}}
    </div>
  </div>
</div>
