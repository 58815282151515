<div class="outer-background gray-gradient">
  <ng-container *ngIf="(scrollService.isMobile$ | async)">
    <app-mobile-toolbar (homeClicked)="linkClicked($event)" (openMobileMenu)="openMobileMenu()"></app-mobile-toolbar>
    <div class="careers-section">
      <div class="large-variable-text blue">Contribute to the future of Submetering</div>
      <div class="medium-variable-text">"To promote conservation of utilities for the betterment of our communities."
      </div>
      <div class="mobile-values-container">
        <div class="value"><i class="icon-waves"></i>Beautifully Simple</div>
        <div class="value"><i class="icon-award"></i>Affordable Quality</div>
        <div class="value"><i class="icon-settings"></i>Effortless Integration</div>
        <div class="value"><i class="icon-electric"></i>Continuous Innovation</div>
        <div class="value"><i><img src="assets/handshake.svg"></i>Unparalleled Service</div>
        <div class="value"><i><img src="assets/stars.svg"></i>Reaching for the Stars</div>
      </div>
      <img id="ourTeam" src="assets/our-team.png">
    </div>
    <app-divider></app-divider>
    <div class="careers-section">
      <div class="large-variable-text blue">Benefits & Opportunities</div>
      <div class="mobile-benefits-container">
        <div class="benefit" *ngFor="let b of benefits">
          <div class="benefit-icon">
            <img [src]="'assets/' + b.icon">
          </div>
          <div class="benefit-text">
            {{b.text}}
          </div>
        </div>
      </div>
    </div>
    <app-divider></app-divider>
    <div class="careers-section">
      <div class="large-variable-text blue">Join Our Team</div>
      <app-open-positions></app-open-positions>
    </div>
    <app-mobile-footer></app-mobile-footer>
  </ng-container>
  <ng-container *ngIf="!(scrollService.isMobile$ | async)">
    <app-toolbar [context]="'careers'" (linkClicked)="linkClicked($event)"></app-toolbar>
    <div class="careers-section top">
      <div class="large-variable-text blue">Contribute to the future of Submetering</div>
      <div class="medium-variable-text">"To promote conservation of utilities for the betterment of our communities."
      </div>
      <div class="values-container">
        <div class="row">
          <div class="value"><i class="icon-waves"></i>Beautifully Simple</div>
          <div class="value"><i class="icon-award"></i>Affordable Quality</div>
          <div class="value"><i class="icon-settings"></i>Effortless Integration</div>
        </div>
        <div class="row">
          <div class="value"><i class="icon-electric"></i>Continuous Innovation</div>
          <div class="value"><i><img src="assets/handshake.svg"></i>Unparalleled Service</div>
          <div class="value"><i><img src="assets/stars.svg"></i>Reaching for the Stars</div>
        </div>
      </div>
      <img id="ourTeam" src="assets/our-team.png">
    </div>
    <app-divider></app-divider>
    <div class="careers-section">
      <div class="large-variable-text blue">Benefits & Opportunities</div>
      <div class="benefits-container">
        <div class="benefit" *ngFor="let b of benefits">
          <div class="benefit-icon">
            <img [src]="'assets/' + b.icon">
          </div>
          <div class="benefit-text">
            {{b.text}}
          </div>
        </div>
      </div>
    </div>
    <app-divider></app-divider>
    <div class="careers-section">
      <div class="large-variable-text blue">Join Our Team</div>
      <app-open-positions></app-open-positions>
    </div>
    <app-footer id="footer" [context]="'careers'" (linkClicked)="linkClicked($event)"></app-footer>
  </ng-container>
</div>
