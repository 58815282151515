<div class="features-container">
  <div class="features-title large-variable-text">
    Key Features
  </div>
  <div class="section-container">
    <div class="alerts-img-container">
      <img src="assets/alerts-page.jpg">
    </div>
    <app-feature-details [featureList]="features[0]" class="feature-list"></app-feature-details>
  </div>
  <app-divider></app-divider>
  <div class="section-container">
    <app-feature-details [featureList]="features[1]" class="feature-list centered"></app-feature-details>
    <app-feature-details [featureList]="features[2]" class="feature-list centered"></app-feature-details>
    <div class="mobile-half-img-container">
      <img id="mobile-half-img" src="assets/mobile-half.jpg">
    </div>
  </div>
</div>
