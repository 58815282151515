import { ScrollService } from './services/scroll.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { filter, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'NextCentury Submetering';
  private _subscriptions: Subscription[] = [];

  constructor(private router: Router, private scrollService: ScrollService) {
    this._subscriptions.push(
      this.router.events.pipe(filter((val) => val instanceof NavigationEnd)).subscribe((val) => {
        ScrollTrigger.refresh();
      }),
      this.scrollService.resize$.pipe(debounceTime(300)).subscribe(() => ScrollTrigger.refresh()));
    ScrollTrigger.config({ autoRefreshEvents: 'resize,load,visibilitychange,DOMContentLoaded' });
  }

  ngOnDestroy(): void {

  }

}
