import { IFeaturesListItem, FeatureList } from './../features/features.component';
import { Component, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-mobile-features',
  templateUrl: './mobile-features.component.html',
  styleUrls: ['./mobile-features.component.scss']
})
export class MobileFeaturesComponent implements AfterViewInit {

  public features: IFeaturesListItem[] = FeatureList;

  constructor() { }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: '.mobile-half-img-container',
      start: 'top bottom',
      end: 'bottom bottom',
      scrub: true,
      animation: gsap.fromTo('.mobile-half-img-container', { y: '30%' }, { y: '0' }),
      invalidateOnRefresh: true,
    });
  }

}
