import { take } from 'rxjs/operators';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { Subscription } from 'rxjs';
import { Injectable, OnDestroy, ApplicationRef, ComponentFactoryResolver, Injector, EmbeddedViewRef } from '@angular/core';
import { ThisReceiver } from '@angular/compiler';

export interface ISnackbarOptions {
  duration?: number;
  closeLabel?: string;
}

export interface ISnackbarResult {
  userDismissed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService implements OnDestroy {

  public get nextId(): number {
    this._nextId++;
    return this._nextId;
  }

  private openSnackBars: {
    [id: number]: {
      componentRef: any,
      resolve: any,
    }
  } = {};
  private _nextId = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public create(message: string, options?: ISnackbarOptions): Promise<ISnackbarResult> {
    return new Promise((resolve) => {
      const componentRef = this.componentFactoryResolver.resolveComponentFactory(SnackbarComponent)
        .create(this.injector);
      componentRef.instance.message = message;
      if (options?.closeLabel) {
        componentRef.instance.closeLabel = options.closeLabel;
      }

      this.appRef.attachView(componentRef.hostView);

      const domEl = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domEl);

      const id = this.nextId;
      this.openSnackBars[id] = { componentRef, resolve };

      const timeout = setTimeout(() => {
        subscription.unsubscribe();
        this.closeSnackBar(componentRef, resolve, false);
        delete this.openSnackBars[id];
      }, options?.duration || 3000);

      const subscription = componentRef.instance.onDismiss.pipe(take(1)).subscribe(() => {
        clearTimeout(timeout);
        this.closeSnackBar(componentRef, resolve, true);
        delete this.openSnackBars[id];
      });
    });
  }

  private closeSnackBar(componentRef: any, resolve?: any, userDismissed?: boolean): void {
    if (resolve) {
      resolve({ userDismissed });
    }
    componentRef.instance.hide = true;
    setTimeout(() => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }, 1000);
  }
}
