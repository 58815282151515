<div class="mobile-equipment-details-container">
  <div class="equipment-photo-container">
    <img [src]="info?.photo">
  </div>
  <div class="large-variable-text equipment-title">{{info?.title}}</div>
  <div class="description" [innerHTML]="info?.descriptionHTML"></div>
  <div class="models-list">
    <div class="model" *ngFor="let m of info?.models">
      <div class="header" (click)="m.open = !m.open">
        {{m?.name}}
        <i [ngClass]="m?.open ? 'icon-chevron-down' : 'icon-chevron-right'"></i>
      </div>
      <div class="downloadables" *ngIf="m?.open" @growIn>
        <div class="downloadable" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.hyperlink"
          (click)="m.disableLinks ? false : linkTo(m.hyperlink.url)">
          {{m.hyperlink.label}}
          <i class="icon-external-link"></i>
        </div>
        <div class="downloadable" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.specSheet"
          (click)="m.disableLinks ? false : linkTo(m.specSheet)">
          Specification Sheet
          <i class="icon-download"></i>
        </div>
        <div class="downloadable" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.hardwareWarranty"
          (click)="m.disableLinks ? false : linkTo(m.hardwareWarranty)">
          Hardware Warranty
          <i class="icon-download"></i>
        </div>
        <div class="downloadable" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.installationGuide"
          (click)="m.disableLinks ? false : linkTo(m.installationGuide)">
          Installation Guide
          <i class="icon-download"></i>
        </div>
        <div class="downloadable" [ngClass]="m.disableLinks ? 'disabled' : ''" *ngIf="m.certifications">
          <select [formControl]="selectionCtrl" [disabled]="!!m.disableLinks">
            <option value="" selected>Certifications</option>
            <option *ngFor="let o of m.certifications" value="{{o.url}}">{{o.label}}</option>
          </select>
          <i class="icon-download"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="learn-more" (click)="learnMoreClicked()" *ngIf="info?.learnMoreUrl">LEARN MORE</div>
</div>
