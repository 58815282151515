<div id="nav-container">
    <div class="toolbar-content">
        <img src="assets/ncss-wordmark-blue.svg" (click)="linkClicked(links.HOME)">
    </div>
</div>
<div class="main-container">
    <h3>Power Consumption Cost Calculator</h3>
    <div class="section">
        <div class="header-row">
            <p>Property Info</p>
        </div>
        <div>
            <div class="row">
                <p>Total Units</p>
                <input type="number" [(ngModel)]="inputs.totalUnits" (blur)="onInputChanged()" />
            </div>
            <div class="row zebra">
                <p>Total Repeaters</p>
                <input type="number" [(ngModel)]="inputs.totalRepeaters" (blur)="onInputChanged()" />
            </div>
            <div class="row">
                <p>Check In Interval (Hours)</p>
                <input type="number" [(ngModel)]="inputs.checkinIntervalHours" (blur)="onInputChanged()" />
            </div>
            <div class="row zebra">
                <p>Electric Rate (Per kWh)</p>
                <div class="input-dollar-icon">
                    <app-numeric-input [inputNum]="inputs.electricRatePerKWH"
                        (outputNum)="inputs.electricRatePerKWH = $event" [isMonetaryValue]="true"
                        (blur)="onInputChanged()"></app-numeric-input>
                </div>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="section">
        <div class="header-row">
            <p>Device Power Usage</p>
        </div>
        <div>
            <div class="row">
                <p>Gateway Tx Power Usage @ 15v</p>
                <span class="row-right">
                    <p>{{ inputs.gatewayTXPowerUsageAt15Volts }} mA</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Gateway Tx Power Usage</p>
                <span class="row-right">
                    <p>{{ inputs.gatewayTXPowerUsage }} Watts</p>
                </span>
            </div>
            <div class="row">
                <p>Gateway Rx Power Usage @ 15v</p>
                <span class="row-right">
                    <p>{{ inputs.gatewayRXPowerUsageAt15Volts }} mA</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Gateway Rx Power Usage</p>
                <span class="row-right">
                    <p>{{ inputs.gatewayRXPowerUsage }} Watts</p>
                </span>
            </div>
            <div class="row">
                <p>Gateway Tx Duration</p>
                <span class="row-right">
                    <p>{{ inputs.gatewayTXDuration }} ms</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Tx Power Usage @ 15v</p>
                <span class="row-right">
                    <p>{{ inputs.repeaterTXPowerUsageAt15Volts }} mA</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Tx Power Usage</p>
                <span class="row-right">
                    <p>{{ inputs.repeaterTXPowerUsage }} Watts</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Rx Power Usage @ 15v</p>
                <span class="row-right">
                    <p>{{ inputs.repeaterRXPowerUsageAt15Volts }} mA</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Rx Power Usage</p>
                <span class="row-right">
                    <p>{{ inputs.repeaterRXPowerUsage }} Watts</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Tx Duration</p>
                <span class="row-right">
                    <p>{{ inputs.repeaterTXDuration }} ms</p>
                </span>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="section">
        <div class="header-row">
            <p>Network Messages</p>
        </div>
        <div>
            <div class="row">
                <p>Total Transceiver Messages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.totalTransceiverMessagesPerDay, true) }}</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Network Retry Rate</p>
                <span class="row-right">
                    <div class="input-percent-icon">
                        <input class="full-size-percent-input" type="number" [(ngModel)]="inputs.networkRetryRate"
                            (blur)="onInputChanged()" />
                        <i>%</i>
                    </div>
                </span>
            </div>
            <div class="row">
                <p class="long-label">Average Transceiver Messages Including Network Overload and Retries</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.averageTransceiverMessages, true) }}</p>
                </span>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="section">
        <div class="header-row">
            <p>Power Usage Durations</p>
        </div>
        <div>
            <div class="row">
                <p>Total Gateway Tx Time</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.totalGatewayTXTime) }} hours</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Total Gateway Rx Time</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.totalGatewayRXTime) }} hours</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Tx Time</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterTXTime) }} hours</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Rx Time</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterRXTime) }} hours</p>
                </span>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="section">
        <div class="header-row">
            <p>Daily kWh Calculations</p>
        </div>
        <div>
            <div class="row">
                <p>Gateway Tx Power Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.gatewayTXPowerUsagesPerDay) }} kWh</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Gateway Rx Power Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.gatewayRXPowerUsagesPerDay) }} kWh</p>
                </span>
            </div>
            <div class="row">
                <p>Gateway Total Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.gatewayTotalUsagePerDay) }} kWh</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Tx Power Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterTXPowerUsagesPerDay) }} kWh</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Rx Power Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterRXPowerUsagesPerDay) }} kWh</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Total Usages Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterTotalUsagePerDay) }} kWh</p>
                </span>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="section">
        <div class="header-row">
            <p>Estimated Costs</p>
        </div>
        <div>
            <div class="row">
                <p>Gateway Cost Per Day</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.gatewayCostPerDay, false, true) }}</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Cost Per Day (Each Repeater)</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterCostPerDayEachRepeater, false, true) }}</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Cost Per Day (For All Repeaters)</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterCostPerDayAllRepeaters, false, true) }}</p>
                </span>
            </div>
            <div class="row total-row zebra">
                <p>Daily Equipment Cost</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.dailyEquipmentCost, false, true) }}</p>
                </span>
            </div>
            <div class="row"></div>
            <div class="row">
                <p>Gateway Cost Per Month</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.gatewayCostPerMonth, false, true) }}</p>
                </span>
            </div>
            <div class="row zebra">
                <p>Repeater Cost Per Month (Each Repeater)</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterCostPerMonthEachRepeater, false, true) }}</p>
                </span>
            </div>
            <div class="row">
                <p>Repeater Cost Per Month (For All Repeaters)</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.repeaterCostPerMonthAllRepeaters, false, true) }}</p>
                </span>
            </div>
            <div class="row total-row zebra">
                <p>Monthly Equipment Cost</p>
                <span class="row-right">
                    <p>{{ formatNumber((outputs | async)?.monthlyEquipmentCost, false, true) }}</p>
                </span>
            </div>
        </div>
    </div>
    <hr class="solid">
    <div class="note">
        <p class="header-row">Note</p>
        <p class="row zebra">The above calcuations are based on worst case scenarios for network layout. It assumes that
            every
            repeater will be involved in routing every message that is sent on the network. This is possible but is not
            typical. It also has a network retry rate of 15% built in. This is a higher than average retry rate. For
            stronger network layouts the values above can be improved. All of the above calculations are estimates and
            actual usage may vary.</p>
    </div>
</div>