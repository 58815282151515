import { Component, OnInit, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      animation: this.getLineRevealTween(),
      trigger: '.chart-container',
      start: 'top bottom',
      end: 'bottom top',
      toggleActions: 'restart none restart none',
      invalidateOnRefresh: true,
    });
    ScrollTrigger.create({
      animation: this.getLaptopParallax(),
      trigger: '#laptopHalf',
      start: 'top bottom',
      end: window.innerHeight <= 400 ? '+=100px' : '+=500px',
      scrub: .5,
      invalidateOnRefresh: true,
    });
  }

  private getLineRevealTween(): gsap.core.Tween {
    const tween = gsap.to('.dotted-line-mask', {
      x: '100%',
      duration: 3,
    });
    return tween;
  }

  private getLaptopParallax(): gsap.core.Tween {
    const tween = gsap.fromTo('#laptopHalf', {
      y: window.innerHeight <= 400 ? '50px' : '150px',
    },
      {
        y: 0,
      });
    return tween;
  }

}
